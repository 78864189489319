import Collapse from "@material-ui/core/Collapse/Collapse";
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import {Col, Row} from 'react-grid-system';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {connect} from "react-redux";
import {saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ClientsServcices from "../../../services/Clients";
import CotizacionServcices from "../../../services/Cotizacion";
import FuecService from "../../../services/Fuec";
import ContratoService from "../../../services/Contratos";
import ServiciosService from "../../../services/Servicios";
import TablePagination from '@material-ui/core/TablePagination';

class Lista extends React.Component {

    Cities = resource('Cities');
    MatenimientoType = resource('MatenimientoType');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            data: [],
            contratos: [],
            page: 0,
            total: 0,
            active: null,
            servicios: []
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll(){
        let [servicios] = await Promise.all([
            ServiciosService.get(-1)
        ]);
        this.setState(()=>({ servicios: servicios.data }));
        await this.getData();
    }

    async getData(){
        Helpers.loader();
        let form = Store.getState().formularios;
        let [clients, contrato] = await Promise.all([
            FuecService.get(this.state.page,form),
            ContratoService.get(-1)
        ]);
        this.setState(()=>({data: clients.data, contratos: contrato.data, total: clients.total}));
        Helpers.closeLoader();
    }

    view = id => () => this.props.history.push(`/fuec/view/${id}`);

    changePage = async (event, page) => {
        Helpers.loader();
        let clients = await FuecService.get(page);
        this.setState(()=>({data: clients.data, total: clients.total, page}));
        Helpers.closeLoader();
    };

    setOpen = active => () => {
        if( active === this.state.active ) return this.setState(()=>({active: null}));
        this.setState(()=>({active}));
    };

    generate = id => async () => {
        Helpers.loader();
        let doc = await FuecService.doc(id);
        let a = document.createElement('a');
        a.download = doc.name;
        a.href = doc.data;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        Helpers.closeLoader();
    };

    search = () => {
        this.setState(()=>({ page: 0 }));
        this.getData();
    };

    render() {
        const {state, props:{campo}} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Fuecs</h2>
                    </Col>
                </Row>
                <div className="wrap table">
                    <Row>
                        <Provider store={'formularios'} save={saveFormulariosData}>
                            <Col md={10}>
                                {campo === 'servicios' &&
                                <Select name={'search'} label={'Buscar...'} optionLabel={Helpers.sevicioLabels} opts={state.servicios} hash={'id'} />
                                }
                                <Select name={'campo'} opts={[
                                    {
                                        name: 'Servicio',
                                        code: 'servicios'
                                    },
                                ]} />
                            </Col>
                            <Col md={2}>
                                <Btn text={"Buscar"} type={'primary'} class={'btn-block'} click={this.search}/>
                            </Col>
                        </Provider>
                    </Row>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Servicio</TableCell>
                                    <TableCell>Contrato</TableCell>
                                    <TableCell>Origen</TableCell>
                                    <TableCell>Destino</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(state.data) && state.data.map((item,key)=>
                                    <React.Fragment key={key}>
                                        <TableRow onClick={this.setOpen(key)} className={`${key===state.active?'active-row':''}`}>
                                            <TableCell>{item.servicios?.consecutivo}</TableCell>
                                            <TableCell>{Helpers.getStatic(item.servicios?.contract_id, state.contratos, 'id').consecutivo}</TableCell>
                                            <TableCell>{Helpers.getStatic(item.servicios?.origen, this.Cities).name}</TableCell>
                                            <TableCell>{Helpers.getStatic(item.servicios?.destino, this.Cities).name}</TableCell>
                                            <TableCell className="last-td">
                                                <IconButton size="small" onClick={this.view(item.id)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="no-border" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                <Collapse in={key===state.active} timeout="auto" unmountOnExit>
                                                    <Row className="colpwrap">
                                                        <Col>
                                                            <Btn type="primary" text="Generar documento" click={this.generate(item.id)} />
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={state.total}
                            rowsPerPage={10}
                            page={state.page}
                            onChangePage={this.changePage}
                        />
                    </TableContainer>
                </div>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        campo: store.formularios.campo
    }),
    (dispatch) => ({}),
)(Lista);
