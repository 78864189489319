import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import {Col, Row} from 'react-grid-system';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Helpers from "../../common/Helpers";
import NotificacionesServcices from '../../services/Notificaciones';
import TablePagination from '@material-ui/core/TablePagination';

class Notificaciones extends React.Component {

    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.getData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getData(){
        Helpers.loader();
        let clients = await NotificacionesServcices.get();
        this.setState(()=>({data: clients.data, total: clients.total}));
        Helpers.closeLoader();
    }

    changePage = async (event, page) => {
        Helpers.loader();
        let clients = await NotificacionesServcices.get(page);
        this.setState(()=>({data: clients.data, total: clients.total, page}));
        Helpers.closeLoader();
    };

    edit = (id,type) => () => {
        if(type === 1) return this.props.history.push(`/vehiculo/edit/${id}`);
        if(type === 2) return this.props.history.push(`/condutcor/edit/${id}`);
    };

    render() {
        const {state} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Notificaciones</h2>
                    </Col>
                </Row>
                <div className="wrap table">
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre/Placa</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Documento</TableCell>
                                    <TableCell>Fecha de vencimiento</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(state.data) && state.data.map((item,key)=>
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            <TableCell>{item.type===1?item.plate:item.name}</TableCell>
                                            <TableCell>{item.type===1?'Vehiculo':'Conductor'}</TableCell>
                                            <TableCell>
                                                {item.name_key ==="soat_date" && "Fecha de vencimiento soat"}
                                                {item.name_key ==="tecn_date" && "Fecha vencimiento tecnomecanica"}
                                                {item.name_key ==="rvb_date" && "Fecha venc revision bimensual"}
                                                {item.name_key ==="ries_date" && "Fecha venc póliza todo riesgo"}
                                                {item.name_key ==="RC_date" && "Fecha vencimiento RC y REC"}
                                                {item.name_key ==="date_licesed" && "Fecha de vencimiento de la licencia"}
                                                {item.name_key ==="exam_date" && "Fecha Del ultimo examen psicosensométrico"}
                                            </TableCell>
                                            <TableCell>{item[item.name_key]}</TableCell>
                                            <TableCell>
                                                <IconButton size="small" onClick={this.edit(item.id, item.type)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={state.total}
                            rowsPerPage={10}
                            page={state.page}
                            onChangePage={this.changePage}
                        />
                    </TableContainer>
                </div>
            </div>
        );
    }
}

export default Notificaciones;
