import {createStore} from 'redux';

//Mocks
// import register from './mocks/register.json';

export const State = {
    user: {},
    login: {},
    formularios: {},
    formularios2: {},
    errors: {},
    repetidos: {},
    repetidosProveedores: {}
};

const reducer = (state = State, action) => {
    const type = action.type;
    switch (type) {
        case "SAVE":
            return {
                ...action.data
            };
        case "SAVE_DATA":
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default createStore(reducer);
