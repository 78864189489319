import Api from '../common/Api';
import {Backend, headers} from "../common/Config";
import Helpers from '../common/Helpers';

export default class Afiliados {

    static async save(form) {
        const response = await Api.post({
            url: `${Backend}/afiliado/create`,
            form: Helpers.processFormData(form),
            headers: {}
        });
        return Helpers.dataReturn(response);
    };

    static async update(form) {
        const response = await Api.post({
            url: `${Backend}/afiliado/update`,
            form: Helpers.processFormData(form),
            headers: {}
        });
        return Helpers.dataReturn(response);
    };

    static async get(page = 0) {
        const response = await Api.get({
            url: `${Backend}/afiliado/list/${page}`,
            headers
        });
        return Helpers.dataReturn(response);
    };

    static async getById(id) {
        const response = await Api.get({
            url: `${Backend}/afiliado/get/${id}`,
            headers
        });
        return Helpers.dataReturn(response);
    };

}
