import React from 'react';
import Formulario from "./components/Formulario";
import Lista from "./components/Lista";

const Contrato = props => {
    const path = props.match.path;
    return (
        <React.Fragment>
            {(path === '/contrato/new' || path.indexOf('/contrato/edit') === 0) && <Formulario {...props}/>}
            {path === '/contrato/list' && <Lista {...props}/>}
        </React.Fragment>
    );
};

export default Contrato;
