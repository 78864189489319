import Api from '../common/Api';
import {Backend, headers} from "../common/Config";
import Helpers from '../common/Helpers';

export default class Consecutivo {

    static async get(type) {
        const response = await Api.get({
            url: `${Backend}/consecutivo/get/${type}`,
            headers: headers
        });
        return Helpers.dataReturn(response);
    };

    static async getFuec() {
        const response = await Api.get({
            url: `${Backend}/consecutivo/fuec/get`,
            headers: headers
        });
        return Helpers.dataReturn(response);
    };

    static async saveFuec(form) {
        const response = await Api.post({
            url: `${Backend}/consecutivo/fuec/save`,
            form,
            headers: headers
        });
        return Helpers.dataReturn(response);
    };

}
