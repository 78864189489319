import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from "react-redux";
import { saveData, saveFormulariosData, saveRepetidosData } from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Check from "../../../common/components/Fields/Check";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import { resource } from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ClientsService from "../../../services/Clients";
import Model from "../Clientes.json";

class Formulario extends React.Component {

    PersonType = resource('PersonType');
    DocumentType = resource('DocumentType');
    _mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            counter: [0]
        }
        this.props.save({ formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({ formularios: {}, repetidos: {} });
            this.setState(() => ({ edit: false, counter: [0] }));
        }
    }

    componentDidMount() {
        this._mounted = true;
        let params = this.props.match.params;
        if (params.id) this.getData(params.id);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getData(id) {
        Helpers.loader();
        let client = await ClientsService.getById(id);
        if (!client.data) return this.props.history.push('/cliente/list');
        if (client.data.id) {
            let counter = [];
            let contactos = client.data.contactos;
            let repetidos = Helpers.ungroupByKey(contactos);
            for (let i = 0; i < contactos.length; i++) counter.push(i);

            this.props.save({ formularios: client.data, repetidos });
            this.setState(() => ({ edit: true, counter }));
        } else {
            this.props.history.push('/cliente/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();
        let repetidos = Helpers.groupByKey(store.repetidos);

        let form = {
            ...store.formularios,
            contactos: repetidos
        };

        let valid = Helpers.validForm(form, Model);
        if (valid.keys) {
            this.props.save({ errors: valid.keys });
            Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
        } else {
            this.props.save({ errors: {} });
            Helpers.loader();
            let method = 'save';
            if (this.state.edit) method = 'update';
            let response = await ClientsService[method](form);
            Helpers.closeLoader();
            if (response.message) {
                Helpers.alert({ title: 'Error!', text: response.message });
            } else if (response.status) {
                if (this.state.edit) {
                    Helpers.alert({ title: '', icon: 'success', text: 'Se ha guardado correctamente!' });
                } else {
                    Helpers.alert({ title: '', icon: 'success', text: 'Se ha creado correctamente!' });
                }
                this.props.history.push('/cliente/list');
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    remove = key => () => {
        let newcounter = [];
        let counter = this.state.counter;
        for (let i = 0; i < counter.length; i++) if (counter[i] !== key) newcounter.push(counter[i]);
        this.setState(() => ({ counter: newcounter }));

        let repetidos = Helpers.removeGroupByKey(Helpers.groupByKey(Store.getState().repetidos), key);
        this.props.save({ 'repetidos': repetidos });
    };

    add = () => {
        let counter = this.state.counter;
        if (counter.length < 30) {
            let index = counter[counter.length - 1] + 1;
            counter.push(isNaN(index) ? 0 : index);
            this.setState(() => ({ counter }));
        } else {
            Helpers.alert({ title: 'Clientes', icon: 'warning', text: 'Solo se permiten 30 contactos' })
        }
    };

    return = () => this.props.history.push('/cliente/list');

    check = key => () => {
        let store = Store.getState().formularios;

        this.props.saveRepe({
            ["docType_" + key]: store.docType,
            ["doc_" + key]: store.doc,
            ["names_" + key]: store.names,
            ["email_" + key]: store.email,
            ["cell_" + key]: store.cell,
            // ["area_"+key]: store.area,
        });

        console.log(key);
    };

    render() {
        const { props: { personType }, state: { counter, edit } } = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{edit ? 'Editar' : 'Nuevo'} Cliente</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    <Select label="Seleccione el tipo de cliente" opts={this.PersonType} className='w-100' name="personType" />
                                </div>
                                <h3 />
                                {personType === 1 &&
                                    <div>
                                        <div className="w-49 d-i max">
                                            <Select label="Tipo de documento" opts={this.DocumentType} name="docType" />
                                            <Text label="Documento" name="doc" />
                                        </div>
                                        <Text label="Nombre completo" name="names" />
                                    </div>

                                }
                                {personType === 2 &&
                                    <div>
                                        <Text label="Nit" name="doc"/>
                                        <Text label="Razón social" name="names"/>
                                    </div>
                                }
                                <div>
                                    <Text label="Dirección" name="address"/>
                                    <Text label="Celular de contacto" name="cell"/>
                                </div>
                                <h3 />
                                <div>
                                    <Text label="Correo electrónico" name="email"/>
                                    <Text label="Contraseña" name="password" type={'password'}/>
                                </div>
                                <h3 />
                            </Col>
                        </Row>
                    </Provider>
                    <Provider save={saveRepetidosData} store="repetidos">
                        <Row className="row">
                            <Col>
                                <h4>Datos de contacto</h4>
                                {counter.map((item,key)=>
                                    <React.Fragment key={key}>
                                        <div>
                                            <Check label={'¿Son los mismos datos?'} change={this.check(key)}/>
                                        </div>
                                        <div className="w-49 d-i max">
                                            <Select label={"Tipo de documento"} opts={this.DocumentType} name={`docType_${item}`} />
                                            <Text label="Documento" name={`doc_${item}`}/>
                                        </div>
                                        <div className="w-49 d-i">
                                            <Text label="Nombre completo" name={`names_${item}`}/>
                                            <Text label="Correo electrónico" name={`email_${item}`}/>
                                        </div>
                                        <div>
                                            <Text label={"Celular"} name={`cell_${item}`}/>
                                            <Text label="Área o dependencia" name={`area_${item}`}/>
                                        </div>
                                        <div className="w-100 d-i">
                                            <Btn click={this.remove(item)} text="Remover" type="secondary" class="remove" />
                                        </div>
                                        <h3 />
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    </Provider>
                    <Row>
                        <Col>
                            <Btn click={this.add} text="Agregar Contacto" type="primary" class="add-contact" />
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col className="btns">
                        <Btn click={this.save} text="Guardar" type="primary" />
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({ personType: store.formularios.personType }),
    (dispatch) => ({
        save: data => dispatch(saveData(data)),
        saveRepe: data => dispatch(saveRepetidosData(data)),
    }),
)(Formulario);
