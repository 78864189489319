import Api from '../common/Api';
import {Backend, headers} from "../common/Config";
import Helpers from '../common/Helpers';

export default class Dashboard {


    static async index() {
        const response = await Api.get({
            url: `${Backend}/dashboard`,
            headers: headers
        });
        return Helpers.dataReturn(response);
    };

}