import Api from '../common/Api';
import { Backend, headers } from "../common/Config";
import Helpers from '../common/Helpers';

export default class Ubicaciones {

    static async list(page, form = {}) {
        const response = await Api.get({
            url: `${Backend}/ubicacion/list/${page}`,
            form,
            headers: headers
        });
        return Helpers.dataReturn(response);
    };

    static async create(form) {
        const response = await Api.post({
            url: `${Backend}/ubicacion/create`,
            form,
            headers: headers
        });
        return Helpers.dataReturn(response)
    }

    static async getOne(id) {
        const response = await Api.get({
            url: `${Backend}/ubicacion/${id}`,
            headers: headers
        });
        return Helpers.dataReturn(response);
    }

    static async update(id, form){
        const response = await Api.post({
            url: `${Backend}/ubicacion/update/${id}`,
            form,
            headers: headers
        })
        return Helpers.dataReturn(response);
    }

}
