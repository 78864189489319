import React from 'react';
import {Col, Row} from 'react-grid-system';
import Btn from "../../common/components/Btn/Btn";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import './NotFound.scss';

const NotFound = props => {

    const home = () => {
        props.history.push('/');
    };

    return (
        <Row className="not-found-component">
            <Col>
                <div className="content">
                    <div className="img">
                        <SentimentVeryDissatisfiedIcon />
                    </div>
                    <h1>404</h1>
                    <h3>Pagina no encontrada</h3>
                    <Btn click={home} type="primary" text={'Regresar'}/>
                </div>
            </Col>
        </Row>
    );
};

export default NotFound;
