import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from "react-redux";
import { saveData, saveFormulariosData } from '../../../common/Actions';
import Btn from '../../../common/components/Btn/Btn';
import Select from '../../../common/components/Fields/Select';
import Text from '../../../common/components/Fields/Text';
import Provider from '../../../common/components/Provider/Provider';
import Helpers from '../../../common/Helpers';
import { resource } from '../../../common/lang/lang';
import Store from '../../../common/Store';
import ClientsServcices from '../../../services/Clients';
import Margenes from '../../../services/Margenes';
import Ubicaciones from '../../../services/Ubicaciones';

class Formulario extends React.Component {

    Cities = resource('Cities');

    constructor(props) {
        super(props)
        this.state = {
            date: '',
            clientes: [],
            ubicaciones: [],
            idParam: this.props.match.params.id
        }
        this.props.save({ formularios: {} });
    }

    componentDidMount() {
        this.getData();
        if (this.state.idParam) {
            this.getEdit(this.state.idParam);
        }
    }

    async getEdit(id) {
        if (id) {
            let data = await Margenes.getById(this.state.idParam);

            data.data.val_dia_conductor = Helpers.formatToMask(data.data.val_dia_conductor);

            this.props.save({ formularios: data.data });
        }
    }


    async getData() {
        let [clientes, ubicaciones] = await Promise.all([
            ClientsServcices.get(-1),
            Ubicaciones.list(-1)
        ])

        this.setState({ clientes: clientes.data, ubicaciones: ubicaciones.data });
    }

    save = async () => {
        Helpers.loader();
        let form = Store.getState().formularios;

        form.val_dia_conductor = Helpers.formatOnlyNumbers(form.val_dia_conductor);

        let result = await Margenes.save(form);

        console.log(result);
        Helpers.closeLoader()
        if (result.status) {
            this.props.history.push('/margenes');
        }
    }

    update = async () => {
        let form = Store.getState().formularios;

        form.val_dia_conductor = Helpers.formatOnlyNumbers(form.val_dia_conductor);

        let result = await Margenes.update(form);

        if (result.status) {
            this.props.history.push('/margenes');
        }

    }

    return = () => this.props.history.push('/margenes');

    changeOrigen = (v) => {
        
        let form = Store.getState().formularios;

        form.origen = v.v.code_city;

        this.props.save({ formularios: form });
    }

    changeDestino = (v) => {
        
        let form = Store.getState().formularios;

        form.destino = v.v.code_city;

        this.props.save({ formularios: form });
    }


    render() {
        const { state } = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{(state.idParam) ? "Editar" : "Nuevo"} margen</h2>
                    </Col>
                    <Col>
                        <div className="top">
                            <p><span>{state.date}</span></p>
                        </div>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider store='formularios' save={saveFormulariosData}>
                        <div>
                            <Select label='Seleccione el cliente' opts={state.clientes} optionLabel={Helpers.clientsLabels} name="cliente" hash="id" />
                            <div className='w-49 d-i'>
                                <Select disabled label='Origen' name='origen' opts={this.Cities} />
                                <Select disabled label='Destino' name='destino' opts={this.Cities} />
                            </div>
                        </div>
                        <div>
                            <Select label='Origen' name='ubic_origen' opts={state.ubicaciones} change={this.changeOrigen} />
                            <Select label='Destino' name='ubic_destino' opts={state.ubicaciones} change={this.changeDestino}  />
                        </div>
                        <div>
                            <Text label="Margen %" number name='margen' />
                            <Text label="Valor día conductor $" money name='val_dia_conductor' />
                        </div>
                    </Provider>
                    <h3 />
                </div>
                <div className='btns'>
                    {!state.idParam && <Btn text="Guardar" type="primary" click={this.save} />}
                    {state.idParam && <Btn text="Actualizar" type="primary" click={this.update} />}
                    <Btn click={this.return} text="Cancelar" type="secondary" />
                </div>
            </div>
        )
    }

}

export default connect(
    (store) => ({
        cliente: store.formularios.cliente
    }),
    (dispatch) => ({
        save: data => dispatch(saveData(data)),
        saveForm: data => dispatch(saveFormulariosData(data))
    })
)(Formulario)