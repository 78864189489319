import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import Provider from '../../../common/components/Provider/Provider';
import { saveData, saveFormulariosData } from '../../../common/Actions';
import Select from '../../../common/components/Fields/Select';
import Helpers from '../../../common/Helpers';
import Btn from '../../../common/components/Btn/Btn';
import Text from '../../../common/components/Fields/Text';
import Date from '../../../common/components/Fields/Date';
import Solicitudes from '../../../services/Solicitudes';
import ClientsService from '../../../services/Clients';
import Store from '../../../common/Store';
import { resource } from '../../../common/lang/lang';
import Contratos from '../../../services/Contratos';

class Lista extends React.Component {


    Cities = resource('Cities');
    VehicleType = resource('VehicleType');

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            total: 0,
            page: 0,
            options: []
        }
    }

    componentDidMount() {
        this.getData();
        this.setState({ user: Store.getState().user })
        let user = Store.getState().user
        if (user.role == 2) {
            this.setState({
                options: [
                    {
                        name: 'Origen',
                        code: 'origen'
                    },
                    {
                        name: 'Fecha',
                        code: 'fecha'
                    }]
            })
        } else {
            this.setState({
                options: [
                    {
                        name: 'Cliente',
                        code: 'client'
                    },
                    {
                        name: 'Origen',
                        code: 'origen'
                    },
                    {
                        name: 'Fecha',
                        code: 'fecha'
                    }
                ]
            })
        }
    }

    async getData(form = {}) {

        let response = null;
        let user = Store.getState().user;
        if (user.role == 2) {
            response = await Solicitudes.get({ client: user.id, ... form });
        } else {
            response = await Solicitudes.get(form);
        }

        this.setState({ data: response.data, total: response.total });
    }

    async getClientes() {
        let clientes = await ClientsService.get(-1);

        this.setState({ clients: clientes.data })
    }

    edit = id => () => this.props.history.push(`/solicitud/edit/${id}`);

    search = () => {
        let form = Store.getState().formularios;

        this.getData(form);
    }

    render() {
        const { state, props: { campo } } = this
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Solicitudes</h2>
                    </Col>
                </Row>
                <div className="wrap table">
                    <Row>
                        <Provider store={'formularios'} save={saveFormulariosData}>
                            <Col md={10}>

                                <Select name={'campo'} opts={state.options} />
                                {(campo === 'client') &&
                                    <Select name={'search'} label={'Buscar...'} optionLabel={Helpers.clientsLabels} opts={state.clients} hash={'id'} />
                                }
                                {campo === 'fecha' &&
                                    <React.Fragment>
                                        <Date name="init_date" label="Fecha de" />
                                        <Date name="end_date" label="Fecha hasta" />
                                    </React.Fragment>
                                }
                                {(campo === 'origen' || campo === 'destino') &&
                                    <Select name={'search'} label={'Buscar...'} opts={this.Cities} />
                                }
                            </Col>
                            <Col md={2}>
                                <Btn text={"Buscar"} type={'primary'} class={'btn-block'} click={this.search} />
                            </Col>
                        </Provider>
                    </Row>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Cliente</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Origen</TableCell>
                                    <TableCell>Destino</TableCell>
                                    <TableCell>Tipo de trayecto</TableCell>
                                    <TableCell>Tipo de vehiculo</TableCell>
                                    <TableCell>Tarifa</TableCell>
                                    <TableCell>Servicio</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(state.data) && state.data.map((item, key) =>
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            <TableCell>{item.cliente.names}</TableCell>
                                            <TableCell>{item.fecha_ida}</TableCell>
                                            <TableCell>{Helpers.getStatic(item.origen, this.Cities).name}</TableCell>
                                            <TableCell>{Helpers.getStatic(item.destino, this.Cities).name}</TableCell>
                                            <TableCell>{item.tipo_trayecto}</TableCell>
                                            <TableCell>{Helpers.getStatic(item.tipo_veh, this.VehicleType).name}</TableCell>
                                            <TableCell>{Helpers.formatToMask(item.tarifa)}</TableCell>
                                            <TableCell>{item.servicio}</TableCell>
                                            <TableCell className="last-td">
                                                <IconButton size="small" onClick={this.edit(item.id)}><EditIcon /></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={state.total}
                            rowsPerPage={10}
                            page={state.page}
                            onChangePage={this.changePage}
                        />
                    </TableContainer>
                </div>
            </div>

        )
    }
}

export default connect(
    (store) => ({
        campo: store.formularios.campo,
    }),
    (dispatch) => ({
        save: data => dispatch(saveData(data)),
        saveForm: data => dispatch(saveFormulariosData(data))
    })
)(Lista);
