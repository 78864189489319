import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {saveData, saveFormulariosData} from "../../common/Actions";
import Btn from "../../common/components/Btn/Btn";
import Text from "../../common/components/Fields/Text";
import Provider from "../../common/components/Provider/Provider";
import Helpers from "../../common/Helpers";
import Store from "../../common/Store";
import ConsecutivoService from "../../services/Consecutivo";
import Model from "./ReferenciaFuec.json";

class ReferenciaFuec extends React.Component {

    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            counter: [0]
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.getData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getData(){
        Helpers.loader();
        let client = await ConsecutivoService.getFuec();
        if( client.number ) this.props.save({formularios: {number: client.number} });
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();

        let form = {...store.formularios};

        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();
            let response = await ConsecutivoService.saveFuec(form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                Helpers.alert({title: '', icon:'success', text: 'Se ha guardado correctamente!'});
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    render() {
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Referencia Fuec</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    <Text label="Referencia" name="number" className="w-100" number/>
                                </div>
                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        <Btn click={this.save} text="Guardar" type="primary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({}),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(ReferenciaFuec);
