import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from "prop-types";
import React from 'react';
import {connect} from 'react-redux';
import HelperText from "./HelperText";

const Check = ({value, save, label, name, error, helpe, disabled, change}) => {
    value = value ? value : null;
    const handler = (e,v) => {
        if(!disabled) {
            save({[name]: v});
            change(v);
        }
    };
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value}
                    onChange={handler}
                    name="checkedB"
                    color="primary"
                    disabled={disabled}
                />
            }
            label={<React.Fragment>{label} <HelperText value={helpe}/></React.Fragment>}
        />
    );
};

Check.propTypes = {
    store: PropTypes.string,
    save: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    helpe: PropTypes.string,
    disabled: PropTypes.bool,
    change: PropTypes.func,
};

export default connect(
    (store, props) => {
        props.name = props.name ? props.name : '';
        props.label = props.label ? props.label : '';
        return {
            value: props.store ? store[props.store][props.name] : undefined,
            error: store.errors[`${props.name}_error`]
        };
    },
    (dispatch, props) => ({save: data => dispatch(props.save(data))}),
)(Check);

