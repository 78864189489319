import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {saveData, saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import FuecService from "../../../services/Fuec";
import ServiciosService from "../../../services/Servicios";
import Model from "../Fuec.json";

class Formulario extends React.Component {

    Cities = resource('Cities');
    MatenimientoType = resource('MatenimientoType');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            servicios: [],
            action: props.match.params.action,
            id: null
        };
        this.props.save({formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({formularios: {}, repetidos: {} });
            this.setState(()=>({ edit: false }));
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll(){
        await this.getItems();
        let params = this.props.match.params;
        if( params.id ) await this.getData(params.id);
    }

    async getItems(){
        let [servicios] = await Promise.all([
            //ServiciosService.getState(-1, 1),
            ServiciosService.getToFuec(),
            //ServiciosService.getToFuec(-1),
        ]);
        this.setState(()=>({ servicios: servicios.data, edit: false }));
    }

    async getData(id){
        Helpers.loader();
        let client = await FuecService.getById(id);
        if( client.data.id ){
            this.props.save({formularios: client.data });
            this.setState(()=>({ edit: true, id }));
        }else{
            this.props.history.push('/fuec/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();

        let form = {
            ...store.formularios,
        };

        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();
            let method = 'save';
            if( this.state.edit ) method = 'update';
            let response = await FuecService[method](form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                if( this.state.edit ){
                    Helpers.alert({title: '', icon:'success', text: 'Se ha guardado correctamente!'});
                }else{
                    Helpers.alert({title: '', icon:'success', text: 'Se ha creado correctamente!'});
                }
                this.props.history.push('/fuec/list');
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    return = () => this.props.history.push('/fuec/list');

    generate = id => async () => {
        Helpers.loader();
        let doc = await FuecService.doc(id);
        let a = document.createElement('a');
        a.download = doc.name;
        a.href = doc.data;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        Helpers.closeLoader();
    };

    render() {
        const {state:{ edit, servicios, action, id }, props} = this;
        let servicio = {contracto: {}, cliente:{}};
        if( props.servicio ) servicio = Helpers.getStatic(props.servicio, servicios, 'id');
        const disabled = action==='view';
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{edit ? 'Editar' :'Nuevo'} Fuec</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios" disabled={disabled}>
                        <Row className="row">
                            <Col>
                                <div>
                                    <Select label="Seleccione el servicio" opts={servicios} optionLabel={Helpers.contratosLabels} hash={'id'} name="servicio" />
                                    <Text label="Placa Asignada" disabled value={servicio?.vehiculo?.plate}/>
                                </div>
                                <div>
                                    <Text label="Razón social" disabled value="Translaval"/>
                                    <Text label="Nit" disabled value="9 0 0 6 1 7 1 7 1 - 5"/>
                                </div>
                                <div>
                                    <Text label="Número de contrato" disabled value={servicio?.contracto?.consecutivo}/>
                                    <Text label="Contratante" disabled value={servicio?.cliente?.names}/>
                                </div>
                                <div>
                                    <Text label="NIT/CC" disabled value={servicio?.cliente?.doc}/>
                                    <Text label="Origen" disabled value={Helpers.getStatic(servicio.origen, this.Cities).name}/>
                                </div>
                                <div>
                                    <Text label="Destino" disabled value={Helpers.getStatic(servicio.destino, this.Cities).name}/>
                                </div>
                                <h4>Vigencia del contrato</h4>
                                <div>
                                    <Text label="Fecha inicial" disabled value={servicio?.contracto?.init_date}/>
                                    <Text label="Fecha de vencimiento" disabled value={servicio?.contracto?.end_date}/>
                                </div>
                                <div>
                                    <Text label="Convenio de colaboración" name="convenio"/>
                                </div>
                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        {!disabled &&<Btn click={this.save} text="Guardar" type="primary" disabled={disabled} />}
                        {disabled &&<Btn type="primary" text="Generar documento" click={this.generate(id)} />}
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        servicio: store.formularios.servicio,
    }),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(Formulario);
