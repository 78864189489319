import Store from "./Store";

const returnNewStore = (key, data) => ({
    type: "SAVE_DATA",
    data: {
        [key]: {
            ...Store.getState()[key],
            ...data
        }
    }
});

export const saveData = data => ({
    type: "SAVE_DATA", data
});

export const save = data => ({
    type: "SAVE", data
});

export const saveLoginData = data => returnNewStore('login', data);
export const saveFormulariosData = data => returnNewStore('formularios', data);
export const saveFormulariosData2 = data => returnNewStore('formularios2', data);
export const saveRepetidosData = data => returnNewStore('repetidos', data);
export const saveRepeProveedores = data => returnNewStore('repetidosProveedores', data);
