import Api from "../common/Api";
import { Backend, headers } from "../common/Config";
import Helpers from "../common/Helpers";

export default class CosteoRutas {

    static async save(form) {
        const response = await Api.post({
            url: `${Backend}/costeorutas/create`,
            form,
            headers: headers
        });
        return Helpers.dataReturn(response);
    };

    static async get(form, page = 0) {
        const response = await Api.post({
            url: `${Backend}/costeorutas/list/${page}`,
            form,
            headers: headers
        })
        return Helpers.dataReturn(response);
    }

    static async getById(id){
        const response = await Api.get({
            url: `${Backend}/costeorutas/get/${id}`,
            headers: headers
        })
        return Helpers.dataReturn(response);
    }

    static async update (form){
        const response = await Api.post({
            url: `${Backend}/costeorutas/update`,
            form,
            headers: headers
        });
        return Helpers.dataReturn(response);
    }
}