import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {saveData, saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Date from "../../../common/components/Fields/Date";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ColaboradoresService from "../../../services/Colaboradores";
import Model from "../Colaborador.json";

class Formulario extends React.Component {

    Banks = resource('Banks');
    Cities = resource('Cities');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {};
        this.props.save({formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({formularios: {}, repetidos: {} });
            this.setState(()=>({ edit: false }));
        }
    }

    componentDidMount() {
        this._mounted = true;
        let params = this.props.match.params;
        if( params.id ) this.getData(params.id);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getData(id){
        Helpers.loader();
        let client = await ColaboradoresService.getById(id);
        if( client.data.id ){
            let counter = [];

            this.props.save({formularios: client.data });
            this.setState(()=>({ edit: true, counter }));
        }else{
            this.props.history.push('/colaboradores/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();

        let form = {
            ...store.formularios
        };

        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();
            let method = 'save';
            if( this.state.edit ) method = 'update';
            let response = await ColaboradoresService[method](form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                if( this.state.edit ){
                    Helpers.alert({title: '', icon:'success', text: 'Se ha guardado correctamente!'});
                }else{
                    Helpers.alert({title: '', icon:'success', text: 'Se ha creado correctamente!'});
                }
                this.props.history.push('/colaboradores/list');
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    return = () => this.props.history.push('/colaboradores/list');

    render() {
        const {state:{ edit }} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{edit ? 'Editar' :'Nuevo'} Colaborador</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    <Text label="Nombre" name="name" />
                                    <Text label="Apellido" name="surname" />
                                </div>
                                <div>
                                    <Text label="Cedula" name="doc" />
                                    <Text label="Dirección" name="address" />
                                </div>
                                <div>
                                    <Text label="Teléfono" name="phone" />
                                    <Text label="Email" name="email" />
                                </div>
                                <div>
                                    <Text label="Nombre contacto emergencia" name="name_emer" />
                                    <Text label="Telefono contacto emergencia" name="phone_emer" />
                                </div>
                                <div>
                                    <Select label="Banco" name="bank" opts={this.Banks} />
                                    <Date label="Fecha de nacimiento" name="birhday" />
                                </div>
                                <div>
                                    <Text label="Fondo de pension" name="pension" />
                                    <Date label="Fecha de ingreso" name="pension_date" />
                                </div>
                                <div>
                                    <Text label="Fondo de cesantias" name="censantias" />
                                    <Date label="Fecha de retiro" name="date_retiro" />
                                </div>
                                <div>
                                    <Text label="Eps" name="eps" />
                                    <Text label="Cuenta de nomina" name="account" />
                                </div>
                                <div>
                                    <Text label="Cargo" name="cargo" />
                                    <Text label="Salario" name="amount" money />
                                </div>

                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        <Btn click={this.save} text="Guardar" type="primary" />
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({personType: store.formularios.personType}),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(Formulario);
