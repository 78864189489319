import moment from "moment";
import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {saveData, saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Date from "../../../common/components/Fields/Date";
import Files from "../../../common/components/Fields/Files";
import Select from "../../../common/components/Fields/Select";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ConductoresService from "../../../services/Conductores";
import ConsecutivoService from "../../../services/Consecutivo";
import ViaticosService from "../../../services/Viaticos";
import Model from "../Viatico.json";
import Text from "../../../common/components/Fields/Text";

class Formulario extends React.Component {

    _mounted = false;
    _init = {
        conductores: [],
        consecutivo: '----',
        date: moment().format('YYYY-MM-DD'),
    };

    constructor(props){
        super(props);
        this.state = this._init;
        this.props.save({formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({formularios: {}, repetidos: {} });
            this.setState(()=>({ edit: false }));
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.props.save({formularios:{estado:'CREADO'}});
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll(){
        await this.getItems();
        let params = this.props.match.params;
        if( params.id ) await this.getData(params.id);
    }

    async getItems(){
        let [conductores] = await Promise.all([
            ConductoresService.get(-1),
        ]);
        this.setState(()=>({ ...this._init, conductores: conductores.data }));
    }

    async getData(id){
        Helpers.loader();
        let client = await ViaticosService.getById(id);
        if( client.data.id ){
            this.props.save({formularios: client.data });
            this.setState(()=>({ edit: true, consecutivo: client.data.consecutivo, estado:client.data.estado }));
        }else{
            this.props.history.push('/viatico/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();

        let form = {
            ...store.formularios,
        };

        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();

            let consecutivo = await Promise.resolve(ConsecutivoService.get(5));
            form['consecutivo'] = this.state.consecutivo != '----' ? this.state.consecutivo : consecutivo.number.toString().padStart(4, '0');
            console.log(form);

            let method = 'save';
            if( this.state.edit ) method = 'update';
            let response = await ViaticosService[method](form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                if( this.state.edit ){
                    Helpers.alert({title: '', icon:'success', text: 'Se ha guardado correctamente!'});
                }else{
                    Helpers.alert({title: '', icon:'success', text: 'Se ha creado correctamente!'});
                }
                this.props.history.push('/viatico/list');
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    return = () => this.props.history.push('/viatico/list');

    render() {
        const {edit, conductores, consecutivo, date, estado} = this.state;
        const disabledCerrado= (estado=='CERRADO')? true: false ;

        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{edit ? 'Editar' :'Nuevo'} Viático</h2>
                    </Col>
                    <Col>
                        <div className="top">
                            <p>Consecutivo <span>{consecutivo}</span></p>
                            <p><span>{date}</span></p>
                        </div>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    <Select label="Seleccione el conductor" optionLabel={Helpers.driveLabels} hash="id" opts={conductores} name={`conductor`} disabled={disabledCerrado}  />
                                    <Text label="Estado" name={`estado`} disabled  />
                               </div>
                                <div>
                                    <Text label="Valor" name={`valor`} money disabled={disabledCerrado}  />
                                    <Text label="Observación" name={`observacion`} multi disabled={disabledCerrado} /> 
                                </div>
                               <h3/>
                                <div>
                                    <Text label="Reintegro" name={`reintegro`} money disabled={disabledCerrado} />
                                </div>

                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                    {(disabledCerrado==false)  &&
                        <Btn click={this.save} text="Guardar" type="primary" disabled={disabledCerrado}  />
                    }
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({cliente: store.formularios.cliente}),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(Formulario);
