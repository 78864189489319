import moment from "moment";
import SimpleMaskMoney from "simple-mask-money";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { save } from "./Actions";
import Loader from "./components/Loader/Loader";
import { Files } from "./Config";
import { resource } from "./lang/lang";
import Menus from "./components/MenuBar/Menus";
import Store, { State } from "./Store";

const swal = withReactContent(Swal);
const Args = {
    allowNegative: false,
    negativeSignAfter: false,
    prefix: '$',
    suffix: '',
    fixed: true,
    fractionDigits: 0,
    decimalSeparator: ',',
    thousandsSeparator: '.',
    cursor: 'end'
};

export default class Helpers {

    static ucfirst = string => string.charAt(0).toUpperCase() + string.slice(1);

    static ucText(string) {
        let split = string.split(' ');
        for (let i = 0; i < split.length; i++) split[i] = Helpers.ucfirst(split[i]);
        return split.join(' ');
    }

    static hasClass(selector, className) {
        let el = document.querySelector(selector);
        if (el != null) if (el.className.indexOf(className) !== -1) return true;
        return false;
    }

    static addClass(selector, className) {
        let el = document.querySelector(selector);
        if (el != null) if (el.className.indexOf(className) === -1) el.className += " " + className;
    }

    static removeClass(selector, className) {
        let el = document.querySelector(selector);
        if (el != null) el.className = el.className.replace(className, "");
    }

    static dataReturn = data => data ? data : {};

    static formatToMask(value) {
        if (value == null) value = 0;
        value = Helpers.formatToNumber(value);
        if (value === undefined) value = 0;
        let format = SimpleMaskMoney.formatToMask(value, Args).split(",")[0];
        return format === "$0." ? '$' : format;
    }

    static formatOnlyString(data) {
        let text = "";
        if (data) for (let i = 0; i < data.length; i++) if (Helpers.isLetter(data[i])) text += data[i];
        return text;
    }

    static formatOnlyNumbers(data) {
        let text = "";
        if (data) for (let i = 0; i < data.length; i++) if (Helpers.isNumber(data[i])) text += data[i];
        return text;
    }

    static formatToNumber = value => SimpleMaskMoney.formatToNumber(value);

    static countObject(obj) {
        let count = 0;
        for (let i in obj) count++;
        return count;
    }

    static exitsKey(obj, array) {
        let exits = false;
        for (let i in obj) {
            if (array.indexOf(i) !== -1 && obj[i]) {
                exits = true;
                break;
            }
        }
        return exits;
    }

    static getUser = () => sessionStorage.getItem("token");

    static getPathImage = path => `${Files}${path}`;

    static isLetter(character) {
        const code = character.charCodeAt();
        return (code >= 65 && code <= 90) || (code >= 97 && code <= 122) || code === 32;
    }

    static isNumber(character) {
        const code = character.charCodeAt();
        return code >= 48 && code <= 57;
    }

    static transformCell(data, max = 10) {
        if (data.toString().length > max) data = data.substring(0, max);
        if (parseInt(data[0]) !== 3 && max === 10) data = '';
        let text = "";
        for (let i = 0; i < data.length; i++) {
            let char = data[i];
            if (Helpers.isNumber(char)) text += data[i];
        }
        return text;
    }

    static logOut() {
        Loader.toogle(true);
        Store.dispatch(save(State));
        sessionStorage.clear();
        Loader.toogle(false);
    }

    static calAge(date) {
        if (date) {
            return moment().diff(date, 'years');
        } else {
            return 0;
        }
    }

    static loader() {
        swal.fire({
            title: '',
            icon: '',
            text: 'Cargando por favor espere...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            onBeforeOpen: () => Swal.showLoading()
        });
    }

    static closeLoader = () => swal.close();

    static confirm({ title = '', text = '', icon = 'warning' }) {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title,
                text,
                icon,
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) return resolve(result.value);
                return reject(result);
            })
        });
    };

    static alert({ title = '', icon = '', text = '' }) {
        swal.fire({
            title,
            icon,
            text
        });
    }

    static isValid(value) {
        try {
            return !(value === "" || value === undefined || value.length === 0 || !value);
        } catch (e) {
            console.warn(e);
            return false;
        }
    }

    static normalize(input) {
        if (!input) return;
        var acentos = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
        var original = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
        for (var i = 0; i < acentos.length; i++) {
            input = input.replace(acentos.charAt(i), original.charAt(i)).toLowerCase();
        }
        return input;
    }

    static isLogin() {
        const user = sessionStorage.getItem("token");
        return user != null && user !== "null" && user !== "{}" && user !== '{"msj":"No existe el recurso"}';
    }

    static isEmail = email => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    static validForm = (form, model) => {
        for (let i in model) {
            let field = i;
            let msjs = model[i].errors;
            let rules = model[i].rules.split('|');
            for (let j = 0; j < rules.length; j++) {
                let rule = rules[j];
                if (rule.indexOf('require') !== -1) {
                    if (!form[field] || form[field] === '') {
                        return {
                            status: false,
                            errors: [msjs['require']],
                            keys: { [`${field}_error`]: true }
                        }
                    }
                }
                if (rule.indexOf('min') !== -1) {
                    let min = rule.split(':')[1];
                    if (form[field].length < min) {
                        return {
                            status: false,
                            errors: [msjs['min']],
                            keys: { [`${field}_error`]: true }
                        }
                    }
                }
                if (rule.indexOf('email') !== -1) {
                    if (!Helpers.isEmail(form[field])) {
                        return {
                            status: false,
                            errors: [msjs['email']],
                            keys: { [`${field}_error`]: true }
                        }
                    }
                }
                if (rule.indexOf('array') !== -1) {
                    let fieldsArray = model[i].array;
                    for (let k = 0; k < form[field].length; k++) {
                        let dataForm = {};
                        let newFields = {};
                        for (let o in fieldsArray) newFields[`${o}_${k}`] = fieldsArray[o];
                        for (let o in form[field][k]) dataForm[`${o}_${k}`] = form[field][k][o];
                        let status = Helpers.validForm(dataForm, newFields);
                        if (!status.status) return status;
                    }
                }
            }
        }
        return {
            status: true
        }
    };

    static groupByKey(object) {
        let array = [];
        for (let i in object) {
            let key = i.substr(i.length - 2).replace('_', '');
            let name = i.substr(0, i.length - 2);
            if(key >= 10) { name = name.substr(0, name.length - 1) }
            if (!array[key]) array[key] = {};
            array[key][name] = object[i];
        }
        return array;
    }

    static processFormData(form) {
        let formData = new FormData();
        for (let i in form) {
            if (Array.isArray(form[i])) {
                if (form[i][0] instanceof File) {
                    formData.append(i, form[i][0]);
                } else {
                    formData.append(i, JSON.stringify(form[i]));
                }
            } else if (typeof form[i] === "object") {
                if (form[i] != null) formData.append(i, JSON.stringify(form[i]));
            } else {
                formData.append(i, form[i]);
            }
        }
        return formData;
    }

    static ungroupByKey(array) {
        let object = {};
        for (let i = 0; i < array.length; i++) for (let j in array[i]) object[`${j}_${i}`] = array[i][j];
        return object;
    }

    static removeGroupByKey(array, key) {
        let newArray = [];
        for (let i = 0; i < array.length; i++) if (i !== key) newArray.push(array[i]);
        array = newArray;
        let object = {};
        for (let i = 0; i < array.length; i++) for (let j in array[i]) object[`${j}_${i}`] = array[i][j];
        return object;
    }

    static getStatic = (code, model, key = 'code') => {
        for (let i = 0; i < model.length; i++) if (code == model[i][key]) return model[i];
        return {};
    };

    static contratosLabels = ({ consecutivo, cliente }) => `${consecutivo} ${cliente ? cliente.names : ''}`;
    static contratosLabels2 = ({ consecutivo, clientenom }) => `${consecutivo} ${clientenom}`;
    static clientsLabels = ({ names }) => `${names}`;
    static viaticosLabels = ({ id,consecutivo,created_at,valor,conductor }) => `${id} * ${consecutivo} * ${moment(created_at).format('YYYY-MM-DD')} * ${valor} * ${conductor ? conductor.name : ''} ${conductor ? conductor.surname : ''}`;
    static driveLabels = ({ name, surname }) => `${name ? name : ''} ${surname ? surname : ''}`;
    static vehicleLabels = ({ plate }) => `${plate}`;
    static sevicioLabels = ({ consecutivo }) => `${consecutivo}`;
    static citieLabels = ({ origen, destino, fecha_ida, fecha_regreso, vehicle_type }) => ` (${Helpers.getStatic(vehicle_type, resource('VehicleType')).name})  ${Helpers.getStatic(origen, resource('Cities')).name} - ${Helpers.getStatic(destino, resource('Cities')).name} - FI: ${fecha_ida} - FR: ${fecha_regreso}`;

    static hasPermiso = (role, permisos, items) => {
        permisos = permisos[role];
        let newitems = [];
        for (let i = 0; i < items.length; i++) if (permisos.indexOf(items[i].id) !== -1) newitems.push(items[i]);
        return newitems;
    };

    static hasAccion = (id, actions) => {
        let status = false;
        for (let i = 0; i < actions.length; i++) if (actions[i].id === id) status = true;
        return status;
    };

}
