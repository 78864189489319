import Api from '../common/Api';
import {Backend, headers} from "../common/Config";
import Helpers from '../common/Helpers';

export default class Auth {

    static async login(form) {
        const response = await Api.post({
            url: `${Backend}/login`,
            form,
            headers
        });
        return Helpers.dataReturn(response);
    };

    static async get(){
        const response = await Api.get({
            url: `${Backend}/user`,
            headers
        });
        return Helpers.dataReturn(response);
    }

}
