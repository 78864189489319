import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {saveData, saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Date from "../../../common/components/Fields/Date";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Time from "../../../common/components/Fields/Time";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import MantenimientoService from "../../../services/Mantenimiento";
import VehiclesService from "../../../services/Vehicles";
import ConductoresService from "../../../services/Conductores";
import Model from "../Mantenimiento.json";
import ModelCerrar from "../Cerrar.json";

class Formulario extends React.Component {

    Cities = resource('Cities');
    MatenimientoType = resource('MatenimientoType');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            vehiculos: [],
            conductores: [],
            action: props.match.params.action
        };
        this.props.save({formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({formularios: {}, repetidos: {} });
            this.setState(()=>({ edit: false }));
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll(){
        await this.getItems();
        let params = this.props.match.params;
        if( params.id ) await this.getData(params.id);
    }

    async getItems(){
        let [vehiculos, conductores] = await Promise.all([
            VehiclesService.get(-1),
            ConductoresService.get(-1),
        ]);
        this.setState(()=>({ vehiculos: vehiculos.data, conductores: conductores.data, edit: false }));
    }

    async getData(id){
        Helpers.loader();
        let client = await MantenimientoService.getById(id);
        if( client.data.id ){
            this.props.save({formularios: client.data });
            this.setState(()=>({ edit: true }));
        }else{
            this.props.history.push('/mantenimiento/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();

        let form = {
            ...store.formularios,
        };

        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();
            let method = 'save';
            if( this.state.edit ) method = 'update';
            let response = await MantenimientoService[method](form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                if( this.state.edit ){
                    Helpers.alert({title: '', icon:'success', text: 'Se ha guardado correctamente!'});
                }else{
                    Helpers.alert({title: '', icon:'success', text: 'Se ha creado correctamente!'});
                }
                this.props.history.push('/mantenimiento/list');
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    cerrar = async () => {
        let store = Store.getState();

        let form = {
            ...store.formularios,
        };

        let valid = Helpers.validForm(form, ModelCerrar);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();
            form['state'] = 1;
            let response = await MantenimientoService.update(form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                Helpers.alert({title: '', icon:'success', text: 'Se ha cerrado correctamente!'});
                this.props.history.push('/mantenimiento/list');
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    return = () => this.props.history.push('/mantenimiento/list');

    render() {
        const {props:{state}, state:{ edit, vehiculos, conductores }} = this;
        const disabled = this.state.action==='view';
        const disableActive = state !== 0 || disabled;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{this.state.edit ? this.state.action==='view'?'Ver':'Editar' :'Nuevo'} Mantenimiento</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios" disabled={disabled}>
                        <Row className="row">
                            <Col>
                                <div>
                                    <Select label="Seleccione el vehiculo" opts={vehiculos} optionLabel={Helpers.vehicleLabels} hash={'id'} name="vehiculo" />
                                    <Select label="Seleccione el conductor" opts={conductores} optionLabel={Helpers.driveLabels} hash={'id'} name="conductor" />
                                </div>
                                <div>
                                    <Date today label="Fecha de inicio" name="init_date" />
                                    <Time label="Hora de inicio" name="init_hour" />
                                </div>
                                <div>
                                    <Date today label="Fecha de final" name="end_date" disabled={disableActive} />
                                    <Time label="Hora de final" name="end_hour" disabled={disableActive} />
                                </div>
                                <div>
                                    <Select label="Tipo de mantenimiento" opts={this.MatenimientoType} name="type" />
                                    <Text label="Comentario" name="commen" multi />
                                </div>
                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        {state === 0 && <Btn click={this.cerrar} text="Cerrar" type="primary" disabled={disabled} />}
                        {state !== 0 && <Btn click={this.save} text="Guardar" type="primary" disabled={disabled} />}
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({state: store.formularios.state}),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(Formulario);
