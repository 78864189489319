import {Popper} from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import React from "react";
import {VariableSizeList} from "react-window";
import Helpers from "../../../../../translaval-front/src/common/Helpers";
import HelperText from "../../../../../translaval-front/src/common/components/Fields/HelperText";

const LISTBOX_PADDING = 0; // px
function renderRow(props) {
    let content;
    const {data, index, style} = props;
    if (index === (data.length - 1)) {
        // content = "Loading...";
        content = data[index].props.children;
    } else {
        content = data[index].props.children;
    }
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING
        }
    }, content);
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const {children, ...other} = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 42;

    const getChildSize = child => {
        if (React.isValidElement(child) && child.type === ListSubheader) return 48;
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) return 8 * itemSize;
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const scroll = props => e => {
        let list = document.querySelector(".list-autocomplete");
        let ul = document.querySelector(".list-autocomplete ul");
        const calc = (ul.scrollHeight + 16) - list.clientHeight;
        if (e.scrollOffset >= calc && props.scroll) props.scroll();
    };

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    key={itemCount}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={index => getChildSize(itemData[index])}
                    overscanCount={5}
                    onScroll={scroll(props)}
                    itemCount={itemCount}>
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );

});

ListboxComponent.propTypes = {
    children: PropTypes.node,
    scroll: PropTypes.func
};

class Virtualize extends React.Component {

    _mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            inputValue: ''
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.updateValue();
    }

    componentWillUnmount = () => this._mounted = false;
    componentDidUpdate = (prevProps, prevState, snapshot) => this.updateValue(prevProps, prevState, snapshot);

    updateValue(prevProps = {defaultValue:''}, prevState = {inputValue: ''}, snapshot) {
        if(!this._mounted)return;
        const {defaultValue, getOptionLabel} = this.props;
        if (getOptionLabel && this.props.defaultValue !== prevProps.defaultValue) {
            let value = defaultValue;
            value = value == null ? this.state.inputValue : value;
            if( typeof value === 'object' && value != null && value !== undefined )value = this.props.getOptionLabel(value);
            if (value !== this.state.inputValue) {
                if( this.props.multi ) {
                    if(value.indexOf('undefined') === -1) this.setState(() => ({inputValue: value}));
                }else{
                    this.setState(() => ({inputValue: value}));
                }
            }
        }
    }

    onInputChange = (event, value, reason) => {
        if(!this._mounted)return;
        if (reason === 'input') {
            if (this.props.onlyString) value = Helpers.formatOnlyString(value);
            this.setState(() => ({inputValue: value}));
        }else if( reason === 'reset'){
            if( event != null ){
                if( event.keyCode === 13 ) this.setState(() => ({inputValue: ''}));
            }
        }else if(reason === 'clear'){
            this.setState(() => ({inputValue: ''}));
        }
    };

    change = (e, v) => {
        if(!this._mounted)return;
        this.setState(() => ({inputValue: ''}));
        this.props.onChange(e, v);
    };

    render() {
        let {
            classes,
            className,
            getOptionLabel,
            defaultValue,
            options,
            multi,
            label,
            disabled,
            freeSolo,
            clearable,
            disableCloseOnSelect,
            error,
            blur,
            helpe,
            scroll,
            nextpage
        } = this.props;
        const {inputValue} = this.state;

        defaultValue = (defaultValue == null || defaultValue === undefined || defaultValue === "") && multi ? [] : defaultValue;
        return (
            <Autocomplete
                style={{width: '100%'}}
                disableListWrap
                inputValue={inputValue}
                onInputChange={this.onInputChange}
                className={`${className} ${multi?'milti-field':''}`}
                classes={{
                    ...classes, ...{
                        'listbox': 'list-autocomplete'
                    }
                }}
                ListboxProps={{scroll, nextpage}}
                ListboxComponent={ListboxComponent}
                renderGroup={params => [
                    <ListSubheader key={params.key} component="div">{params.key}</ListSubheader>, params.children
                ]}
                getOptionLabel={getOptionLabel}
                defaultValue={defaultValue}
                options={options}
                multiple={multi}
                disabled={disabled}
                freeSolo={freeSolo}
                value={defaultValue}
                disableClearable={!clearable}
                disableCloseOnSelect={!disableCloseOnSelect}
                blurOnSelect={false}

                // debug={true}
                PopperComponent={props => (
                    <Popper
                        {...props}
                        modifiers={{
                            flip: {
                                enabled: true,
                            },
                            preventOverflow: {
                                enabled: false,
                                boundariesElement: 'scrollParent',
                            },
                        }}/>
                )}
                onChange={this.change}
                renderInput={params => {
                    return (
                        <TextField onBlur={blur} error={error} {...params}
                                   label={<React.Fragment>{label}<HelperText value={helpe}/></React.Fragment>}
                                   variant="outlined" fullWidth/>
                    );
                }}
            />
        );
    }
}

Virtualize.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    helpe: PropTypes.string,
    getOptionLabel: PropTypes.func,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    className: PropTypes.string,
    classes: PropTypes.object,
    multi: PropTypes.bool,
    disabled: PropTypes.bool,
    freeSolo: PropTypes.bool,
    clearable: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    error: PropTypes.bool,
    blur: PropTypes.func,
    onlyString: PropTypes.bool
};

export default Virtualize;
