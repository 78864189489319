import React from 'react';
import Formulario from "./components/Formulario";
import Lista from "./components/Lista";

const Margenes = props => {
    const path = props.match.path;
    return (
        <React.Fragment>
            {(path === '/margenes/nuevo' || path === '/margenes/edit/:id') && <Formulario {...props}/>}
            {path === '/margenes' && <Lista {...props}/>}
        </React.Fragment>
    );
};

export default Margenes;
