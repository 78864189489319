import React from 'react';
import Formulario from "./components/Formulario";
import Lista from "./components/Lista";

const Fuec = props => {
    const path = props.match.path;
    return (
        <React.Fragment>
            {(path === '/fuec/new' || path.indexOf('/fuec/:action/:id') === 0) && <Formulario {...props}/>}
            {path === '/fuec/list' && <Lista {...props}/>}
        </React.Fragment>
    );
};

export default Fuec;
