import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import moment from 'moment';
import PropTypes from "prop-types";
import React from 'react';
import {connect} from 'react-redux';
import HelperText from "./HelperText";

const Time = ({value, save, label, name, years, error, helpe, disabled, today, change}) => {
    value = value ? moment(value, 'h:mm:ss a') : null;
    const handler = e => {
        if( disabled ) return null;
        save({[name]: moment(e).format('h:mm:ss a')});
        if(change) change(e);
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            {disabled && <TextField disabled={disabled} className={'date-field'} value={moment(value).format('h:mm:ss a')} error={error}/>}
            {!disabled &&<KeyboardTimePicker
                placeholder="h:mm:ss a"
                margin="normal"
                autoOk={false}
                orientation={'portrait'}
                value={value}
                onChange={handler}
                minDate={today?moment()._d:moment('1900-01-01')._d}
                label={<React.Fragment>{label} <HelperText value={helpe}/></React.Fragment>}
                TextFieldComponent={(props) => {
                    return (<TextField disabled={disabled} className={'date-field'} {...props} error={error}/>);
                }}
            />}
        </MuiPickersUtilsProvider>
    );
};

Time.propTypes = {
    store: PropTypes.string,
    save: PropTypes.func,
    change: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    helpe: PropTypes.string,
    years: PropTypes.bool,
    disabled: PropTypes.bool,
    today: PropTypes.bool,
};

export default connect(
    (store, props) => {
        props.name = props.name ? props.name : '';
        props.label = props.label ? props.label : '';
        props.years = props.years !== undefined ? props.years : false;
        return {
            value: props.store ? store[props.store][props.name] : undefined,
            error: store.errors[`${props.name}_error`]
        };
    },
    (dispatch, props) => ({save: data => dispatch(props.save(data))}),
)(Time);

