import PropTypes from "prop-types";
import React from 'react';
import {Col, Row} from 'react-grid-system';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {connect} from "react-redux";
import {saveData, saveFormulariosData, saveRepetidosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import CotizacionServcices from '../../../services/Cotizacion';
import ClientsServcices from '../../../services/Clients';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import Collapse from '@material-ui/core/Collapse';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Modal from "./Modal";

class Lista extends React.Component {

    WayPay = resource('WayPay');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0,
            active: null,
            type: props.type,
            open: false,
            clients: []
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll(){
        let [clients] = await Promise.all([
            ClientsServcices.get()
        ]);
        this.setState(()=>({ clients: clients.data }));
        await this.getData();
    }

    async getData(){
        Helpers.loader();
        let form = Store.getState().formularios;
        let cotizacion = await CotizacionServcices.getState(0, this.state.type, form);
        this.setState(()=>({data: cotizacion.data, total: cotizacion.total}));
        Helpers.closeLoader();
    }

    edit = id => () => this.props.history.push(`/cotizacion/edit/${id}`);
    view = id => () => this.props.history.push(`/cotizacion/view/${id}`);

    changePage = async (event, page) => {
        Helpers.loader();
        let cotizacion = await CotizacionServcices.getState(page, this.state.type);
        this.setState(()=>({data: cotizacion.data, total: cotizacion.total, page}));
        Helpers.closeLoader();
    };

    generate = id => async () => {
        Helpers.loader();
        let doc = await CotizacionServcices.doc(id);
        let a = document.createElement('a');
        a.download = doc.name;
        a.href = doc.data;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        Helpers.closeLoader();
    };

    aproved = id => async () => {
        this.setState(()=>({open: true}));
        // Helpers.loader();
        // await CotizacionServcices.update({id, state: 1});
        // await this.getData();
        // Helpers.alert({title: '', icon:'success', text: 'Se ha aprobado correctamente!'});
    };

    reject = id => async () => {
        Helpers.loader();
        await CotizacionServcices.update({id, state: 2});
        await this.getData();
        Helpers.alert({title: '', icon:'success', text: 'Se ha rechazado correctamente!'});
    };

    setOpen = active => () => {
        if( active === this.state.active ) return this.setState(()=>({active: null}));
        this.setState(()=>({active}));
    };

    closeModal = async data => {
        this.setState(()=>({open: false}));
        await this.getData();
    };

    search = () => {
        this.setState(()=>({ page: 0 }));
        this.getData();
    };

    render() {
        const {state, props:{campo}} = this;
        return (
            <div className="wrap table">
                <Row>
                    <Provider store={'formularios'} save={saveFormulariosData}>
                        <Col md={10}>
                            {campo === 'client' &&
                            <Select name={'search'} label={'Buscar...'} optionLabel={Helpers.clientsLabels} opts={state.clients} hash={'id'} />
                            }
                            {campo === 'pay' &&
                            <Select name={'search'} label={'Buscar...'} opts={this.WayPay} />
                            }
                            {campo === 'consecutivo' &&
                            <Text name={'search'} label={'Buscar...'}/>
                            }
                            <Select name={'campo'} opts={[
                                {
                                    name: 'Número de orden',
                                    code: 'consecutivo'
                                },
                                {
                                    name: 'Cliente',
                                    code: 'client'
                                },
                                {
                                    name: 'Forma de pago',
                                    code: 'pay'
                                }
                            ]} />
                        </Col>
                        <Col md={2}>
                            <Btn text={"Buscar"} type={'primary'} class={'btn-block'} click={this.search}/>
                        </Col>
                    </Provider>
                </Row>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Número de orden</TableCell>
                                <TableCell>Cliente</TableCell>
                                <TableCell>Forma de pago</TableCell>
                                <TableCell>Valor del servicio</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(state.data) && state.data.map((item,key)=>
                                <React.Fragment key={key}>
                                    <TableRow onClick={this.setOpen(key)} className={`${key===state.active?'active-row':''}`}>
                                        <TableCell>{item.consecutivo}</TableCell>
                                        <TableCell>{item.client.names}</TableCell>
                                        <TableCell>{Helpers.getStatic(item.waypay, this.WayPay).name}</TableCell>
                                        <TableCell>{item.total}</TableCell>
                                        <TableCell className="last-td">
                                            {item.state === 0 &&<IconButton size="small" onClick={this.edit(item.id)}>
                                                <EditIcon />
                                            </IconButton>}
                                            {item.state !== 0 &&<IconButton size="small" onClick={this.view(item.id)}>
                                                <VisibilityIcon />
                                            </IconButton>}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="no-border" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                            <Collapse in={key===state.active} timeout="auto" unmountOnExit>
                                                <Row className="colpwrap">
                                                    <Col>
                                                        <Btn type="primary" text="Generar documento" click={this.generate(item.id)} />
                                                        {item.state === 0 &&
                                                        <React.Fragment>
                                                            <Btn type="primary" text="Editar" click={this.edit(item.id)} />
                                                            <Btn type="primary" text="Confirmar" click={this.aproved(item.id)} />
                                                            <Btn class="btn-danger" text="Rechazar" click={this.reject(item.id)} />
                                                        </React.Fragment>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )}
                        </TableBody>

                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={state.total}
                        rowsPerPage={10}
                        page={state.page}
                        onChangePage={this.changePage}
                    />
                </TableContainer>
                <Modal open={state.open} data={state.data[state.active]} close={this.closeModal} />
            </div>
        );
    }
}

Lista.propTypes = {
    type: PropTypes.number,
};

export default connect(
    (store) => ({
        campo: store.formularios.campo
    }),
    (dispatch) => ({}),
)(Lista);
