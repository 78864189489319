import React from 'react';
import {Col, Row} from 'react-grid-system';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {connect} from "react-redux";
import {saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ConductoresServcices from '../../../services/Conductores';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';

class Lista extends React.Component {

    Type = resource('Type');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.getData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getData(){
        Helpers.loader();
        let form = Store.getState().formularios;
        let clients = await ConductoresServcices.get(this.state.page, form);
        this.setState(()=>({data: clients.data, total: clients.total}));
        Helpers.closeLoader();
    }

    edit = id => () => this.props.history.push(`/condutcor/edit/${id}`);

    changePage = async (event, page) => {
        Helpers.loader();
        let clients = await ConductoresServcices.get(page);
        this.setState(()=>({data: clients.data, total: clients.total, page}));
        Helpers.closeLoader();
    };

    search = () => {
        this.setState(()=>({ page: 0 }));
        this.getData();
    };

    render() {
        const {state, props:{campo}} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Conductores</h2>
                    </Col>
                </Row>
                <div className="wrap table">
                    <Row>
                        <Provider store={'formularios'} save={saveFormulariosData}>
                            <Col md={10}>
                                <Text name={'search'} label={'Buscar...'}/>
                                <Select name={'campo'} opts={[
                                    {
                                        name: 'Nombre',
                                        code: 'name'
                                    },
                                    {
                                        name: 'Apellidos',
                                        code: 'surname'
                                    },
                                    {
                                        name: 'Dirección',
                                        code: 'adress'
                                    },
                                    {
                                        name: 'Telefono',
                                        code: 'phone'
                                    },
                                    {
                                        name: 'Email',
                                        code: 'email'
                                    },
                                ]} />
                            </Col>
                            <Col md={2}>
                                <Btn text={"Buscar"} type={'primary'} class={'btn-block'} click={this.search}/>
                            </Col>
                        </Provider>
                    </Row>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Apellidos</TableCell>
                                    <TableCell>Dirección</TableCell>
                                    <TableCell>Telefono</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(state.data) && state.data.map((item,key)=>
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.surname}</TableCell>
                                            <TableCell>{item.adress}</TableCell>
                                            <TableCell>{item.phone}</TableCell>
                                            <TableCell>{item.email}</TableCell>
                                            <TableCell className="last-td">
                                                <IconButton size="small" onClick={this.edit(item.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={state.total}
                            rowsPerPage={10}
                            page={state.page}
                            onChangePage={this.changePage}
                        />
                    </TableContainer>
                </div>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        campo: store.formularios.campo
    }),
    (dispatch) => ({}),
)(Lista);

