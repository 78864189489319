import React from 'react';
import Formulario from "./components/Formulario";
import Lista from "./components/Lista";

const Ubicaciones = props => {
    const path = props.match.path;
    return (
        <React.Fragment>
            {(path === '/ubicaciones/nuevo' || path === '/ubicaciones/edit/:id') && <Formulario {...props}/>}
            {path === '/ubicaciones' && <Lista {...props}/>}
        </React.Fragment>
    );
};

export default Ubicaciones;
