//Resource
import es from './es.json';

const translation = key => {
    return es[key];
};

export const resource = key => {
    try {
        return require(`../resources/db/${key}.json`);
    } catch (e) {
        console.log(e);
        return [];
    }
};

export default translation;
