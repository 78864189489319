import React from 'react';
import Formulario from "./components/Formulario";
import Lista from "./components/Lista";

const Colaborador = props => {
    const path = props.match.path;
    return (
        <React.Fragment>
            {(path === '/colaboradores/new' || path.indexOf('/colaboradores/edit') === 0) && <Formulario {...props}/>}
            {path === '/colaboradores/list' && <Lista {...props}/>}
        </React.Fragment>
    );
};

export default Colaborador;
