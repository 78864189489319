import PropTypes from "prop-types";
import React from 'react';
import {connect} from "react-redux";
import {Route as Router} from "react-router";
import Auth from "../../../services/Auth";
import {saveData} from "../../Actions";
import Helpers from "../../Helpers";

class Route extends React.Component {

    _mounted = false;

    componentDidMount() {
        this._mounted = true;
        this.getUser();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.getUser();
    }

    getUser = async () => {
        let {save, user} = this.props;
        if (Helpers.isLogin() && !user.id) {
            Helpers.loader();
            let user = await Auth.get();
            if (user.id) save({user});
            Helpers.closeLoader();
        }
    };

    render() {
        const {props: {component: Component, restricted, ...rest}} = this;
        return (
            <Router {...rest} render={props => (<Component {...props} />)}/>
        );
    }
}

Route.propTypes = {
    restricted: PropTypes.bool,
    partial: PropTypes.bool
};

export default connect(
    (store) => ({user: store.user}),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(Route);

