import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from "react-redux";
import { saveData, saveFormulariosData } from "../../common/Actions";
import Btn from "../../common/components/Btn/Btn";
import Date from "../../common/components/Fields/Date";
import Select from "../../common/components/Fields/Select";
import Provider from "../../common/components/Provider/Provider";
import Helpers from "../../common/Helpers";
import { resource } from "../../common/lang/lang";
import Store from "../../common/Store";
import InformesService from "../../services/Informes";
import Model from "./Informes.json";
import ViaticosServices from "../../services/Viaticos";

class Informes extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            listViaticos: []
        }
    }

    Reports = resource('Report');
    _mounted = false;

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    // getData() {
    //     ViaticosServices.get(-1).then(response => {
    //         if (response.total) {
    //             this.setState({ listViaticos: response.data })
    //         }
    //     })
    // }

    verifyViatico = () => {
        let store = Store.getState();

        let form = { ...store.formularios };

        if (form.type == 5 && form.init && form.end) {
            let data = {
                init: form.init,
                end: form.end
            }
            console.log(form)
            ViaticosServices.getForDate(data).then(response => {
                console.log(response);
                this.setState({listViaticos: response.data})
            })
        }
    }

    save = async () => {
        let store = Store.getState();

        let form = { ...store.formularios };

        let valid = Helpers.validForm(form, Model);
        if (valid.keys) {
            this.props.save({ errors: valid.keys });
            Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
        } else {
            this.props.save({ errors: {} });
            Helpers.loader();
            let doc = await InformesService.get(form);
            let a = document.createElement('a');
            a.download = doc.name;
            a.href = doc.data;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            Helpers.closeLoader();
        }
    };

    render() {
        const { state, props: { type, init, end } } = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Informes</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    <Select opts={this.Reports} label="Tipo de reporte" name="type" className="w-100" change={this.verifyViatico} />
                                </div>
                                <div>
                                    <Date label="Fecha de inicio" name="init" className="w-100" change={this.verifyViatico} />
                                    <Date label="Fecha de fin" name="end" className="w-100 max" change={this.verifyViatico} />
                                </div>
                                {
                                    (type == 5 && init != null && end != null && state.listViaticos.length) &&
                                    <Select opts={state.listViaticos} label="Viatico" name="viatico" className="w-100" hash="id" optionLabel={Helpers.viaticosLabels} />
                                }
                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        <Btn click={this.save} text="Guardar" type="primary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        type: store.formularios.type,
        init: store.formularios.init,
        end: store.formularios.end
    }),
    (dispatch) => ({ save: data => dispatch(saveData(data)) }),
)(Informes);
