import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Helpers from "../../Helpers";
import translation from "../../lang/lang";
import './MenuBar.scss';
import Logo from '../../resources/images/logo.png';
import PersonIcon from '@material-ui/icons/Person';
import Menus from './Menus.json';
import Permisos from './Permisos.json';


import icon_afiliados from '../../resources/images/menu/icon_afiliados.png';
import icon_calendario from '../../resources/images/menu/icon_calendario.png';
import icon_comercial from '../../resources/images/menu/icon_comercial.png';
import icon_conductor from '../../resources/images/menu/icon_conductor.png';
import icon_configuracion from '../../resources/images/menu/icon_configuracion.png';
import icon_proveedores from '../../resources/images/menu/icon_proveedores.png';
import icon_vehiculo from '../../resources/images/menu/icon_vehiculo.png';
import icon_operaciones from '../../resources/images/menu/icon_operaciones.png';

import icon_aux_operaciones from '../../resources/images/menu/icon_aux_operaciones.png';
import icon_colaborador from '../../resources/images/menu/icon_colaborador.png';
import icon_servicios from '../../resources/images/menu/icon_servicios.png';
import icon_usuario from '../../resources/images/menu/icon_usuario.png';

class MenuBar extends React.Component {

    _mounted = false;
    static excludeClass = [
        '.dashboard-page'
    ];

    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            user: props.user,
            items: []
        };
        let permisos = Permisos[this.state.user.role];
        for (let i = 0; i < Menus.length; i++) {
            let children = [];
            for (let j = 0; j < Menus[i].children.length; j++) {
                if(permisos.indexOf(Menus[i].children[j].id) !== -1) children.push(Menus[i].children[j]);
            }
            if( children.length > 0 ) {
                Menus[i].children = children;
                this.state.items.push(Menus[i]);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.location) !== JSON.stringify(this.props.location)) this.setActive();
    }

    componentDidMount() {
        this._mounted = true;
        this.setActive();
    }
    componentWillUnmount() {
        this._mounted = false;
    }

    setActive(){
        let {state} = this;
        for (let i = 0; i < state.items.length; i++) for (let j = 0; j < state.items[i].children.length; j++) {
            state.items[i].children[j]['active'] = false;
            if( window.location.pathname === state.items[i].children[j]['path'] ){
                state.items[i].children[j]['active'] = true;
                state.active = i;
            }
        }
        if(this._mounted) this.setState(()=>({ state }));
    }

    static toggle(status) {
        status = status !== undefined ? status : !Helpers.hasClass('.menu-bar-component', 'open');
        if (status === true) {
            Helpers.addClass('.menu-bar-component', 'open');
            Helpers.addClass('.col-items.menu', 'active');
            if (window.innerWidth > 768) {
                for (let i = 0; i < MenuBar.excludeClass.length; i++) {
                    Helpers.addClass(MenuBar.excludeClass[i], 'active');
                }
            }
        } else {
            Helpers.removeClass('.menu-bar-component', 'open');
            Helpers.removeClass('.col-items.menu', 'active');
            if (window.innerWidth > 768) {
                for (let i = 0; i < MenuBar.excludeClass.length; i++) {
                    Helpers.removeClass(MenuBar.excludeClass[i], 'active');
                }
            }
        }
    }

    changeActive = key => () => {
        if (this.state.active === key) return this.setState(() => ({active: 9999}));
        if(this._mounted) this.setState(() => ({active: key}));
    };

    click = (path, key) => () => {
        if (path === '/') Helpers.logOut();
        this.props.history.push(path);
        this.setActive();
        window.location.reload(false);
    };

    render() {
        const {changeActive, state: {active, items}, props} = this;
        return (
            <Row className="menu-bar-component">
                <Col>
                    <Row>
                        <Col>
                            <div className="logodiv">
                                <img src={Logo} alt=""/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="user">
                            <div className="img"><PersonIcon/></div>
                            <h3>Usuario</h3>
                            <h4>{props.user.names}</h4>
                        </Col>
                    </Row>
                    <Row nogutter={true} className="main-menu">
                        <Col>
                            <List component="nav" aria-labelledby="nested-list-subheader"
                                  subheader={
                                      <ListSubheader onClick={this.click(0)}
                                                     component="div">{translation('job_panel')}</ListSubheader>
                                  }>
                                <div className="content-list">
                                    {items.map(({text, children}, key) =>
                                        <React.Fragment key={key}>
                                            <ListItem button onClick={changeActive(key)}>
                                                <ListItemIcon>
                                                    <span className='icons-list'>
                                                        {key === 0 && <img src={icon_comercial}/>}
                                                        {key === 1 && <img src={icon_operaciones}/>}
                                                        {key === 2 && <img src={icon_conductor}/>}
                                                        {key === 3 && <img src={icon_vehiculo}/>}
                                                        {key === 4 && <img src={icon_proveedores}/>}
                                                        {key === 5 && <img src={icon_afiliados}/>}
                                                        {key === 6 && <img src={icon_calendario}/>}
                                                        {key === 7 && <img src={icon_configuracion}/>}
                                                    </span>
                                                </ListItemIcon>
                                                <ListItemText primary={text}/>
                                                {active === key ? <ExpandLess/> : <ExpandMore/>}
                                            </ListItem>
                                            {children &&
                                            <Collapse in={active === key} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {children.map(({text, id, path, active}, k) =>
                                                        <ListItem button key={k} className={active?'active':''} onClick={this.click(path, key)}>
                                                            <ListItemText primary={text}/>
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Collapse>
                                            }
                                        </React.Fragment>
                                    )}
                                </div>
                            </List>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default connect((store) => ({user: store.user}))(withRouter(MenuBar));
