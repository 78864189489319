import {esES} from '@material-ui/core/locale';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import moment from "moment";
import React, {Suspense} from 'react';
import {Container, setConfiguration} from 'react-grid-system';
import {connect} from "react-redux";
import {Switch} from "react-router";
import Loader from "../../common/components/Loader/Loader";
import MainLayout from "../../common/components/MainLayout/MainLayout";
import Route from "../../common/components/Route/Route";
import '../../common/scss/general.scss';
import Afiliados from "../Afiliados/Afiliados";
import Calendario from "../Calendario/Calendario";
import Clientes from "../Clientes/Clientes";
import Colaborador from "../Colaborador/Colaborador";
import Conductores from "../Conductores/Conductores";
import Contrato from "../Contratos/Contrato";
import CosteoRutas from '../CosteoRutas/CosteoRutas';
import Cotizacion from "../Cotizacion/Cotizacion";
import DashBoard from "../DashBoard/DashBoard";
import Fuec from "../Fuec/Fuec";
import Informes from "../Informes/Informes";
import Login from "../Login/Login";
import Logs from "../Logs/Logs";
import Mantenimiento from "../Mantenimiento/Mantenimiento";
import Margenes from '../Margenes/Margenes';
import NotFound from "../NotFound/NotFound";
import Notificaciones from "../Notificaciones/Notificaciones";
import Proveedores from "../Proveedores/Proveedores";
import ReferenciaFuec from "../ReferenciaFuec/ReferenciaFuec";
import Servicio from "../Servicio/Servicio";
import Solicitudes from '../Solicitudes/Solicitudes';
import Ubicaciones from '../Ubicaciones/Ubicaciones';
import Usuarios from "../Usuarios/Usuarios";
import Vehiculos from "../Vehiculos/Vehiculos";
import Viatico from "../Viatico/Viatico";

const Colors = {
    "main": "#8ee110",
    "light": "#a8ff10",
    "dark": "#8cd710",
};

const Theme = createMuiTheme({
    palette: {
        primary: {
            main: Colors.dark,
            light: Colors.light,
            dark: Colors.dark
        }
    }
}, esES);

setConfiguration({breakpoints: [540, 769, 930, 1140], containerWidths: [576, 770, 1022, 1200]});

moment.locale('es');

const App = ({user}) => {
    return (
        <ThemeProvider theme={Theme}>
            <Container fluid className="transalaval-app">
                <Suspense fallback={<Loader/>}>
                    {!user.id ? (
                        <Switch>
                            <Route exact path="*" component={Login}/>
                        </Switch>
                    ) : (
                        <MainLayout>
                            <Switch>
                                <Route restricted exact path={["/", "/dashboard"]} component={DashBoard}/>

                                {/*edit, view*/}
                                <Route restricted exact path="/cotizacion/new" component={Cotizacion}/>
                                <Route restricted exact path="/cotizacion/:action/:id" component={Cotizacion}/>
                                <Route restricted exact path="/cotizacion/list" component={Cotizacion}/>

                                <Route restricted exact path="/cliente/new" component={Clientes}/>
                                <Route restricted exact path="/cliente/edit/:id" component={Clientes}/>
                                <Route restricted exact path="/cliente/list" component={Clientes}/>

                                <Route restricted exact path="/servicio/new" component={Servicio}/>
                                <Route restricted exact path="/servicio/:action/:id" component={Servicio}/>
                                <Route restricted exact path="/servicio/list" component={Servicio}/>
                                <Route restricted exact path="/serviciotwnel/:id" component={Servicio}/>


                                <Route restricted exact path="/solicitud/new" component={Solicitudes}/>
                                <Route restricted exact path="/solicitud/:action/:id" component={Solicitudes}/>
                                <Route restricted exact path="/solicitud/list" component={Solicitudes}/>

                                <Route restricted exact path="/fuec/new" component={Fuec}/>
                                <Route restricted exact path="/fuec/:action/:id" component={Fuec}/>
                                <Route restricted exact path="/fuec/list" component={Fuec}/>

                                <Route restricted exact path="/contrato/new" component={Contrato}/>
                                <Route restricted exact path="/contrato/edit/:id" component={Contrato}/>
                                <Route restricted exact path="/contrato/list" component={Contrato}/>

                                <Route restricted exact path="/viatico/new" component={Viatico}/>
                                <Route restricted exact path="/viatico/edit/:id" component={Viatico}/>
                                <Route restricted exact path="/viatico/list" component={Viatico}/>

                                <Route restricted exact path="/condutcor/new" component={Conductores}/>
                                <Route restricted exact path="/condutcor/edit/:id" component={Conductores}/>
                                <Route restricted exact path="/condutcor/list" component={Conductores}/>

                                <Route restricted exact path="/vehiculo/new" component={Vehiculos}/>
                                <Route restricted exact path="/vehiculo/edit/:id" component={Vehiculos}/>
                                <Route restricted exact path="/vehiculo/list" component={Vehiculos}/>

                                <Route restricted exact path="/mantenimiento/new" component={Mantenimiento}/>
                                <Route restricted exact path="/mantenimiento/:action/:id" component={Mantenimiento}/>
                                <Route restricted exact path="/mantenimiento/list" component={Mantenimiento}/>

                                <Route restricted exact path="/proveedor/new" component={Proveedores}/>
                                <Route restricted exact path="/proveedor/edit/:id" component={Proveedores}/>
                                <Route restricted exact path="/proveedor/list" component={Proveedores}/>

                                <Route restricted exact path="/afiliado/new" component={Afiliados}/>
                                <Route restricted exact path="/afiliado/edit/:id" component={Afiliados}/>
                                <Route restricted exact path="/afiliado/list" component={Afiliados}/>

                                <Route restricted exact path="/calendario" component={Calendario}/>

                                <Route restricted exact path="/usuario/new" component={Usuarios}/>
                                <Route restricted exact path="/usuario/edit/:id" component={Usuarios}/>
                                <Route restricted exact path="/usuario/list" component={Usuarios}/>

                                <Route restricted exact path="/colaboradores/new" component={Colaborador}/>
                                <Route restricted exact path="/colaboradores/edit/:id" component={Colaborador}/>
                                <Route restricted exact path="/colaboradores/list" component={Colaborador}/>

                                <Route restricted exact path="/fuect/ref" component={ReferenciaFuec}/>
                                <Route restricted exact path="/notifications" component={Notificaciones}/>
                                <Route restricted exact path="/logs" component={Logs}/>
                                <Route restricted exact path="/informes" component={Informes}/>

                                <Route restricted exact path="/ubicaciones" component={Ubicaciones} />
                                <Route restricted exact path="/ubicaciones/nuevo" component={Ubicaciones} />
                                <Route restricted exact path="/ubicaciones/edit/:id" component={Ubicaciones} />

                                <Route restricted exact path="/margenes" component={Margenes} />
                                <Route restricted exact path="/margenes/nuevo" component={Margenes} />
                                <Route restricted exact path="/margenes/edit/:id" component={Margenes} />

                                
                                <Route restricted exact path="/costeorutas" component={CosteoRutas} />
                                <Route restricted exact path="/costeorutas/nuevo" component={CosteoRutas} />
                                <Route restricted exact path="/costeorutas/edit/:id" component={CosteoRutas} />

                                <Route component={NotFound}/>
                            </Switch>
                        </MainLayout>
                    )}
                </Suspense>
            </Container>
        </ThemeProvider>
    );
};

export default connect(
    (store) => ({user: store.user})
)(App);
