import PropTypes from "prop-types";
import React from "react";
import {DirectionsRenderer, GoogleMap, withGoogleMap, withScriptjs,} from "react-google-maps";
import {GOOGLE_API} from '../../Config';
import {resource} from "../../lang/lang";
import Text from "./Text";

class MapDirectionsRenderer extends React.Component {

    state = {
        directions: null,
        markers: [],
        error: null
    };

    componentDidMount() {
        this.process();
    }

    sleep = miliseconds => {
        var currentTime = new Date().getTime();
        while (currentTime + miliseconds >= new Date().getTime()) {
        }
    };

    async process() {
        let markers = this.props.markers;
        // window.markers = [];
        // let citiescoors = resource('CitiesCoors');
        // let cities = resource('Cities');
        // // eslint-disable-next-line no-undef
        // const geocoder = new google.maps.Geocoder();
        // // cities = [{name: 'Bochalema'}]
        // for (let i = 0; i < cities.length; i++) {
        //     let exits = false;
        //     for (let j = 0; j < citiescoors.length; j++) {
        //         if( citiescoors[j].code === cities[i].code ) exits = true;
        //     }
        //
        //     if( exits === false ){
        //         await new Promise(resolve => {
        //             geocoder.geocode({
        //                 'address': `${cities[i].name}, ${cities[i].dpto}`
        //             }, (results, status) => {
        //                 // eslint-disable-next-line no-undef
        //                 if (status == google.maps.GeocoderStatus.OK) {
        //                     var Lat = results[0].geometry.location.lat();
        //                     var Lng = results[0].geometry.location.lng();
        //                     window.markers.push({
        //                         ...cities[i],
        //                         location: {
        //                             latitude: Lat,
        //                             longitude: Lng
        //                         }
        //                     });
        //                     return resolve();
        //                 }
        //                 console.log(cities[i].name);
        //                 resolve();
        //             });
        //         });
        //         this.sleep(100);
        //     }
        // }
        // return;
        // if (markers.length === 0) return;

        const waypoints = markers.map(p => ({
            location: {lat: p.latitude, lng: p.longitude},
            stopover: true
        }));
        const origin = waypoints[0].location;
        const destination = waypoints[waypoints.length-1].location;

        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService();
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                // eslint-disable-next-line no-undef
                travelMode: google.maps.TravelMode.DRIVING,
                waypoints: []
            },
            (result, status) => {
                // eslint-disable-next-line no-undef
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({directions: result});
                } else {
                    this.setState({error: result});
                }
            }
        );
    }

    render() {
        const {props, state: {error, directions}} = this;
        if (error||!directions) return (<h1>Error</h1>);
        return (
            <React.Fragment>
                <GoogleMap
                    defaultCenter={props.defaultCenter}
                    defaultZoom={props.defaultZoom}>
                    <DirectionsRenderer directions={directions}/>
                </GoogleMap>
                <br/>
                <br/>
                {directions &&
                <div>
                    <Text disabled value={directions.routes[0].legs[0].distance.text} label="Distancia recorrida" />
                    <Text disabled value={directions.routes[0].legs[0].duration.text} label="Duración del recorriedo" />
                </div>
                }
                <br/>
            </React.Fragment>
        )
    }
}

const Map = withScriptjs(withGoogleMap(MapDirectionsRenderer));

const MapContainer = props => {
    return (
        <Map
            googleMapURL={'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_API + '&libraries=geometry,drawing,places'}
            markers={props.points}
            loadingElement={<div style={{height: `100%`}}/>}
            containerElement={<div style={{height: "auto"}}/>}
            mapElement={<div style={{height: `450px`}}/>}
            defaultZoom={11}
        />
    );
};

MapContainer.propTypes = {
    points: PropTypes.any,
};

export default MapContainer;
