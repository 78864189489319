import PropTypes from "prop-types";
import React from 'react';
import {Col, Row} from 'react-grid-system';
import {withRouter} from "react-router";
import Header from "../Header/Header";
import './MainLayout.scss';

const MainLayout = props => {
    return (
        <Row className="main-layout">
            <Col>
                <Header {...props}>
                    <Row className={"main-layout-content " + props.className}>
                        <Col>
                            {props.children}
                        </Col>
                    </Row>
                </Header>
            </Col>
        </Row>
    );
};

MainLayout.propTypes = {
    className: PropTypes.string
};

export default withRouter(MainLayout);
