import moment from "moment";
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from "react-redux";
import { saveData, saveFormulariosData, saveFormulariosData2, saveRepeProveedores, saveRepetidosData } from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Check from "../../../common/components/Fields/Check";
import Date from "../../../common/components/Fields/Date";
import Files from "../../../common/components/Fields/Files";
import MapContainer from "../../../common/components/Fields/MapContainer";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Time from "../../../common/components/Fields/Time";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import { resource } from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ClientsService from "../../../services/Clients";
import ContratosService from "../../../services/Contratos";
import ConsecutivoService from "../../../services/Consecutivo";
import ServiciosService from "../../../services/Servicios";
import ConductoresService from "../../../services/Conductores";
import VehiclesService from "../../../services/Vehicles";
import ProveedoresService from "../../../services/Proveedores";
import MantenimientoService from "../../../services/Mantenimiento";
import ContratoModel from "../../Contratos/Contrato.json";
import ModelFacturar from "../Facturar.json";
import ModelCerrar from "../Cerrar.json";
import ModelAsignado from "../Asignado.json";
import Model from "../Servicio.json";
import ViaticosService from "../../../services/Viaticos";
import Ubicaciones from "../../../services/Ubicaciones";
import { Modal } from "@material-ui/core";

class Formulario extends React.Component {

    WayPay = resource('WayPay');
    Disponibilidad = resource('Disponibilidad');
    VehicleType = resource('VehicleType');
    ServiceType = []
    ContractClass = resource('ContractClass');
    Cities = resource('Cities');
    ContractType = resource('ContractType');
    _mounted = false;
    _init = {
        counter: [0],
        counterProveedor: [0],
        consecutivo: '----',
        clients: [],
        contratos: [],
        frecuentes: [],
        proveedores: [],
        viaticos: [],
        conductoresOriginal: [],
        conductores: [],
        vehiclesOriginal: [],
        vehicles: [],
        date: moment().format('YYYY-MM-DD'),
        contract_number: '----',
        ramdom: '',
        edit: false,
        action: this.props.match.params.action,
        openModal: false
    };

    constructor(props) {
        super(props);
        this.state = this._init;
        this.props.save({ formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({ formularios: {}, repetidos: {} });
            this.getAll();
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll() {
        await this.getItems();
        let params = this.props.match.params;
        if (params.id) await this.getData(params.id);
    }

    async getItems() {
        let [clients, contratos, servicios, conductores, vehicles, proveedores, viaticos, serviciosType, ubicaciones] = await Promise.all([
            ClientsService.get(-1),
            ContratosService.get(-1),
            ServiciosService.getFrecuentes(),
            ConductoresService.get(-1),
            VehiclesService.get(-1),
            ProveedoresService.get(-1),
            ViaticosService.get(-1),
            ServiciosService.getType(),
            Ubicaciones.list(-1)
        ]);
        let newActual = [];
        for (let i = 0; i < contratos.data.length; i++) {
            let end = moment(contratos.data[i].end_date);
            if (moment().isBefore(end)) newActual.push(contratos.data[i]);
        }
        
        let newActualConductores = [];
        for (let i = 0; i < conductores.data.length; i++) {
            if (!conductores.data[i].cesantias_date){
                newActualConductores.push(conductores.data[i]);
                // console.log("Cesantias",conductores.data[i])
            }
            let end = moment(conductores.data[i].cesantias_date);
            if (moment().isBefore(end)){
                newActualConductores.push(conductores.data[i]);
                // console.log("Moment", conductores.data[i])
            }
        }

        // console.log(conductores.data.length);
        // console.log(newActualConductores.length)

        let ubics = [];

        ubics.push({
            code: "9999",
            name: "Nueva Ubicación"
        })

        ubics.push(...ubicaciones.data);

        this.setState(() => ({
            ...this._init,
            clients: clients.data,
            contratos: newActual,
            frecuentes: servicios.data,
            conductores: newActualConductores,
            conductoresOriginal: conductores.data,
            vehicles: vehicles.data,
            vehiclesOriginal: vehicles.data,
            proveedores: proveedores.data,
            viaticos: viaticos.data,
            serviciosType: serviciosType.data,
            ubicaciones: ubics
        }));
    }

    async getData(id) {
        Helpers.loader();
        let client = await ServiciosService.getById(id);
        if (client.data.id) {
            delete client.data['frecuente'];

            let counter = [];
            let contactos = client.data.conductores;
            let repetidos = Helpers.ungroupByKey(contactos);
            for (let i = 0; i < contactos.length; i++) counter.push(i);

            let counterProveedor = [];
            let proveedores = client.data.costos;
            for (let i = 0; i < proveedores.length; i++) {
                counterProveedor.push(i);
                if (proveedores[i].proveedor.id) proveedores[i].proveedor = proveedores[i].proveedor.id;
            }
            let repetidosProveedores = Helpers.ungroupByKey(proveedores);

            this.props.save({
                formularios: client.data,
                repetidos,
                repetidosProveedores
            });
            this.setState(() => ({ edit: true, counter, counterProveedor, consecutivo: client.data.consecutivo }));
            this.filterVehiculo();
        } else {
            this.props.history.push('/cotizacion/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();
        let model = Model;

        let form = {
            ...store.formularios,
        };

        console.log('MI LOGGGGGGGGG', form, model);

        if (form.tipo_contract === 1) delete model['contract_id'];
        delete model['state'];

        let valid = Helpers.validForm(form, model);
        if (valid.keys) {
            this.props.save({ errors: valid.keys });
            Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
        } else {
            Helpers.loader();

            let consecutivo = await Promise.resolve(ConsecutivoService.get(3));
            form['consecutivo'] = consecutivo.number.toString().padStart(4, '0');

            if (form.tipo_contract === 1) {
                let contract = {
                    init_date: form.init_date,
                    end_date: form.end_date,
                    objecto: form.objecto,
                    cliente: form.client,
                    contact: form.contact,
                };
                let contratoModel = ContratoModel;
                delete contratoModel['file'];
                delete contratoModel['cliente'];
                delete contratoModel['contact'];
                let valid = Helpers.validForm(contract, contratoModel);
                if (valid.keys) {
                    this.props.save({ errors: valid.keys });
                    Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
                    Helpers.closeLoader();
                    return;
                }
                let consecutivo = await Promise.resolve(ConsecutivoService.get(4));
                contract['consecutivo'] = consecutivo.number.toString().padStart(4, '0');
                let contrato = await ContratosService.save(contract);
                form['contract_id'] = contrato['status'];
            }

            this.props.save({ errors: {} });

            let method = 'save';
            if (this.state.edit) method = 'update';
            if (form['frecuente'] !== undefined) form['frecuente'] = form['frecuente'] ? 1 : 0;
            if (form.tipo_contract === 1) form['tipo_contract'] = 2;           
            let response = await ServiciosService[method](form);
            Helpers.closeLoader();
            if (response.message) {
                Helpers.alert({ title: 'Error!', text: response.message });
            } else if (response.status) {
                if (this.state.edit) {
                    Helpers.alert({ title: '', icon: 'success', text: 'Se ha guardado correctamente!' });
                } else {
                    Helpers.alert({ title: '', icon: 'success', text: 'Se ha creado correctamente!' });
                }
                this.props.history.push('/servicio/list');
            } else {
                Helpers.alert({ title: 'Error!', text: 'Error intente más tarde' });
            }
        }
    };

    asingnar = async () => {
        let store = Store.getState();
        let repetidos = Helpers.groupByKey(store.repetidos);
        let model = ModelAsignado;

        let form = {
            ...store.formularios,
            conductores: repetidos
        };

        console.log('MI LOGGGGGGGGG', form, model);
        //return ;

        let valid = Helpers.validForm(form, model);
        if (valid.keys) {
            this.props.save({ errors: valid.keys });
            Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
        } else {
            await this.updateModel(1);
            const state = 1;
            this.props.save({ errors: {} });
            Helpers.loader();
            form['state'] = state;
            let response = await ServiciosService.update(form);
            Helpers.closeLoader();
            if (response.message) {
                Helpers.alert({ title: 'Error!', text: response.message });
            } else if (response.status) {
                Helpers.alert({ title: '', icon: 'success', text: 'Se ha asignado correctamente!' });
                this.props.saveForm({ state });
            } else {
                Helpers.alert({ title: 'Error!', text: 'Error intente más tarde' });
            }
        }
    };

    saveNewUbication = async () => {
        
        let data = Store.getState().formularios2;
    
        let ubication = await Ubicaciones.create(data);

        if (ubication.code == 200) {
            let ubicsdata = await Ubicaciones.list(-1);
            let ubics = [];

            ubics.push({
                code: "9999",
                name: "Nueva Ubicación"
            })
    
            ubics.push(...ubicsdata.data);

            this.setState({ ubicaciones : ubics, openModal: false})
            this.props.save({ formularios2: {} });


        }
    }

    liquidar = async () => {
        Helpers.loader();
        const { id } = Store.getState().formularios;
        const state = 2;
        let form = { state, id };
        await this.updateModel(1);
        let response = await ServiciosService.update(form);
        Helpers.closeLoader();
        if (response.message) {
            Helpers.alert({ title: 'Error!', text: response.message });
        } else if (response.status) {
            Helpers.alert({ title: '', icon: 'success', text: 'Se ha liquidado correctamente!' });
            this.props.saveForm({ state });
        } else {
            Helpers.alert({ title: 'Error!', text: 'Error intente más tarde' });
        }
    };

    facturar = async () => {
        let store = Store.getState();
        let repetidos = Helpers.groupByKey(store.repetidosProveedores);
        let model = ModelFacturar;

        const {
            base,
            bonus_driver,
            percer_driver,
            numero_factura,
            factura,
            id
        } = store.formularios;

        let form = {
            base,
            bonus_driver,
            percer_driver,
            numero_factura,
            factura,
            id,
            costos: repetidos
        };

        let valid = Helpers.validForm(form, model);
        if (valid.keys) {
            this.props.save({ errors: valid.keys });
            Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
        } else {
            await this.updateModel(1);
            const state = 3;
            this.props.save({ errors: {} });
            Helpers.loader();
            form['state'] = state;
            let response = await ServiciosService.update(form);
            Helpers.closeLoader();
            if (response.message) {
                Helpers.alert({ title: 'Error!', text: response.message });
            } else if (response.status) {
                Helpers.alert({ title: '', icon: 'success', text: 'Se ha facturado correctamente!' });
                this.props.saveForm({ state });
            } else {
                Helpers.alert({ title: 'Error!', text: 'Error intente más tarde' });
            }
        }
    };

    pendiente = async () => {
        Helpers.loader();
        const { id } = Store.getState().formularios;
        const state = 4;
        await this.updateModel(1);
        let form = { state, id };
        let response = await ServiciosService.update(form);
        Helpers.closeLoader();
        if (response.message) {
            Helpers.alert({ title: 'Error!', text: response.message });
        } else if (response.status) {
            Helpers.alert({ title: '', icon: 'success', text: 'Se ha actualizado correctamente!' });
            this.props.saveForm({ state });
        } else {
            Helpers.alert({ title: 'Error!', text: 'Error intente más tarde' });
        }
    };

    cerrar = async () => {
        Helpers.loader();
        const { id } = Store.getState().formularios;

        let model = ModelCerrar;
        const {
            base,
            bonus_driver,
            percer_driver,
            numero_factura,
            factura,
        } = Store.getState().formularios;
        let form = {
            base,
            bonus_driver,
            percer_driver,
            numero_factura,
            factura,
        };

        let valid = Helpers.validForm(form, model);
        if (valid.keys) {
            this.props.save({ errors: valid.keys });
            Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
        } else {

            const state = 5;
            form = { state, id };
            let response = await ServiciosService.update(form);
            Helpers.closeLoader();
            if (response.message) {
                Helpers.alert({ title: 'Error!', text: response.message });
            } else if (response.status) {
                Helpers.alert({ title: '', icon: 'success', text: 'Se ha cerrado correctamente!' });
                this.props.saveForm({ state });
            } else {
                Helpers.alert({ title: 'Error!', text: 'Error intente más tarde' });
            }

        }
    };

    return = () => this.props.history.push('/servicio/list');

    clearContact = () => this.props.saveForm({ contact: undefined });

    getContacts() {
        let clients = this.state.clients;
        let client = this.props.client;
        for (let i = 0; i < clients.length; i++) {
            if (client === clients[i].id) return clients[i].contactos;
        }
        return [];
    }

    calc = () => () => {
        let store = Store.getState();
        let repetidos = store.formularios;
        let cantidad_vehiculos = repetidos.cantidad_vehiculos;
        cantidad_vehiculos = cantidad_vehiculos ? parseInt(cantidad_vehiculos) : 0;
        let valor = repetidos['valor'];
        let cantidad = repetidos['cantidad'];
        if (!valor) valor = 0;
        if (!cantidad) cantidad = 0;
        let value = cantidad * Helpers.formatToNumber(valor);
        let value_total = value * cantidad_vehiculos;
        this.props.saveForm({
            [`valor_service`]: Helpers.formatToMask(value),
            [`total`]: Helpers.formatToMask(value_total),
        });
    };

    calcProveedor = () => {
        let store = Store.getState();
        let repetidos = Helpers.groupByKey(store.repetidosProveedores);
        for (let i = 0; i < repetidos.length; i++) {
            let costxunit = repetidos[i]['costxunit'];
            let cantidad = repetidos[i]['cantidad'];
            costxunit = costxunit ? Helpers.formatToNumber(costxunit) : 0;
            cantidad = cantidad ? cantidad : 0;

            let value_total = costxunit * cantidad;
            this.props.saveRepeProveedores({
                [`valor_${i}`]: Helpers.formatToMask(value_total),
            });
        }
    };

    remove = (key, name) => () => {
        let newcounter = [];
        let counter = this.state[name];
        for (let i = 0; i < counter.length; i++) if (counter[i] !== key) newcounter.push(counter[i]);
        this.setState(() => ({ [name]: newcounter }));

        let repetidos = Helpers.removeGroupByKey(Helpers.groupByKey(Store.getState().repetidos), key);
        this.props.save({ 'repetidos': repetidos });
    };

    add = name => () => {
        let counter = this.state[name];
        let index = counter[counter.length - 1] + 1;
        counter.push(isNaN(index) ? 0 : index);
        if (counter.length > 20) return;
        this.setState(() => ({ [name]: counter }));
    };

    getDriver(index) {
        let driver = {};
        let repetidos = Helpers.groupByKey(Store.getState().repetidos);
        if (repetidos) {
            repetidos = repetidos[index];
            if (repetidos) if (repetidos.conductor_id) driver = Helpers.getStatic(repetidos.conductor_id, this.state.conductores, 'id');
        }
        return driver;
    }

    update = () => this.setState(() => ({ ramdom: Math.random() }));

    getCity(code) {
        return Helpers.getStatic(code, this.Cities);
    }

    filterVehiculo = async () => {
        if (this.state.edit && this.props.state === 0) {
            let formulario = Store.getState().formularios;
            let init = formulario.fecha_ida.split('/').join('-');
            let end = formulario.fecha_regreso.split('/').join('-');
            let hour = formulario.hour;
            let serviceDate = moment(`${init} ${hour}`);
            if (hour == null) return;

            Helpers.loader();
            let manetenimientos = await MantenimientoService.getByDate('2000-01-01', end);
            this.props.saveForm({ vehiculo: null });
            this.props.save({ repetidos: {} });
            let vehicles = [];
            let drivers = [];
            for (let i = 0; i < manetenimientos.data.length; i++) {
                let mantenimiento = manetenimientos.data[i];
                if (mantenimiento.end_date == null) {
                    drivers.push(mantenimiento['conductor']);
                    vehicles.push(mantenimiento['vehiculo']);
                } else {
                    let date = moment(`${mantenimiento.init_date} ${mantenimiento.init_hour}`);
                    if (date.diff(serviceDate) < 0) {
                        drivers.push(mantenimiento['conductor']);
                        vehicles.push(mantenimiento['vehiculo']);
                    }
                }
            }

            let newVehicles = [];
            let newDrivers = [];
            let conductoresOriginal = this.state.conductoresOriginal;
            let vehiclesOriginal = this.state.vehiclesOriginal;
            for (let i = 0; i < conductoresOriginal.length; i++) if (drivers.indexOf(conductoresOriginal[i]['id']) === -1) newDrivers.push(conductoresOriginal[i]);
            for (let i = 0; i < vehiclesOriginal.length; i++) if (vehicles.indexOf(vehiclesOriginal[i]['id']) === -1) newVehicles.push(vehiclesOriginal[i]);

            this.setState(() => ({
                conductores: newDrivers,
                vehicles: newVehicles,
            }));

            Helpers.closeLoader();
        }
    };

    caclBonus = () => {
        let { base, percer_driver } = Store.getState().formularios;
        base = base ? Helpers.formatToNumber(base) : 0;
        percer_driver = percer_driver ? percer_driver : 0;
        let total = base * percer_driver / 100;
        this.props.saveForm({ bonus_driver: Helpers.formatToMask(total) });
    };

    recurrente = e => {
        if (e.v) for (let i in Model) {
            this.props.saveForm({ 'tipo_contract': 2 });
            for (let j in Model) {
                if (i === j) this.props.saveForm({ [i]: e.v[i] });
            }
        }
    };

    contract = e => {
        // if( this.state.edit === true ) return;
        this.props.saveForm({
            'client': e.v.cliente?.id,
            'contact': e.v.contacto?.id,
        });
    };

    updateModel = async status => {
        Helpers.loader();
        let repetidos = Helpers.groupByKey(Store.getState().repetidos);
        let repetidosProveedores = Helpers.groupByKey(Store.getState().repetidosProveedores);
        const forms = {
            ...Store.getState().formularios,
            conductores: repetidos,
            costos: repetidosProveedores
        };

        let form = { id: forms.id };
        for (let i in Model) for (let j in forms) if (j === i) form[j] = forms[j];
        for (let i in ModelAsignado) for (let j in forms) if (j === i) form[j] = forms[j];
        for (let i in ModelFacturar) for (let j in forms) if (j === i) form[j] = forms[j];
        console.log(form);
        await ServiciosService.update(form);
        if (isNaN(status)) Helpers.alert({ title: '', icon: 'success', text: 'Se ha actualizado correctamente!' });
    };

    setOrigen = o => {
        console.log(o);

        if (o.v.code == 9999) {
            this.setState({ openModal: true });
        } else {
            let data = Store.getState().formularios;
            data.origen = o.v.code_city;
            this.props.save({ formularios: data })
        }
    }

    setDestino = d => {
        let data = Store.getState().formularios;
        data.destino = d.v.code_city;
        this.props.save({ formularios: data })
    }

    render() {
        const { state, props } = this;
        let vehicles = {};
        if (props.vehiculo) vehicles = Helpers.getStatic(props.vehiculo, state.vehicles, 'id');

        const disabledAsignado = props.state >= 1;
        const disabledLiquidacion = props.state == 2;
        const disabledFacturacion = props.state > 3;
        const viewCosts = props.user.role !== 2;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{state.edit ? state.action === 'view' ? 'Ver' : 'Editar' : 'Nuevo'} Servicio</h2>
                    </Col>
                    ¨{props.state}
                    <Col>
                        <div className="top">
                            <p>Consecutivo <span>{state.consecutivo}</span></p>
                            <p><span>{state.date}</span></p>
                        </div>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    {!state.edit && <Select disabled={disabledAsignado} label="Servicio recurrente" opts={state.frecuentes} optionLabel={Helpers.contratosLabels} name="recurrente" hash="id" change={this.recurrente} />}
                                </div>
                                <div>
                                    <Select disabled={disabledAsignado} label="Tipo de contrato" opts={this.ContractClass} name="tipo_contract" />
                                    {props.tipo_contract === 1 && <Text disabled={disabledAsignado} label="Número de contrato" value={state.contract_number} />}
                                    {props.tipo_contract === 2 && <Select disabled={disabledAsignado} label="Selecione el contrato" opts={state.contratos} change={this.contract} optionLabel={Helpers.contratosLabels} name="contract_id" hash="id" />}
                                </div>
                                {props.tipo_contract === 1 && <React.Fragment>
                                    <div>
                                        <Date today disabled={disabledAsignado} label="Fecha de inicio" name="init_date" />
                                        <Date today disabled={disabledAsignado} label="Fecha de vencimiento" name="end_date" />
                                    </div>
                                    <div>
                                        <Select disabled={disabledAsignado} label="Objeto del contrato" opts={this.ContractType} name="objecto" />
                                    </div>
                                </React.Fragment>}
                                <h3 />
                                <div>
                                    <Select disabled={disabledAsignado} label="Seleccione el cliente" opts={state.clients} change={this.clearContact} optionLabel={Helpers.clientsLabels} name="client" hash="id" />
                                    <Select disabled={disabledAsignado} label="Seleccione el contacto" optionLabel={Helpers.clientsLabels} opts={this.getContacts()} name="contact" hash="id" />
                                </div>
                                <div>
                                    <Date disabled={disabledAsignado} label="Fecha de ida" name="fecha_ida" change={this.filterVehiculo} />
                                    <Date disabled={disabledAsignado} label="Fecha de regreso" name="fecha_regreso" change={this.filterVehiculo} />
                                </div>
                                <div>
                                    <Text disabled={disabledAsignado} label="Cantidad de pasajeros" name="cantidad_pasajeros" number />
                                    <div className="w-49 d-i">
                                        <Select disabled={true} label="Origen" opts={this.Cities} name='origen' />
                                        <Select disabled={true} label="Destino" opts={this.Cities} name='destino' />
                                    </div>
                                </div>
                                <div>
                                    <Select disabled={disabledAsignado} label="Seleccione tipo de servicio" opts={state.serviciosType} name="service_type" />
                                    <div className="w-49 d-i">
                                        <Select disabled={disabledAsignado} label="Ubicación Origen" opts={state.ubicaciones} name='ubic_origen' change={this.setOrigen} />
                                        <Select disabled={disabledAsignado} label="Ubicación Destino" opts={state.ubicaciones} name='ubic_destino' change={this.setDestino} />
                                    </div>
                                </div>
                                <div>
                                    <Select disabled={disabledAsignado} label="Seleccione tipo de vehículo" opts={this.VehicleType} name="vehicle_type" />
                                    <Text disabled={disabledAsignado} label="Cantidad de vehículos" number name={`cantidad_vehiculos`} change={this.calc()} />
                                </div>

                                <h4>Disponibilidad</h4>
                                <div>
                                    <Select label="Tipo" opts={this.Disponibilidad} name={`tipo`} />
                                    <div className="w-49 d-i">
                                        <Text label="Cantidad" name={`cantidad`} change={this.calc()} />
                                        <Text label="Valor por unidad" name={`valor`} money change={this.calc()} />
                                    </div>
                                </div>
                                <div>
                                    <Text label="Valor del servicio" name={`valor_service`} money disabled />
                                    <Text label="Valor total del servicio" name={`total`} money disabled />
                                </div>
                                <div>
                                    <Text label="Observación" multi className="w-100 field-observa" name={`observa`} />
                                    {!state.edit && <Check name="frecuente" label="Servicio frecuente" />}
                                </div>
                                <h3 />
                                {(state.edit && props.state >= 0) &&
                                    <React.Fragment>
                                        <div>
                                            <Time disabled={disabledAsignado} today label="Hora programada del servicio" name="hour" change={this.filterVehiculo} />
                                        </div>
                                        <h3 />
                                        <h4>Vehiculo</h4>
                                        <div>
                                            <Select disabled={disabledLiquidacion} label="Seleccione el vehículo" opts={state.vehicles} name="vehiculo" hash="id" optionLabel={Helpers.vehicleLabels} />
                                            <Text label="Placa" disabled value={vehicles.plate} />
                                        </div>
                                        <div>
                                            <Text label="Modelo" disabled value={vehicles.model} />
                                            <Text label="Marca" disabled value={vehicles.marca} />
                                        </div>
                                        <div>
                                            <Text label="Clase" disabled value={Helpers.getStatic(vehicles.class, this.VehicleType).name} />
                                            <Text label="Numero interno" disabled value={vehicles.no_interno} />
                                        </div>
                                        <div>
                                            <Text label="Número de Tarjeta de Operación" disabled value={vehicles.no_tarjeta} />
                                            {vehicles.proveedor && <Text label="Proveedor" disabled value={vehicles.proveedor.names} />}
                                        </div>
                                        <h3 />
                                    </React.Fragment>
                                }
                            </Col>
                        </Row>
                    </Provider>

                    {(state.edit && props.state >= 0) && <React.Fragment>
                        <Provider save={saveRepetidosData} store="repetidos">
                            <Row className="row">
                                <Col>
                                    <h4>Conductores</h4>
                                    {state.counter.map((item, key) =>
                                        <React.Fragment key={key}>
                                            {(state.edit && props.state >= 0) &&
                                                <React.Fragment>
                                                    <div>
                                                        <Select disabled={disabledLiquidacion} label="Seleccione el coductor" change={this.update} opts={state.conductores} name={`conductor_id_${item}`} hash="id" optionLabel={Helpers.driveLabels} />
                                                        <Text label="Nombre del conductor" disabled value={this.getDriver(item).name} />
                                                    </div>
                                                    <div>
                                                        <Text label="Número Cédula del conductor" disabled value={this.getDriver(item).doc} />
                                                        <Text label="Número Licencia del conductor" disabled value={this.getDriver(item).licence_number} />
                                                    </div>
                                                    <div>
                                                        <Text label="Vencimiento de la licencia" disabled value={this.getDriver(item).date_licesed} />
                                                        {this.getDriver(item).proveedor && <Text label="Proveedor" disabled value={this.getDriver(item).names} />}
                                                    </div>
                                                    <div className="w-100 d-i">
                                                        <Btn click={this.remove(item, 'counter')} text="Remover" type="secondary" class="remove" />
                                                    </div>
                                                    <h3 />
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Provider>
                        {(state.counter.length < 3 && !disabledAsignado) && <div className='text-center'>
                            <Btn click={this.add('counter')} text="Agregar conductor" type="primary" class="add-contact more" />
                        </div>}
                    </React.Fragment>}

                    {props.state >= 1 && <MapContainer points={[
                        this.getCity(props.origen).location,
                        this.getCity(props.destino).location,
                    ]} />}

                    {(props.state >= 2 && viewCosts) && <React.Fragment>

                        <Provider save={saveRepeProveedores} store="repetidosProveedores" disabled={disabledLiquidacion}>
                            <Row className="row">
                                <Col>
                                    <h4>Costos</h4>
                                    {state.counterProveedor.map((item, key) =>
                                        <React.Fragment key={key}>
                                            {state.edit &&
                                                <React.Fragment>

                                                    <Text label="Codigo" name={`id_${item}`} className="hide" />
                                                    <div>

                                                        <Select label="Seleccione el proveedor" opts={state.proveedores} name={`proveedor_${item}`} hash="id" optionLabel={Helpers.clientsLabels} />
                                                        <Text label="Detalle" name={`detalle_${item}`} />
                                                    </div>
                                                    <div>
                                                        <div className="w-49 d-i max">
                                                            <Text label="Costo x Unidad" name={`costxunit_${item}`} money change={this.calcProveedor} />
                                                            <Text label="Cantidad" name={`cantidad_${item}`} change={this.calcProveedor} />
                                                        </div>

                                                        <Text label="Valor total" name={`valor_${item}`} money disabled />
                                                        <Select label="Seleccione viático" opts={state.viaticos} name={`viatico_${item}`} hash="id" optionLabel={Helpers.viaticosLabels} />

                                                    </div>

                                                    <div className="w-100 d-i">
                                                        <Btn click={this.remove(item, 'counterProveedor')} text="Remover" type="secondary" class="remove" />
                                                    </div>
                                                    <h3 />
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Provider>
                        {!disabledLiquidacion && <div className='text-center'>
                            <Btn click={this.add('counterProveedor')} text="Agregar costo" type="primary" class="add-contact more" disabled={disabledFacturacion} />
                        </div>}
                    </React.Fragment>}
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <h4>Facturar servicio</h4>
                                <div>
                                    <Text label="Base" name="base" money change={this.caclBonus} />
                                    <Text label="Porcentaje" name="percer_driver" number change={this.caclBonus} />
                                </div>
                                <div>
                                    <Text label="Bonificaciones para el conductor" disabled name="bonus_driver" money />
                                    <Text label="Relacionar el número de factura" name="numero_factura" number />
                                </div>
                                <div>
                                    <Files name="factura" label="Selecione la factura" />
                                </div>
                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        {viewCosts && <React.Fragment>
                            {(state.edit && props.state >= 0) && <Btn click={this.updateModel} text="Actualizar" type="primary" />}
                            {!state.edit && <Btn click={this.save} text="Guardar" type="primary" />}
                            {(state.edit && props.state >= 3) && <div className="wrap-btns">
                                {(state.edit && props.state === 3) && <Btn click={this.pendiente} text="Pendiente pago" type="primary" />}
                                {(state.edit && (props.state === 4 || props.state === 3)) && <Btn click={this.cerrar} text="Cerrar" type="primary" />}
                            </div>}
                            {(state.edit && props.state == 2) && <Btn click={this.facturar} text="Facturar" type="primary" />}
                            {(state.edit && (props.state == 1 || props.state == 6)) && <Btn click={this.liquidar} text="Liquidar" type="primary" />}
                            {(state.edit && props.state == 0) && <Btn click={this.asingnar} text="Asignar" type="primary" />}
                        </React.Fragment>}
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
                <Modal open={state.openModal}>
                    <div className="modal formdata">
                        <div className="header">
                            <h4>Nueva Ubicación</h4>
                        </div>
                        <div className="body wrap">
                            <Provider save={saveFormulariosData2} store="formularios2">
                                <div>
                                    <Text label="Nombre de ubicacion" name="name" />
                                    <Select label="Ciudad" opts={this.Cities} name='code_city' />
                                </div>
                                <div>
                                    <Text label="Latitud" name="lat" />
                                    <Text label="Longitud" name="lng" />
                                </div>
                            </Provider>
                            <div className="btns">
                                <Btn text="Guardar" type="primary" click={this.saveNewUbication} />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        client: store.formularios.client,
        tipo_contract: store.formularios.tipo_contract,
        vehiculo: store.formularios.vehiculo,
        state: store.formularios.state,
        origen: store.formularios.origen,
        destino: store.formularios.destino,
    }),
    (dispatch) => ({
        save: data => dispatch(saveData(data)),
        saveForm: data => dispatch(saveFormulariosData(data)),
        saveRepe: data => dispatch(saveRepetidosData(data)),
        saveRepeProveedores: data => dispatch(saveRepeProveedores(data)),
    }),
)(Formulario);
