import moment from "moment";
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from "react-redux";
import { saveData, saveFormulariosData, saveRepetidosData } from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Date from "../../../common/components/Fields/Date";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import { resource } from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ClientsService from "../../../services/Clients";
import CotizacionService from "../../../services/Cotizacion";
import ConsecutivoService from "../../../services/Consecutivo";
import Model from "../Cotizacion.json";
import Ubicaciones from "../../../services/Ubicaciones";

class Formulario extends React.Component {

    WayPay = resource('WayPay');
    Disponibilidad = resource('Disponibilidad');
    VehicleType = resource('VehicleType');
    Cities = resource('Cities');
    _mounted = false;
    _init = {
        counter: [0],
        consecutivo: '----',
        clients: [],
        date: moment().format('YYYY-MM-DD'),
        action: this.props.match.params.action,
        id: null,
        edit: false
    };

    constructor(props) {
        super(props);
        this.state = this._init;
        this.props.save({ formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({ formularios: {}, repetidos: {} });
            this.getAll();
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll() {
        await this.getItems();
        let params = this.props.match.params;
        if (params.id) await this.getData(params.id);
    }

    async getItems() {
        let [clients, ubicaciones] = await Promise.all([ClientsService.get(-1), Ubicaciones.list(-1)]);
        this.setState(() => ({ ...this._init, clients: clients.data, ubicaciones: ubicaciones.data }));
    }

    async getData(id) {
        Helpers.loader();
        let client = await CotizacionService.getById(id);
        if (client.data.id) {
            let counter = [];
            let contactos = client.data.disponibilidad;
            let repetidos = Helpers.ungroupByKey(contactos);
            for (let i = 0; i < contactos.length; i++) counter.push(i);
            this.props.save({ formularios: client.data, repetidos });
            let date = moment(client.data.created_at).format('YYYY-MM-DD');
            this.setState(() => ({ edit: true, counter, consecutivo: client.data.consecutivo, date, id }));
        } else {
            this.props.history.push('/cotizacion/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {

        let store = Store.getState();
        let repetidos = Helpers.groupByKey(store.repetidos);

        let form = {
            ...store.formularios,
            disponibilidad: repetidos,
            consecutivo: this.state.consecutivo
        };

        let valid = Helpers.validForm(form, Model);
        if (valid.keys) {
            this.props.save({ errors: valid.keys });
            Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
        } else {
            this.props.save({ errors: {} });
            Helpers.loader();

            let consecutivo = await Promise.resolve(ConsecutivoService.get(1));
            form['consecutivo'] = this.state.consecutivo !== '----' ? this.state.consecutivo : consecutivo.number.toString().padStart(4, '0');

            let method = 'save';
            if (this.state.edit) method = 'update';
            let response = await CotizacionService[method](form);
            Helpers.closeLoader();
            if (response.message) {
                Helpers.alert({ title: 'Error!', text: response.message });
            } else if (response.status) {
                if (this.state.edit) {
                    Helpers.alert({ title: '', icon: 'success', text: 'Se ha guardado correctamente!' });
                } else {
                    Helpers.alert({ title: '', icon: 'success', text: 'Se ha creado correctamente!' });
                }
                this.props.history.push('/cotizacion/list');
            } else {
                Helpers.alert({ title: 'Error!', text: 'Error intente más tarde' });
            }
        }
    };

    remove = key => () => {
        let newcounter = [];
        let counter = this.state.counter;
        for (let i = 0; i < counter.length; i++) if (counter[i] !== key) newcounter.push(counter[i]);
        this.setState(() => ({ counter: newcounter }));

        let repetidos = Helpers.removeGroupByKey(Helpers.groupByKey(Store.getState().repetidos), key);
        this.props.save({ 'repetidos': repetidos });
        this.calc(-1)();
    };

    add = () => {
        let counter = this.state.counter;
        let index = counter[counter.length - 1] + 1;
        counter.push(isNaN(index) ? 0 : index);
        this.setState(() => ({ counter }));
    };

    return = () => this.props.history.push('/cotizacion/list');

    clearContact = () => {
        this.props.saveForm({ contact: undefined });
    };

    getContacts() {
        let clients = this.state.clients;
        let client = this.props.client;
        for (let i = 0; i < clients.length; i++) {
            if (client === clients[i].id) return clients[i].contactos;
        }
        return [];
    }

    calc = key => () => {
        let store = Store.getState();
        let repetidos = Helpers.groupByKey(store.repetidos);
        let total = 0;
        for (let i = 0; i < repetidos.length; i++) {
            let cantidad_vehiculos = repetidos[i]['cantidad_vehiculos'];
            let valor = repetidos[i]['valor'];
            let cantidad = repetidos[i]['cantidad'];
            if (!valor) valor = 0;
            if (!cantidad) cantidad = 0;
            if (!cantidad_vehiculos) cantidad_vehiculos = 0;
            let value = cantidad * Helpers.formatToNumber(valor);
            let value_total = value * cantidad_vehiculos;
            this.props.saveRepe({
                [`valor_service_${i}`]: Helpers.formatToMask(value),
                [`total_${i}`]: Helpers.formatToMask(value_total),
            });
            total += value_total;
        }
        this.props.saveForm({ 'total': Helpers.formatToMask(total) });
    };

    generate = id => async () => {
        Helpers.loader();
        let doc = await CotizacionService.doc(id);
        let a = document.createElement('a');
        a.download = doc.name;
        a.href = doc.data;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        Helpers.closeLoader();
    };

    setOrigen = (o) => {
        let form = Store.getState().repetidos;
        let keys = Object.keys(o);
        let index = keys[0].replace('ubic_origen_', '')
        form['origen_' + index] = o.v.code_city;

        this.props.save({ repetidos: form });
    }

    setDestino = (d) => {
        let form = Store.getState().repetidos;
        let keys = Object.keys(d);
        let index = keys[0].replace('ubic_destino_', '')
        form['destino_' + index] = d.v.code_city;

        this.props.save({ repetidos: form });
    }

    render() {
        const { state } = this;
        const disabled = state.action === 'view';
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{state.edit ? state.action === 'view' ? 'Ver' : 'Editar' : 'Nuevo'} Cotización</h2>
                    </Col>
                    <Col>
                        <div className="top">
                            <p>Consecutivo <span>{state.consecutivo}</span></p>
                            <p><span>{state.date}</span></p>
                        </div>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios" disabled={disabled}>
                        <Row className="row">
                            <Col>
                                <div>
                                    <Select label="Seleccione el cliente" opts={state.clients} change={this.clearContact} optionLabel={Helpers.clientsLabels} name="client" hash="id" />
                                    <Select label="Seleccione el contacto" optionLabel={Helpers.clientsLabels} opts={this.getContacts()} name="contact" hash="id" />
                                </div>
                                <h3 />
                            </Col>
                        </Row>
                    </Provider>
                    <Provider save={saveRepetidosData} store="repetidos" disabled={disabled}>
                        <Row className="row">
                            <Col>
                                {state.counter.map((item, key) =>
                                    <React.Fragment key={key}>
                                        <div>
                                            <Date today label="Fecha de ida" name={`fecha_ida_${item}`} />
                                            <Date today label="Fecha de regreso" name={`fecha_regreso_${item}`} />
                                        </div>
                                        <div>
                                            <Text label="Cantidad de pasajeros" name={`cantidad_pasajeros_${item}`} number />
                                            <div className="w-49 d-i">
                                                <Select label="Origen" opts={this.Cities} name={`origen_${item}`} />
                                                <Select label="Destino" opts={this.Cities} name={`destino_${item}`} />
                                            </div>
                                        </div>
                                        <div>
                                            <Select label="Ubicación Origen" opts={state.ubicaciones} name={`ubic_origen_${item}`} change={this.setOrigen} />
                                            <Select label="Ubicación Destino" opts={state.ubicaciones} name={`ubic_destino_${item}`} change={this.setDestino} />
                                        </div>
                                        <div>
                                            <Select label="Seleccione tipo de vehículo" opts={this.VehicleType} name={`vehicle_type_${item}`} />
                                            <Text label="Cantidad de vehículos" number name={`cantidad_vehiculos_${item}`} change={this.calc(-1)} />
                                        </div>
                                        <h4>Disponibilidad</h4>
                                        <div>
                                            <Select label="Tipo" opts={this.Disponibilidad} name={`tipo_${item}`} />
                                            <div className="w-49 d-i">
                                                <Text label="Cantidad" name={`cantidad_${item}`} change={this.calc(item)} />
                                                <Text label="Valor por unidad" name={`valor_${item}`} money change={this.calc(item)} />
                                            </div>
                                        </div>
                                        <div>
                                            <Text label="Valor del servicio" name={`valor_service_${item}`} money disabled />
                                            <Text label="Valor total del servicio" name={`total_${item}`} money disabled />
                                        </div>
                                        <div>
                                            <Text label="Observación" multi className="w-100 field-observa" name={`observa_${item}`} />
                                        </div>
                                        <div className="w-100 d-i">
                                            <Btn click={this.remove(item)} text="Remover" type="secondary" class="remove" />
                                        </div>
                                        <h3 />
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    </Provider>
                    <div className='text-center'>
                        <Btn click={this.add} text="Agregar servicio" type="primary" class="add-contact more" disabled={disabled} />
                    </div>
                    <Provider save={saveFormulariosData} store="formularios" disabled={disabled}>
                        <Row className="row">
                            <Col>
                                <h4>Recorrido</h4>
                                <div>
                                    <Select label="Forma de pago" opts={this.WayPay} name="waypay" />
                                    <Text label="Total servicios cotizados" name="total" money disabled />
                                </div>
                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        {!disabled && <Btn click={this.save} text="Guardar" type="primary" disabled={disabled} />}
                        {disabled && <Btn type="primary" text="Generar" click={this.generate(state.id)} />}
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        client: store.formularios.client
    }),
    (dispatch) => ({
        save: data => dispatch(saveData(data)),
        saveForm: data => dispatch(saveFormulariosData(data)),
        saveRepe: data => dispatch(saveRepetidosData(data)),
    }),
)(Formulario);
