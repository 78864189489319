import {Help} from "@material-ui/icons";
import Api from '../common/Api';
import {Backend, headers} from "../common/Config";
import Helpers from '../common/Helpers';

export default class Viaticos {

    static async save(form) {
        const response = await Api.post({
            url: `${Backend}/viatico/create`,
            form: Helpers.processFormData(form),
            headers: {}
        });
        return Helpers.dataReturn(response);
    };

    static async update(form) {
        const response = await Api.post({
            url: `${Backend}/viatico/update`,
            form: Helpers.processFormData(form),
            headers: {}
        });
        return Helpers.dataReturn(response);
    };

    static async get(page = 0, form) {
        const response = await Api.post({
            url: `${Backend}/viatico/list/${page}`,
            form,
            headers
        });
        return Helpers.dataReturn(response);
    };

    static async getById(id) {
        const response = await Api.get({
            url: `${Backend}/viatico/get/${id}`,
            headers
        });
        return Helpers.dataReturn(response);
    };

    static async getForDate(form){
        const response = await Api.post({
            url: `${Backend}/viatico/date`,
            form,
            headers
        })

        return Helpers.dataReturn(response);
    }

}
