import AppBar from "@material-ui/core/AppBar/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React from 'react';
import SwipeableViews from "react-swipeable-views";
import Formulario from "./components/Formulario";
import Lista from "./components/Lista";

class Mantenimiento extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            tap: 0
        };
    }

    changeTap = (e, newValue) => {
        this.setState(() => ({tap: newValue}));
    };

    render() {
        const {props, state: {tap}} = this;
        const path = props.match.path;
        return (
            <React.Fragment>
                {(path === '/mantenimiento/new' || path === '/mantenimiento/:action/:id') && <Formulario {...props}/>}
                {path === '/mantenimiento/list' && <React.Fragment>
                    <div className="formdata content">
                        <AppBar position="static" color="default">
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                value={tap}
                                variant="scrollable"
                                scrollButtons="on"
                                onChange={this.changeTap}
                            >
                                <Tab label={<h2>Mantenimientos activos</h2>}/>
                                <Tab label={<h2>Mantenimientos cerrados</h2>}/>
                            </Tabs>
                        </AppBar>
                        <SwipeableViews index={tap}>
                            <React.Fragment>{tap === 0 && <Lista {...props} type={0}/>}</React.Fragment>
                            <React.Fragment>{tap === 1 && <Lista {...props} type={1}/>}</React.Fragment>
                        </SwipeableViews>
                    </div>
                </React.Fragment>}
            </React.Fragment>
        );
    }
}

export default Mantenimiento;
