import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {saveData, saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Files from "../../../common/components/Fields/Files";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ProveedoresService from "../../../services/Proveedores";
import Model from "../Proveedores.json";

class Formulario extends React.Component {

    PersonType = resource('PersonType');
    DocumentType = resource('DocumentType');
    Cities = resource('Cities');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {};
        this.props.save({formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({formularios: {}, repetidos: {} });
            this.setState(()=>({ edit: false }));
        }
    }

    componentDidMount() {
        this._mounted = true;
        let params = this.props.match.params;
        if( params.id ) this.getData(params.id);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getData(id){
        Helpers.loader();
        let client = await ProveedoresService.getById(id);
        if( client.data.id ){
            this.props.save({formularios: client.data });
            this.setState(()=>({ edit: true }));
        }else{
            this.props.history.push('/proveedor/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();
        let repetidos = Helpers.groupByKey(store.repetidos);

        let form = {
            ...store.formularios,
            contactos: repetidos
        };

        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();
            let method = 'save';
            if( this.state.edit ) method = 'update';
            delete form['conductor'];
            delete form['vehiculo'];
            delete form['contactos'];
            let response = await ProveedoresService[method](form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                if( this.state.edit ){
                    Helpers.alert({title: '', icon:'success', text: 'Se ha guardado correctamente!'});
                }else{
                    Helpers.alert({title: '', icon:'success', text: 'Se ha creado correctamente!'});
                }
                this.props.history.push('/proveedor/list');
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    return = () => this.props.history.push('/proveedor/list');

    render() {
        const {props:{personType, conductor, vehiculo}, state:{ edit }} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{edit ? 'Editar' :'Nuevo'} Proveedor</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    <Select label="Seleccione el tipo de proveedor" opts={this.PersonType} className='w-100' name="personType" />
                                </div>
                                <h3 />
                                {personType === 1 &&
                                <div>
                                    <div className="w-49 d-i max">
                                        <Select label="Tipo de documento" opts={this.DocumentType} name="docType" />
                                        <Text label="Documento" name="doc" />
                                    </div>
                                    <Text label="Nombre completo" name="names"/>
                                </div>

                                }
                                {personType === 2 &&
                                <React.Fragment>
                                    <div>
                                        <Text label="Nit" name="doc"/>
                                        <Text label="Razón social" name="names"/>
                                    </div>
                                    <div>
                                        <Text label="Representante legal" name="name_rep"/>
                                    </div>
                                </React.Fragment>
                                }
                                <div>
                                    <Text label="Dirección" name="address"/>
                                    <Text label="Celular" name="cell"/>
                                </div>
                                <div>
                                    <Select label="Ciudad" opts={this.Cities} name='city' />
                                    <Text label="Correo electrónico" name="email"/>
                                </div>
                                <div>
                                    <Files name="file"/>
                                </div>
                                <h3 />
                            </Col>
                        </Row>
                    </Provider>
                    {edit &&<Row className="row">
                        <Col>
                            <h4>Conductores</h4>
                            <ul className="list-items">
                                {Array.isArray(conductor) && conductor.map((item,key)=>
                                    <li key={key}>
                                        <p><b>Name:</b> <span>{item.name} {item.surname}</span></p>
                                    </li>
                                )}
                            </ul>
                        </Col>
                        <Col>
                            <h4>Vehiculos</h4>
                            <ul className="list-items">
                                {Array.isArray(vehiculo) && vehiculo.map((item,key)=>
                                    <li key={key}>
                                        <p><b>Placa:</b> <span>{item.plate}</span></p>
                                    </li>
                                )}
                            </ul>
                        </Col>
                    </Row>}
                </div>
                <Row>
                    <Col className="btns">
                        <Btn click={this.save} text="Guardar" type="primary" />
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        personType: store.formularios.personType,
        conductor: store.formularios.conductor,
        vehiculo: store.formularios.vehiculo,
    }),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(Formulario);
