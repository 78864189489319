import React from 'react';
import {Col, Row} from 'react-grid-system';
import './Login.scss';
import {connect} from "react-redux";
import {saveLoginData, saveData} from "../../common/Actions";
import Btn from "../../common/components/Btn/Btn";
import Text from "../../common/components/Fields/Text";
import Provider from "../../common/components/Provider/Provider";
import Helpers from "../../common/Helpers";
import Imagen from '../../common/resources/images/login/login_image.png';
import Logo from '../../common/resources/images/logo.png';
import Model from "./Login.json";
import AuthService from '../../services/Auth';

class Login extends React.Component {

    login = async () => {
        let form = this.props.login;
        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();
            let response = await AuthService.login(form);
            Helpers.closeLoader();
            if( response == null ) {
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
                return;
            }
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.token){
                sessionStorage.clear();
                sessionStorage.setItem('token', response.token);
                this.props.history.push('/dashboard');
                this.props.save({user: response.user});
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    render() {
        return (
            <Row className="login-page">
                <Col className="wrap">
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={8}>
                            <div className="logo">
                                <img src={Logo} alt=""/>
                            </div>
                            <div className="img">
                                <img src={Imagen} alt=""/>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4}>
                            <div className="form">
                                <h2>Bienvenidos</h2>
                                <Provider store="login" save={saveLoginData}>
                                    <Text name='email' label={<span className="user">Username</span>} />
                                    <Text name='password' type="password" label={<span className="pass">Contraseña</span>} />
                                    <Btn click={this.login} text="Iniciar sesión" type="primary" class="btn-block" />
                                </Provider>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default connect(
    (store) => ({login: store.login}),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(Login);
