import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {saveData, saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Date from "../../../common/components/Fields/Date";
import Files from "../../../common/components/Fields/Files";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import AfiliadosService from "../../../services/Afiliados";
import ProveedoresService from "../../../services/Proveedores";
import VehiclesService from "../../../services/Vehicles";
import Model from "../Vehiculos.json";

class Formulario extends React.Component {

    Type = resource('Type');
    VehicleType = resource('VehicleType');
    Cities = resource('Cities');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            afiliados: [],
            proveedores: [],
        };
        this.props.save({formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({formularios: {}, repetidos: {} });
            this.setState(()=>({ edit: false }));
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll(){
        await this.getItems();
        let params = this.props.match.params;
        if( params.id ) await this.getData(params.id);
    }

    async getItems(){
        let [afiliados, proveedores] = await Promise.all([
            AfiliadosService.get(-1),
            ProveedoresService.get(-1),
        ]);
        this.setState(()=>({ afiliados: afiliados.data, proveedores: proveedores.data, edit: false }));
    }

    async getData(id){
        Helpers.loader();
        let client = await VehiclesService.getById(id);
        if( client.data.id ){
            this.props.save({formularios: client.data });
            this.setState(()=>({ edit: true }));
        }else{
            this.props.history.push('/vehiculo/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();

        let form = {
            ...store.formularios,
        };

        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();
            let method = 'save';
            if( this.state.edit ) method = 'update';
            let response = await VehiclesService[method](form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                if( this.state.edit ){
                    Helpers.alert({title: '', icon:'success', text: 'Se ha guardado correctamente!'});
                }else{
                    Helpers.alert({title: '', icon:'success', text: 'Se ha creado correctamente!'});
                }
                this.props.history.push('/vehiculo/list');
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    return = () => this.props.history.push('/vehiculo/list');

    render() {
        const {props:{type}, state:{ edit, afiliados, proveedores }} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{edit ? 'Editar' :'Nuevo'} Vehiculo</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    <Select label="Seleccione el tipo de vehiculo" opts={this.Type} name="type" />
                                    {type === 2 && <Select label={'Seleccione el provedor'} optionLabel={Helpers.clientsLabels} hash="id" opts={proveedores} name="proveedor" />}
                                    {type === 3 && <Select label={'Seleccione el afiliados'} optionLabel={Helpers.clientsLabels} hash="id" opts={afiliados} name="afiliado" />}
                                </div>
                                <h3 />

                                <div>
                                    <Text label="No. Pasajeros" name="no_pasajeros"/>
                                    <Text label="Modelo" name="model"/>
                                </div>
                                <div>
                                    <Text label="Marca" name="marca" />
                                    <Text label="Placa" name="plate"/>
                                </div>
                                <div>
                                    <Select label="Clase de vehiculo" opts={this.VehicleType} name="class" />
                                    <Text label="No. Interno" name="no_interno"/>
                                </div>
                                <div>
                                    <Text label="No. Tarjeta de operación" name="no_tarjeta"/>
                                </div>
                                <div>
                                    <Date label="Fecha de vencimiento soat" name="soat_date" />
                                    <Files name="soat_file"/>
                                </div>
                                <div>
                                    <Date label="Fecha vencimiento tecnomecanica" name="tecn_date" />
                                    <Files name="tecn_file"/>
                                </div>
                                <div>
                                    <Date label="Fecha venc revision bimensual" name="rvb_date" />
                                    <Files name="rvb_file"/>
                                </div>
                                <div>
                                    <Date label="Fecha venc póliza todo riesgo" name="ries_date" />
                                    <Files name="ries_file"/>
                                </div>
                                <div>
                                    <Date label="Fecha vencimiento RC y REC" name="RC_date" />
                                    <Files name="RC_file"/>
                                </div>
                                <h3 />
                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        <Btn click={this.save} text="Guardar" type="primary" />
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({type: store.formularios.type}),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(Formulario);
