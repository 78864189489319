import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Helpers from "../../Helpers";
import './Btn.scss';

const Btn = (props) => {
    let {type, disabled, active, url, click, children, text, variant, id} = props;
    variant = variant !== undefined ? variant : 'contained';
    let className = "btn btn-" + type + (disabled ? ' disabled' : '') + (active ? ' active' : '') + (props.class ? ` ${props.class}` : '');
    return (
        <React.Fragment>
            {url ?
                <a className={`MuiButtonBase-root MuiButton-root MuiButton-${variant} MuiButton-contained${Helpers.ucfirst(type)} ${className}`}
                   id={id} href={url} onClick={click}>{children}{text}</a>
                : <Button variant={variant} color={type} type='button' disabled={disabled} onClick={click}
                          className={className}>{children}{text}</Button>
            }
        </React.Fragment>
    );
};

Btn.propTypes = {
    type: PropTypes.string,
    text: PropTypes.any,
    url: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.any,
    class: PropTypes.string,
    id: PropTypes.string,
    click: PropTypes.func,
    variant: PropTypes.string
};

export default Btn;
