import Api from '../common/Api';
import {Backend, headers} from "../common/Config";
import Helpers from '../common/Helpers';

export default class Calendar {

    static async get(init, end) {
        const response = await Api.get({
            url: `${Backend}/calander/list/${encodeURIComponent(init)}/${encodeURIComponent(end)}`,
            headers
        });
        return Helpers.dataReturn(response);
    };

}
