import React from 'react';
import {Col, Row} from 'react-grid-system';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Helpers from "../../common/Helpers";
import LogsServcices from '../../services/Logs';
import TablePagination from '@material-ui/core/TablePagination';

class Logs extends React.Component {

    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.getData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getData(){
        Helpers.loader();
        let clients = await LogsServcices.get();
        this.setState(()=>({data: clients.data, total: clients.total}));
        Helpers.closeLoader();
    }

    changePage = async (event, page) => {
        Helpers.loader();
        let clients = await LogsServcices.get(page);
        this.setState(()=>({data: clients.data, total: clients.total, page}));
        Helpers.closeLoader();
    };

    render() {
        const {state} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Logs de acciones</h2>
                    </Col>
                </Row>
                <div className="wrap table">
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Usuario</TableCell>
                                    <TableCell>Accion</TableCell>
                                    <TableCell>Entidad</TableCell>
                                    <TableCell>Fecha</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(state.data) && state.data.map((item,key)=>
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            <TableCell>{item.user.names}</TableCell>
                                            <TableCell>{item.descripcion}</TableCell>
                                            <TableCell>{item.entity_id}</TableCell>
                                            <TableCell>{item.created_at}</TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={state.total}
                            rowsPerPage={10}
                            page={state.page}
                            onChangePage={this.changePage}
                        />
                    </TableContainer>
                </div>
            </div>
        );
    }
}

export default Logs;
