import React from 'react';
import {Col, Row} from 'react-grid-system';
import Helpers from "../../Helpers";
import './Loader.scss';

class Loader extends React.Component {

    static toogle(status) {
        if (status === true) {
            Helpers.removeClass(".loader-component", "hide");
        } else {
            setTimeout(() => {
                Helpers.addClass(".loader-component", "hide");
            }, 1000);
        }
    };

    render() {
        return (
            <Row className="loader-component-react">
                <Col>
                    <div className="content">
                        <div className="mesh-loader">
                            <div className="set-one">
                                <div className="circle">&nbsp;</div>
                                <div className="circle">&nbsp;</div>
                            </div>
                            <div className="set-two">
                                <div className="circle">&nbsp;</div>
                                <div className="circle">&nbsp;</div>
                            </div>
                        </div>

                        <div className="spinner">
                            <div className="double-bounce1">&nbsp;</div>
                            <div className="double-bounce2">&nbsp;</div>
                        </div>

                        <div className="text">
                            <h5>Cargando por favor espere...</h5>
                        </div>
                    </div>

                </Col>
            </Row>
        );
    }
}

export default Loader;
