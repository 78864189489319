import PropTypes from "prop-types";
import React from 'react';

const Provider = ({children, store, save, disabled}) => {
    const process = children => children ? React.Children.map(children, child => {
        if (!child) return [];
        let props = {};
        let propsChild = child.props ? child.props : {};
        if (typeof child.type !== "string" && propsChild.component !== 'div') {
            if( disabled ) {
                props = {store, save, disabled};
            }else{
                props = {store, save};
            }
        }
        let children = typeof propsChild.children !== "string" ? process(propsChild.children) : propsChild.children;
        return React.cloneElement(child, props, children);
    }) : [];
    return process(children);
};

Provider.propTypes = {
    store: PropTypes.string,
    save: PropTypes.func,
    disabled: PropTypes.bool
};

export default Provider;
