import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import moment from "moment";
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import 'moment/locale/es';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import {connect} from "react-redux";
import Helpers from "../../common/Helpers";
import CalendarService from '../../services/Calendar';
import './Calendario.scss';
import Menu from "../Servicio/Menu";
import Acciones from './Acciones.json';
import Permisos from './Permisos.json';

class Calendario extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            acciones: []
        };
        this.state.acciones = Helpers.hasPermiso(props.user.role, Permisos, Acciones);
    }

    calendar = React.createRef();

    async getEvents({end, start}){
        Helpers.loader();
        end = moment(end).format('YYYY-MM-DD');
        start = moment(start).format('YYYY-MM-DD');
        let events = await CalendarService.get(start, end);

        this.calendar.current.calendar.removeAllEvents();
        for (let i = 0; i < events.data.length; i++) this.calendar.current.calendar.addEvent(events.data[i]);
        Helpers.closeLoader();
    }

    titleFormat = date => {
        moment.locale('es');
        let year = moment(date.date).format('YYYY');
        let month = moment(date.date).format('MMMM');
        let day_init = moment(date.start).format('DD');
        let day_end = moment(date.end).format('DD');
        this.getEvents(date);
        return `${Helpers.ucfirst(month)} ${day_init} - ${day_end}, ${year}`;
    };

    eventClick = e => {
        console.log(this.state.acciones);
        if( !Helpers.hasAccion(0, this.state.acciones) ) return;
        let {_type, _id} = e.event._def.extendedProps;
        if( _type === 1 ){
            this.props.history.push(`/servicio/edit/${_id}`);
        }else if( _type === 2 ){
            this.props.history.push(`/mantenimiento/edit/${_id}`);
        }
    };

    render(){
        return (
            <div className="formdata">
                <div className="wrap">
                    <FullCalendar
                        allDaySlot={false}
                        header={{
                            left:   'prev,next today',
                            center: 'title',
                            right:  'dayGridMonth timeGridWeek timeGridDay listWeek '
                        }}
                        buttonText={{
                            today:    'Hoy',
                            month:    'Mes',
                            week:     'Semana',
                            day:      'Día',
                            list:     'lista'
                        }}
                        locales={[esLocale]}
                        locale={'es'}
                        ref={this.calendar}
                        selectable={false}
                        selectMirror={true}
                        slotDuration={'0:30:00'}
                        scrollTime={'00:00:00'}
                        timeFormat={'h(:mm)t'}
                        displayEventTime={true}
                        eventClick={this.eventClick}
                        firstDay={1}
                        defaultView="timeGridWeek"
                        titleFormat={this.titleFormat}
                        aspectRatio={1}
                        plugins={[ dayGridPlugin, listPlugin, timeGridPlugin ]} />
                </div>
            </div>
        );
    }
}

export default connect((store) => ({user: store.user}))(Calendario);
