import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import MenuBar from "../MenuBar/MenuBar";
import './Header.scss';

class Header extends React.Component {

    static withMenu = [
        '/dashboard',
        '/membership'
    ];

    constructor(props) {
        super(props);
        this.state = {
            openSideBar: false
        };
    }

    componentDidMount = () => {
        return Header.withMenu.indexOf(this.props.match.path) !== -1 && window.innerWidth > 768 ? MenuBar.toggle() : undefined;
    };

    render() {
        const {props} = this;
        return (
            <Row className="header-top-component">
                <Col md={2}>
                    <MenuBar {...props}/>
                </Col>
                <Col md={10}>
                    {this.props.children}
                </Col>
            </Row>
        );
    }
}

export default connect((store) => ({user: store.user}))(Header);

