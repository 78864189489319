import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import Servicios from '../../../services/Servicios';
import moment from "moment";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import PhotoIcon from '@material-ui/icons/Photo';
import RoomIcon from '@material-ui/icons/Room';

class Detalle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getDataService();
    }

    getDataService = async () => {
        let id = this.props.match.params.id;
        let data = await Servicios.getTwunelId(id);
        if (data.hasOwnProperty('data')) {
            this.setState({ data: data.data })
        }
    }

    showEvidence = url => () => {
        window.open(url)
    }
    showMaps = (lat, lng) => () => {
        let url = `https://www.google.com/maps/@${lat},${lng},16z`;
        window.open(url);
    }

    edit = id => () => this.props.history.push(`/servicio/edit/${id}`);

    render() {
        const { state: { data } } = this;
        return (
            <div className='formdata'>
                <Row>
                    <Col>
                        <h2>Historico Servicio</h2>
                    </Col>
                </Row>
                <br/>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell># Servicio</TableCell>
                                <TableCell>Fecha de cambio</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Lng</TableCell>
                                <TableCell>Ltd</TableCell>
                                <TableCell>Observaciones</TableCell>
                                <TableCell>Evidencia</TableCell>
                                <TableCell>Evidencia Signature</TableCell>
                                <TableCell>Maps</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                Array.isArray(data) && data.map((item, index) =>
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            <TableCell>{item.consecutivo}</TableCell>
                                            <TableCell>{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                            <TableCell>{item.updateType}</TableCell>
                                            <TableCell>{item.evidenceLocation1}</TableCell>
                                            <TableCell>{item.evidenceLocation2}</TableCell>
                                            <TableCell>{item.observations}</TableCell>
                                            <TableCell>
                                                {item.evidenceUrl && <IconButton size="small" onClick={this.showEvidence(item.evidenceUrl)}><InsertDriveFile /></IconButton>}
                                            </TableCell>
                                            <TableCell>
                                                {item.evidenceSignatureUrl && <IconButton size="small" onClick={this.showEvidence(item.evidenceSignatureUrl)}><PhotoIcon /></IconButton>}
                                            </TableCell>
                                            <Table>
                                                {item.evidenceLocation1 && item.evidenceLocation2 && <IconButton size="small" onClick={this.showMaps(item.evidenceLocation1, item.evidenceLocation2)}><RoomIcon /></IconButton>}
                                            </Table>
                                        </TableRow>
                                    </React.Fragment>
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>


            </div>
        )
    }

}

export default connect()(Detalle);
