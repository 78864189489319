import Api from '../common/Api';
import {Backend, headers} from "../common/Config";
import Helpers from '../common/Helpers';

export default class Informe {

    static async get(form) {
        const response = await Api.post({
            url: `${Backend}/informes/list`,
            form,
            headers
        });
        return Helpers.dataReturn(response);
    };

}
