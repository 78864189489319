import Api from '../common/Api';
import {Backend, headers} from "../common/Config";
import Helpers from '../common/Helpers';

export default class Files {

    static async download(path) {
        const response = await Api.post({
            url: `${Backend}/files/download`,
            form: {path},
            headers: headers
        });
        return Helpers.dataReturn(response);
    };

}
