import React from 'react';
import Formulario from './components/Formulario';
import Lista from "./components/Lista";

const Solicitudes = props => {
    const path = props.match.path;
    return (
        <React.Fragment>
            {(path === '/solicitud/new' || path === '/solicitud/:action/:id') && <Formulario {...props}/>}
            {path === '/solicitud/list' && <Lista {...props}/>}
        </React.Fragment>
    );
};

export default Solicitudes;
