import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from "react-redux";
import { saveData, saveFormulariosData } from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Date from "../../../common/components/Fields/Date";
import Files from "../../../common/components/Fields/Files";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import { resource } from "../../../common/lang/lang";
import Store from "../../../common/Store";
import AfiliadosService from "../../../services/Afiliados";
import ProveedoresService from "../../../services/Proveedores";
import ConductoresService from "../../../services/Conductores";
import Model from "../Conductores.json";

class Formulario extends React.Component {

    Type = resource('Type');
    Banks = resource('Banks');
    Cities = resource('Cities');
    Licensed = resource('Licensed');
    _mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            afiliados: [],
            proveedores: [],
        };
        this.props.save({ formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({ formularios: {}, repetidos: {} });
            this.setState(() => ({ edit: false }));
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll() {
        await this.getItems();
        let params = this.props.match.params;
        if (params.id) await this.getData(params.id);
    }

    async getItems() {
        let [afiliados, proveedores] = await Promise.all([
            AfiliadosService.get(-1),
            ProveedoresService.get(-1),
        ]);
        this.setState(() => ({ afiliados: afiliados.data, proveedores: proveedores.data, edit: false }));
    }

    async getData(id) {
        Helpers.loader();
        let client = await ConductoresService.getById(id);
        if (client.data.id) {
            this.props.save({ formularios: client.data });
            this.setState(() => ({ edit: true }));
        } else {
            this.props.history.push('/condutcor/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();

        let form = {
            ...store.formularios,
        };

        let model = Model;

        if(form.type == 2){
            console.log("Externo");
            model.birhtday.rules = ""
            model.eps.rules = "";
            model.pesion.rules = "";
            model.persion_date.rules = "";
            model.cesantias.rules = "";
            model.bank.rules = "";
            model.account.rules = "";
            model.cargo.rules = "";
            model.amount.rules = ""
            model.exam_date.rules = "";
            model.exam.rules = "";
        }

        let valid = Helpers.validForm(form, model);
        if (valid.keys) {
            this.props.save({ errors: valid.keys });
            Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
        } else {
            this.props.save({ errors: {} });
            Helpers.loader();
            let method = 'save';
            if (this.state.edit) method = 'update';
            let response = await ConductoresService[method](form);
            Helpers.closeLoader();
            if (response.message) {
                Helpers.alert({ title: 'Error!', text: response.message });
            } else if (response.status) {
                if (this.state.edit) {
                    Helpers.alert({ title: '', icon: 'success', text: 'Se ha guardado correctamente!' });
                } else {
                    Helpers.alert({ title: '', icon: 'success', text: 'Se ha creado correctamente!' });
                }
                this.props.history.push('/condutcor/list');
            } else {
                Helpers.alert({ title: 'Error!', text: 'Error intente más tarde' });
            }
        }
    };

    return = () => this.props.history.push('/conductor/list');

    render() {
        const { props: { type }, state: { edit, afiliados, proveedores } } = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{edit ? 'Editar' : 'Nuevo'} Conductor</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    <Select label="Seleccione el tipo de conductor" opts={this.Type} name="type" />
                                    {type === 2 && <Select label={'Seleccione el provedor'} optionLabel={Helpers.clientsLabels} hash="id" opts={proveedores} name="proveedor" />}
                                    {type === 3 && <Select label={'Seleccione el afiliados'} optionLabel={Helpers.clientsLabels} hash="id" opts={afiliados} name="afiliado" />}
                                </div>
                                <h3 />
                                <div>
                                    <Text label="Nombre" name="name" />
                                    <Text label="Apellido" name="surname" />
                                </div>
                                <div>
                                    <Text label="Cedula" name="doc" />
                                    <Text label="Direccion" name="adress" />
                                </div>
                                <div>
                                    <Text label="Telefono" name="phone" />
                                    <Text label="email" name="email" />
                                </div>
                                <div>
                                    <Text label="Nombre de contacto emergencia" name="name_emer" />
                                    <Text label="Telefono de contacto emergencia" name="phone_emer" />
                                </div>
                                <div>
                                    {type != 2 && <Date label="Fecha de nacimiento" name="birhtday" />}
                                    {type != 2 && <Text label="Eps" name="eps" />}
                                </div>
                                <div>
                                    {type != 2 && <Text label="Fondo de pensión" name="pesion" />}
                                    {type != 2 && <Date label="Fecha de ingreso" name="persion_date" />}
                                </div>
                                <div>
                                    {type != 2 && <Text label="Fondo de cesantías" name="cesantias" />}
                                    {type != 2 && <Date label="Fecha de retiro" name="cesantias_date" />}
                                </div>
                                <div>
                                    {type != 2 && <Select label="Banco" name="bank" opts={this.Banks} />}
                                    {type != 2 && <Text label="Cuenta de nomina" name="account" number />}
                                </div>
                                <div>
                                    {type != 2 && <Text label="Cargo" name="cargo" />}
                                    {type != 2 && <Text label="Salario" name="amount" money />}
                                </div>
                                <div>
                                    <Select label="Tipo de licencia de conducción" name="licensed" opts={this.Licensed} />
                                    <Text label="No. de licencia" name="licence_number" number />
                                </div>
                                <div>
                                    <Date label="Fecha de vencimiento de la licencia" name="date_licesed" />
                                </div>
                                {type != 2 &&
                                    <div>
                                        <Date label="Fecha Del ultimo examen psicosensométrico" name="exam_date" />
                                        <Files name="exam" />
                                    </div>
                                }

                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        <Btn click={this.save} text="Guardar" type="primary" />
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({ type: store.formularios.type }),
    (dispatch) => ({ save: data => dispatch(saveData(data)) }),
)(Formulario);
