import {Promise} from 'es6-promise';
import Helpers from "./Helpers";

class Api {

    static HttpRequest(method, data) {
        return new Promise(function (resolve) {
            const {url, form = "", headers = {}} = data;
            let xhr = new XMLHttpRequest();

            xhr.open(method, url);
            for (let j in headers) xhr.setRequestHeader(j, headers[j]);
            if( sessionStorage.getItem('token') != null ) xhr.setRequestHeader('Authorization', sessionStorage.getItem('token'));

            xhr.onload = function () {
                let response = xhr.response;
                try {
                    resolve(JSON.parse(response));
                } catch (e) {
                    resolve(response);
                }
                console.warn(this.status);
                console.warn(xhr.statusText);
                if( this.status === 403 || this.status === 429 ) Helpers.logOut();
            };
            xhr.onerror = function () {
                resolve(null);
                console.warn(this.status);
                console.warn(xhr.statusText);
                if( this.status === "" || this.status === 403 || this.status === 429 ) Helpers.logOut();
            };
            if (form instanceof FormData) {
                xhr.send(form);
            } else {
                xhr.send(form !== ""
                    ? JSON.stringify(form).replace(/<(?:.|\n)*?>/gm, '')
                    : null
                );
            }
        });
    }

    static async get(data) {
        return await Api.HttpRequest('GET', data).then(function (response) {
            return response;
        });
    };

    static async post(data) {
        return await Api.HttpRequest('POST', data).then(function (response) {
            return response;
        });
    };

    static async put(data) {
        return await Api.HttpRequest('PUT', data).then(function (response) {
            return response;
        })
    };

}

export default Api;
