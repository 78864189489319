import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PropTypes from "prop-types";
import React from 'react';

class HelperText extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.tooltip = React.createRef();
    }

    componentDidMount() {
        let el = this.tooltip.current;
        if (el != null) this.calcOpen(el);
    }

    calcOpen = el => {
        // el.parentNode.style.pointerEvents = "all";
        el.addEventListener('mouseenter', () => {
            this.setState(() => ({open: true}));
        });
        el.addEventListener('mouseleave', () => {
            this.setState(() => ({open: false}));
        });
    };

    render() {
        let {props: {value, placement = 'right'}, state: {open}} = this;
        value = value ? value : '';
        return (
            <React.Fragment>
                {value && <Tooltip ref={this.tooltip} open={open} placement={placement} arrow
                                   title={value}><HelpOutlineIcon/></Tooltip>}
            </React.Fragment>
        );
    }

}

HelperText.propTypes = {
    value: PropTypes.string,
    placement: PropTypes.string,
};

export default HelperText;
