import Api from "../common/Api";
import { Backend, headers } from "../common/Config";
import Helpers from "../common/Helpers";

export default class Solicitudes {

    static async save(form) {
        const response = await Api.post({
            url: `${Backend}/solicitudes/create`,
            form: form,
            headers
        });
        return Helpers.dataReturn(response);
    };

    static async get(form, page = 0) {
        const response = await Api.post({
            url: `${Backend}/solicitudes/list/${page}`,
            form: form,
            headers
        });
        return Helpers.dataReturn(response);
    }

    static async costeoRutas(form){
        const response = await Api.post({
            url: `${Backend}/solicitudes/costoruta`,
            form,
            headers
        })
        return Helpers.dataReturn(response)
    }

    static async getById(id){
        const response = await Api.get({
            url: `${Backend}/solicitudes/get/${id}`,
            headers
        });
        return Helpers.dataReturn(response)
    }

    static async update(form) {
        const response = await Api.post({
            url: `${Backend}/solicitudes/update`,
            form: form,
            headers
        });
        return Helpers.dataReturn(response);
    };
}