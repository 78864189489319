import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import Select from '../../../common/components/Fields/Select';
import Text from '../../../common/components/Fields/Text';
import { resource } from '../../../common/lang/lang';
import Btn from "../../../common/components/Btn/Btn";
import { saveData, saveFormulariosData, saveRepeProveedores, saveRepetidosData } from '../../../common/Actions';
import Provider from "../../../common/components/Provider/Provider";
import Store from '../../../common/Store';
import Ubicaciones from '../../../services/Ubicaciones';

class Formulario extends React.Component {

    Cities = resource('Cities');

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0,
            id: this.props.match.params.id
        };
    }

    save = async () => {

        let data = Store.getState().formularios;

        let ubication = await Ubicaciones.create(data);

        if (ubication.code == 200) {
            this.props.history.push(`/ubicaciones`);
        }

    }

    update = async () => {
        let data = Store.getState().formularios;

        if (this.state.id) {
            let update = await Ubicaciones.update(this.state.id, data);

            let { code, message } = update;

            if (code == 200) {
                this.props.history.push(`/ubicaciones`);
            } else {
                alert(message);
            }
        }
    }

    async componentDidMount() {
        if (this.state.id) {
            let ubicacion = await Ubicaciones.getOne(this.state.id);

            const { code, data, message } = ubicacion;

            this.props.save({ formularios: data });

        }
    }

    render() {
        const { state } = this;
        const disabledAsignado = false;
        return (
            <div className='formdata'>
                <Row>
                    <Col>
                        <h2>{(state.id) ? "Editar" : "Nueva"} ubicacion</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <div>
                            <Text disabled={disabledAsignado} label="Nombre de ubicacion" name="name" />
                            <Select disabled={disabledAsignado} label="Ciudad" opts={this.Cities} name='code_city' />
                        </div>
                        <div>
                            <Text disabled={disabledAsignado} label="Latitud" name="lat" />
                            <Text disabled={disabledAsignado} label="Longitud" name="lng" />
                        </div>
                    </Provider>
                </div>
                <div className='btns'>
                    {!state.id && <Btn click={this.save} text="Guardar" type="primary" />}
                    {state.id && <Btn click={this.update} text="Actualizar" type="primary" />}
                </div>
            </div>
        )
    }
}

export default connect(
    (store) => ({
        name: store.formularios.name,
        ubic_origen: store.formularios.ubic_origen,
        lat: store.formularios.lat,
        lng: store.formularios.lng
    }),
    (dispatch) => ({
        save: data => dispatch(saveData(data)),
        saveForm: data => dispatch(saveFormulariosData(data))
    })
)(Formulario)