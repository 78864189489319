import React from 'react';
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { saveData, saveFormulariosData, saveRepetidosData } from "../../../common/Actions";
import Btn from '../../../common/components/Btn/Btn';
import Select from '../../../common/components/Fields/Select';
import Text from '../../../common/components/Fields/Text';
import Provider from "../../../common/components/Provider/Provider";
import Helpers from '../../../common/Helpers';
import { resource } from '../../../common/lang/lang';
import Store from '../../../common/Store';
import CosteoRutas from '../../../services/CosteoRutas';

class Formulario extends React.Component {

    VehicleType = resource('VehicleType');
    Cities = resource('Cities');
    Trayecto = [
        {
            code: "IDA",
            name: "IDA"
        },
        {
            code: "IDA Y VUELTA",
            name: "IDA Y VUELTA"
        }
    ];

    constructor(props) {
        super(props)
        this.state = {
            date: '',
            counter: [0],
            idParam: this.props.match.params.id
        }
        this.props.save({ formularios: {}, repetidos: {} });
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        if (this.state.idParam) {
            let response = await CosteoRutas.getById(this.state.idParam)
            console.log(response);
            response.data.costo_cond = Helpers.formatToMask(response.data.costo_cond);
            response.data.costo_mant_gen = Helpers.formatToMask(response.data.costo_mant_gen);
            response.data.costo_mant_caja = Helpers.formatToMask(response.data.costo_mant_caja);
            response.data.costo_llantas = Helpers.formatToMask(response.data.costo_llantas);
            response.data.costo_combustible = Helpers.formatToMask(response.data.costo_combustible);

            response.data.peajes.forEach(element => {
                element.valor = Helpers.formatToMask(element.valor);
            })

            let repetidos = Helpers.ungroupByKey(response.data.peajes);

            let counter = response.data.peajes.length;
            let counterState = [];

            for (let i = 0; i < counter; i++) {
                counterState.push(i)
            }

            console.log(counter, counterState)

            this.props.save({ formularios: response.data, repetidos: repetidos });
            this.setState({ counter: counterState })
        }
    }

    add = name => () => {
        let counter = this.state[name];
        let index = counter[counter.length - 1] + 1;
        counter.push(isNaN(index) ? 0 : index);
        if (counter.length > 20) return;
        this.setState(() => ({ [name]: counter }));
    };

    remove = (key, name) => () => {
        let newcounter = [];
        let counter = this.state[name];
        for (let i = 0; i < counter.length; i++) if (counter[i] !== key) newcounter.push(counter[i]);
        this.setState(() => ({ [name]: newcounter }));

        let repetidos = Helpers.removeGroupByKey(Helpers.groupByKey(Store.getState().repetidos), key);
        this.props.save({ 'repetidos': repetidos });
    };

    return = () => this.props.history.push('/costeorutas');

    save = async () => {
        let data = Store.getState().formularios;
        let repetidos = Store.getState().repetidos;
        console.log(repetidos)
        let repetidosB = Helpers.groupByKey(repetidos);

        data.costo_cond = Helpers.formatOnlyNumbers(data.costo_cond);
        data.costo_mant_gen = Helpers.formatOnlyNumbers(data.costo_mant_gen);
        data.costo_mant_caja = Helpers.formatOnlyNumbers(data.costo_mant_caja);
        data.costo_llantas = Helpers.formatOnlyNumbers(data.costo_llantas);
        data.costo_combustible = Helpers.formatOnlyNumbers(data.costo_combustible);

        repetidosB.forEach(element => {
            element.valor = Helpers.formatOnlyNumbers(element.valor);
        })

        data.disponibilidad = repetidosB;
        let response = await CosteoRutas.save(data);



        if (response.status) {
            this.props.history.push(`/costeorutas`);
        }

    }

    update = async () => {
        let data = Store.getState().formularios;
        let repetidos = Store.getState().repetidos;

        let repetidosB = Helpers.groupByKey(repetidos);

        data.costo_cond = Helpers.formatOnlyNumbers(data.costo_cond);
        data.costo_mant_gen = Helpers.formatOnlyNumbers(data.costo_mant_gen);
        data.costo_mant_caja = Helpers.formatOnlyNumbers(data.costo_mant_caja);
        data.costo_llantas = Helpers.formatOnlyNumbers(data.costo_llantas);
        data.costo_combustible = Helpers.formatOnlyNumbers(data.costo_combustible);

        repetidosB.forEach(element => {
            element.valor = Helpers.formatOnlyNumbers(element.valor);
        })

        delete data.peajes

        let form = {
            ...data,
            disponibilidad: repetidosB
        }

        let response = await CosteoRutas.update(form);

        if (response.status) {
            this.props.history.push(`/costeorutas`);
        }
    }

    render() {
        const { state, props } = this;
        return (
            <div>
                <div className="formdata">
                    <Row>
                        <Col>
                            <h2>Nuevo Costeo de ruta</h2>
                        </Col>
                        <Col>
                            <div className="top">
                                <p><span>{state.date}</span></p>
                            </div>
                        </Col>
                    </Row>
                    <div className="wrap">
                        <Provider store='formularios' save={saveFormulariosData}>
                            <div>
                                <Select label='Origen' name='origen' opts={this.Cities} />
                                <Select label='Destino' name='destino' opts={this.Cities} />
                            </div>
                            <div>
                                <Select label='Tipo trayecto' name='tipo_trayecto' opts={this.Trayecto} />
                                <Text label="Vía" name='via' />
                            </div>
                            <div>
                                <Select label='Tipo vehiculo' name='tipo_veh' opts={this.VehicleType} />
                                <Text label="Costo conductor $" money name='costo_cond' />
                            </div>
                            <div>
                                <Text label="Costo mant. general $" money name='costo_mant_gen' />
                                <Text label="Costo mont. caja $" money name='costo_mant_caja' />
                            </div>
                            <div>
                                <Text label="Costo llantas $" money name='costo_llantas' />
                                <Text label="Costo combustible $" money name='costo_combustible' />
                            </div>
                        </Provider>
                        <h3></h3>
                        <Provider save={saveRepetidosData} store="repetidos">
                            <Row className="row">
                                <Col>
                                    <h4>Peajes</h4>
                                    {state.counter.map((item, key) =>
                                        <React.Fragment key={key}>
                                            <div>
                                                <Text label="Nombre de peaje" name={`nom_peaje_${item}`} />
                                                <Text label="Valor" money name={`valor_${item}`} />
                                            </div>
                                            <div className="w-100 d-i">
                                                <Btn click={this.remove(item, 'counter')} text="Remover" type="secondary" class="remove" />
                                            </div>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='text-center'>
                                        <Btn click={this.add('counter')} text="Agregar peaje" type="primary" class="add-contact more" />
                                    </div>
                                </Col>
                            </Row>
                        </Provider>
                    </div>
                    <div className='btns'>
                        {!state.idParam && <Btn text="Guardar" type="primary" click={this.save} />}
                        {state.idParam && <Btn text="Actualizar" type="primary" click={this.update} />}
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (store) => ({
        name: store.formularios.name
    }),
    (dispatch) => ({
        save: data => dispatch(saveData(data)),
        saveForm: data => dispatch(saveFormulariosData(data)),
        saveRepe: data => dispatch(saveRepetidosData(data)),
    })
)(Formulario)