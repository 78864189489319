import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Col, Row } from 'react-grid-system';
import Loader from "../../common/components/Loader/Loader";
import { TableContainer, Table, TableCell, TableHead, TableBody, TableRow } from '@material-ui/core';
import Dashboard from '../../services/Dashboard';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import './DashBoard.scss';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const labels = ['Ejecutados', 'Pendientes'];

class DashBoard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            totalEjecutados: 0,
            totalPendientes: 0,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Servicios',
                    },
                },
            }
        }
    }

    componentDidMount() {
        Loader.toogle(false);
        this.getData();
    }

    async getData() {
        let data = await Dashboard.index()
        data = data.data;
        if (data) {
            this.setState({
                totalEjecutados: data.realizados.length,
                totalPendientes: data.pendientes.length,
                dataEjecutados: data.realizados,
                dataPendientes: data.pendientes
            });
        }
    }

    view = id => () => this.props.history.push(`/servicio/view/${id}`);

    render() {
        let { state: { dataEjecutados, dataPendientes, totalEjecutados, totalPendientes, options } } = this;
        return (
            <div className="dashboard-page">
                <Row>
                    <Col>
                        {
                            totalEjecutados &&
                            <Row >
                                <Col className="formdata">
                                    {
                                        (totalEjecutados > 0) &&
                                        <Bar options={options} data={
                                            {
                                                labels: ['Ejecutados', 'Pendientes'],
                                                datasets: [
                                                    {
                                                        label: 'Servicios',
                                                        data: [totalEjecutados, totalPendientes],
                                                        backgroundColor: 'rgba(166,253,16,0.5)',
                                                    }
                                                ]
                                            }
                                        } />
                                    }
                                </Col>
                            </Row>
                        }

                        {
                            (dataEjecutados && dataPendientes) &&
                            <Row>
                                <Col>
                                    <div className='table-reporte'>
                                        <h4>Servicios Ejecutados</h4>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell># Servicio</TableCell>
                                                        <TableCell>Total</TableCell>
                                                        <TableCell>Fecha ida</TableCell>
                                                        <TableCell>Fecha Regreso</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {
                                                        Array.isArray(dataEjecutados) && dataEjecutados.map((item, key) => (
                                                            <TableRow key={key}>
                                                                <TableCell> {item.id} </TableCell>
                                                                <TableCell> {item.total} </TableCell>
                                                                <TableCell> {item.fecha_ida} </TableCell>
                                                                <TableCell> {item.fecha_regreso} </TableCell>
                                                                <TableCell> {item.state >= 2 && <IconButton size="small" onClick={this.view(item.id)}><VisibilityIcon /></IconButton>} </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='table-reporte'>
                                        <h4>Servicios Pendientes</h4>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell># Servicio</TableCell>
                                                        <TableCell>Total</TableCell>
                                                        <TableCell>Fecha ida</TableCell>
                                                        <TableCell>Fecha Regreso</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {
                                                        Array.isArray(dataPendientes) && dataEjecutados.map((item, key) => (
                                                            <TableRow key={key}>
                                                                <TableCell> {item.id} </TableCell>
                                                                <TableCell> {item.total} </TableCell>
                                                                <TableCell> {item.fecha_ida} </TableCell>
                                                                <TableCell> {item.fecha_regreso} </TableCell>
                                                                <TableCell> {item.state >= 2 && <IconButton size="small" onClick={this.view(item.id)}><VisibilityIcon /></IconButton>} </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Col>
                            </Row>
                        }

                    </Col>
                </Row >
            </div >
        );
    }
}

export default DashBoard;
