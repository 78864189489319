import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {saveData, saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import UsuariosService from "../../../services/Usuarios";
import Model from "../Usuarios.json";

class Formulario extends React.Component {

    DocumentType = resource('DocumentType');
    Roles = resource('Roles');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {};
        this.props.save({formularios: {}, repetidos: {} });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.match) !== JSON.stringify(this.props.match)) {
            this.props.save({formularios: {}, repetidos: {} });
            this.setState(()=>({ edit: false }));
        }
    }

    componentDidMount() {
        this._mounted = true;
        let params = this.props.match.params;
        if( params.id ) this.getData(params.id);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getData(id){
        Helpers.loader();
        let client = await UsuariosService.getById(id);
        if( client.data.id ){

            this.props.save({formularios: client.data });
            this.setState(()=>({ edit: true }));
        }else{
            this.props.history.push('/usuario/list');
        }
        Helpers.closeLoader();
    }

    save = async () => {
        let store = Store.getState();

        let form = {
            ...store.formularios,
        };

        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();
            let method = 'save';
            if( this.state.edit ) method = 'update';
            let response = await UsuariosService[method](form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                if( this.state.edit ){
                    Helpers.alert({title: '', icon:'success', text: 'Se ha guardado correctamente!'});
                }else{
                    Helpers.alert({title: '', icon:'success', text: 'Se ha creado correctamente!'});
                }
                this.props.history.push('/usuario/list');
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    return = () => this.props.history.push('/usuario/list');

    render() {
        const {state:{ edit }} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>{edit ? 'Editar' :'Nuevo'} Usuario</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <Row className="row">
                            <Col>
                                <div>
                                    <div className="w-49 d-i max">
                                        <Select label="Tipo de documento" opts={this.DocumentType} name="docType" />
                                        <Text label="Documento" name="doc" />
                                    </div>
                                    <Text label="Nombre completo" name="names"/>
                                </div>
                                <div>
                                    <Text label="Celular" name="cell"/>
                                    <Text label="Correo electrónico" name="email"/>
                                </div>
                                <div>
                                    <Text label="Contraseña" name="password" type={'password'}/>
                                    <Select label="Seleccione un rol" opts={this.Roles} name="role" />
                                </div>
                            </Col>
                        </Row>
                    </Provider>
                </div>
                <Row>
                    <Col className="btns">
                        <Btn click={this.save} text="Guardar" type="primary" />
                        <Btn click={this.return} text="Cancelar" type="secondary" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (store) => ({}),
    (dispatch) => ({save: data => dispatch(saveData(data))}),
)(Formulario);
