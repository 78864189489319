import Api from '../common/Api';
import {Backend, headers} from "../common/Config";
import Helpers from '../common/Helpers';

export default class Notificaciones {

    static async get(page = 0) {
        const response = await Api.get({
            url: `${Backend}/notificaciones/list/${page}`,
            headers
        });
        return Helpers.dataReturn(response);
    };

}
