import AppBar from "@material-ui/core/AppBar/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React from 'react';
import {connect} from "react-redux";
import SwipeableViews from "react-swipeable-views";
import Helpers from "../../common/Helpers";
import Formulario from "./components/Formulario";
import Lista from "./components/Lista";
import Menu from "./Menu.json"
import Detalle from "./components/Detalle"
import Permisos from "./Permisos.json"

class Servicio extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            tap: 0,
            items: []
        };
        this.state.items = Helpers.hasPermiso(props.user.role, Permisos, Menu);
    }

    changeTap = (e, newValue) => {
        this.setState(() => ({tap: newValue}));
    };

    render() {
        const {props, state: {tap, items}} = this;
        const path = props.match.path;
        return (
            <React.Fragment>
                {(path === '/servicio/new' || path === '/servicio/:action/:id' ) && <Formulario {...props}/>}
                {path === '/servicio/list' && <React.Fragment>
                    <div className="formdata content">
                        <AppBar position="static" color="default">
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                value={tap}
                                variant="scrollable"
                                scrollButtons="on"
                                onChange={this.changeTap}
                            >
                                {items.map((item,key)=>
                                    <Tab key={key} label={<h2>{item.text}</h2>}/>
                                )}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews index={tap}>
                            {items.map((item,key)=>
                                <React.Fragment key={key}>
                                    {tap === key && <Lista {...props} type={item.id}/>}
                                </React.Fragment>
                            )}
                        </SwipeableViews>
                    </div>
                </React.Fragment>}
                { path === '/serviciotwnel/:id' && <Detalle  {...props} />}
            </React.Fragment>
        );
    }
}

export default connect((store) => ({user: store.user}))(Servicio);

