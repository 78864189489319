import React from 'react';
import Formulario from "./components/Formulario";
import Lista from "./components/Lista";

const Vehiculos = props => {
    const path = props.match.path;
    return (
        <React.Fragment>
            {(path === '/vehiculo/new' || path.indexOf('/vehiculo/edit') === 0) && <Formulario {...props}/>}
            {path === '/vehiculo/list' && <Lista {...props}/>}
        </React.Fragment>
    );
};

export default Vehiculos;
