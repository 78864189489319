import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import Btn from '../../../common/components/Btn/Btn';
import Select from '../../../common/components/Fields/Select';
import Provider from '../../../common/components/Provider/Provider';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { saveFormulariosData } from '../../../common/Actions';
import Helpers from '../../../common/Helpers';
import { resource } from '../../../common/lang/lang';
import Store from '../../../common/Store';
import CosteoRutas from '../../../services/CosteoRutas';


class Lista extends React.Component {

    Cities = resource('Cities')
    VehicleType = resource('VehicleType');

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0
        }
    }

    componentDidMount() {
        this.getData();
    }


    async getData(page = 0) {
        let form = Store.getState().formularios;
        let costeo = await CosteoRutas.get(form, page);
        if (costeo.data) {
            this.setState({ total: costeo.total, data: costeo.data, page: page });
        }
    }

    changePage = (event, page) => {
        this.getData(page);
    };

    search = () => {
        this.setState(() => ({ page: 0 }));
        this.getData();
    }

    edit = id => () => this.props.history.push(`/costeorutas/edit/${id}`);

    render() {
        const { state, props: { campo } } = this;
        return (
            <div className='formdata' >
                <Row>
                    <Col>
                        <h2>Costeos de ruta</h2>
                    </Col>
                </Row>
                <div className="wrap table">
                    <Row>
                        <Provider store={'formularios'} save={saveFormulariosData}>
                            <Col md={10}>

                                <Select name={'campo'} opts={[
                                    {
                                        name: 'Origen',
                                        code: 'origen'
                                    },
                                ]} />
                                {(campo === 'origen' || campo === 'destino') &&
                                    <Select name={'search'} label={'Buscar...'} opts={this.Cities} />
                                }

                            </Col>
                            <Col md={2}>
                                <Btn text={"Buscar"} type={'primary'} class={'btn-block'} click={this.search} />
                            </Col>
                        </Provider>
                    </Row>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Origen</TableCell>
                                    <TableCell>Destino</TableCell>
                                    <TableCell>Tipo trayecto</TableCell>
                                    <TableCell>Via</TableCell>
                                    <TableCell>Tipo vehiculo</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(state.data) && state.data.map((item, key) =>
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            <TableCell>{Helpers.getStatic(item.origen, this.Cities).name}</TableCell>
                                            <TableCell>{Helpers.getStatic(item.destino, this.Cities).name}</TableCell>
                                            <TableCell>{item.tipo_trayecto}</TableCell>
                                            <TableCell>{item.via}</TableCell>
                                            <TableCell>{Helpers.getStatic(item.tipo_veh, this.VehicleType).name}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className="last-td">
                                                {item.state !== 5 && <IconButton size="small" onClick={this.edit(item.id)}><EditIcon /></IconButton>}
                                                {item.state === 5 && <IconButton size="small" onClick={this.view(item.id)}><VisibilityIcon /></IconButton>}
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={state.total}
                            rowsPerPage={10}
                            page={state.page}
                            onChangePage={this.changePage}
                        />
                    </TableContainer>
                </div>
            </div>

        )
    }
}

export default connect(
    (store) => ({
        campo: store.formularios.campo
    }),
    (dispatch) => ({}),
)(Lista);