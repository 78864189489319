import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {Col, Row} from "react-grid-system";
import {connect} from "react-redux";
import {saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ClientsServcices from "../../../services/Clients";
import MantenimientoService from "../../../services/Mantenimiento";
import ConductoresService from "../../../services/Conductores";
import VehiclesService from "../../../services/Vehicles";
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';

class Lista extends React.Component {

    MatenimientoType = resource('MatenimientoType');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0,
            type: props.type,
            vehiculos: [],
            conductor: [],
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll(){
        let [conductor, vehiculos] = await Promise.all([
            ConductoresService.get(),
            VehiclesService.get(),
        ]);
        this.setState(()=>({ conductor: conductor.data, vehiculos: vehiculos.data }));
        await this.getData();
    }

    async getData(){
        Helpers.loader();
        let form = Store.getState().formularios;
        let clients = await MantenimientoService.getState(0, this.state.type, form);
        this.setState(()=>({data: clients.data, total: clients.total}));
        Helpers.closeLoader();
    }

    edit = id => () => this.props.history.push(`/mantenimiento/edit/${id}`);
    view = id => () => this.props.history.push(`/mantenimiento/view/${id}`);

    changePage = async (event, page) => {
        Helpers.loader();
        let clients = await MantenimientoService.getState(page, this.state.type);
        this.setState(()=>({data: clients.data, total: clients.total, page}));
        Helpers.closeLoader();
    };

    search = () => {
        this.setState(()=>({ page: 0 }));
        this.getData();
    };

    render() {
        const {state, props: {campo}} = this;
        return (
            <div className="wrap table">
                <Row>
                    <Provider store={'formularios'} save={saveFormulariosData}>
                        <Col md={10}>
                            {campo === 'vehiculo' &&
                            <Select name={'search'} label={'Buscar...'} optionLabel={Helpers.vehicleLabels} opts={state.vehiculos} hash={'id'} />
                            }
                            {campo === 'conductor' &&
                            <Select name={'search'} label={'Buscar...'} optionLabel={Helpers.driveLabels} opts={state.conductor} hash={'id'} />
                            }
                            <Select name={'campo'} opts={[
                                {
                                    name: 'Placa',
                                    code: 'vehiculo'
                                },
                                {
                                    name: 'Conductor',
                                    code: 'conductor'
                                }
                            ]} />
                        </Col>
                        <Col md={2}>
                            <Btn text={"Buscar"} type={'primary'} class={'btn-block'} click={this.search}/>
                        </Col>
                    </Provider>
                </Row>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Placa</TableCell>
                                <TableCell>Conductor</TableCell>
                                <TableCell>Fecha inicio</TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(state.data) && state.data.map((item,key)=>
                                <React.Fragment key={key}>
                                    <TableRow>
                                        <TableCell>{item.vehiculo?.plate}</TableCell>
                                        <TableCell>{item.conductor?.name}</TableCell>
                                        <TableCell>{item.init_date} {item.init_hour}</TableCell>
                                        <TableCell>{Helpers.getStatic(item.type, this.MatenimientoType).name}</TableCell>
                                        <TableCell className="last-td">
                                            {item.state === 0 && <IconButton size="small" onClick={this.edit(item.id)}><EditIcon /></IconButton>}
                                            {item.state === 1 && <IconButton size="small" onClick={this.view(item.id)}><VisibilityIcon /></IconButton>}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={state.total}
                        rowsPerPage={10}
                        page={state.page}
                        onChangePage={this.changePage}
                    />
                </TableContainer>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        campo: store.formularios.campo
    }),
    (dispatch) => ({}),
)(Lista);

