import PropTypes from "prop-types";
import React from 'react';
import {connect} from 'react-redux';
import Helpers from "../../Helpers";
import Autocomplete from '../Autocomplete/Autocomplete';

const Select = ({value, update, opts, label, name, multi, disabled, free, optionLabel, change, clear, close, error, defaultValue, optsData, saveOpt, helpe, scroll, onlyString, className, hash}) => {
    hash = hash ? hash: 'code';
    let newOptsData = [];
    if (free) {
        newOptsData = Helpers.filterArray(optsData, name);
        opts = [...opts, ...newOptsData];
    }
    if (value === undefined && defaultValue !== undefined) {
        if (name !== '') {
            update({[name]: defaultValue});
        } else {
            value = defaultValue;
        }
    } else {
        value = value ? value : defaultValue;
    }
    optionLabel = optionLabel ? optionLabel : (option => option ? `${option.name}` : option);
    const handler = (e, v) => {
        if (v == null) v = {[hash]: ''};
        if (free && v !== '') {
            //Process and save custom data from user
            if (typeof v === "string") {
                v = Helpers.newUserOpts(v, name);
                newOptsData = Helpers.addToArray(optsData, v);
                saveOpt(newOptsData);
            } else if (Array.isArray(v)) {
                for (let i = 0; i < v.length; i++) if (typeof v[i] === "string") {
                    v[i] = Helpers.newUserOpts(v[i], name);
                    newOptsData = Helpers.addToArray(optsData, v[i]);
                    saveOpt(newOptsData);
                }
            }
        }
        if (Array.isArray(v)) return update({[name]: v.map(item => (item[hash]))});
        update({[name]: v[hash]});
        if( change ) change({[name]: value, e, v});
    };
    const get = code => {
        if (Array.isArray(code)) return code.map(item => {
            for (let i = 0; i < opts.length; i++) if (item == opts[i][hash]) return opts[i];
            return null;
        });
        if( typeof code === "undefined" ) return '';
        for (let i = 0; i < opts.length; i++) if (code == opts[i][hash]) return opts[i];
        return null;
    };

    //TODO solve way change on blur
    //Use Blur for add opts without enter key.
    // const blur = e => free ? change(e, e.target.value) : null;
    return (
        <Autocomplete
            error={error}
            options={opts}
            getOptionLabel={optionLabel}
            defaultValue={get(value)}
            onChange={handler}
            disabled={disabled}
            freeSolo={free}
            helpe={helpe}
            disableCloseOnSelect={close}
            multi={multi}
            scroll={scroll}
            clearable={clear}
            onlyString={onlyString}
            className={className}
            // blur={blur}
            label={label}/>
    );
};

Select.propTypes = {
    store: PropTypes.string,
    save: PropTypes.func,
    name: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    helpe: PropTypes.string,
    opts: PropTypes.array,
    multi: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    free: PropTypes.bool,
    optionLabel: PropTypes.func,
    change: PropTypes.func,
    clear: PropTypes.bool,
    error: PropTypes.bool,
    close: PropTypes.bool,
    scroll: PropTypes.func,
    onlyString: PropTypes.bool,
    hash: PropTypes.string,
};

export default connect(
    (store, props) => {
        props.name = props.name ? props.name : '';
        props.label = props.label ? props.label : '';
        props.helpe = props.helpe ? props.helpe : '';
        props.multi = props.multi !== undefined ? props.multi : false;
        props.free = props.free !== undefined ? props.free : false;
        props.clear = props.clear !== undefined ? props.clear : true;
        props.disabled = props.disabled !== undefined ? props.disabled : false;
        props.close = props.close !== undefined ? props.close : true;
        props.opts = props.opts ? props.opts : [];
        let error = props.error;
        error = error ? error : store.errors[`${props.name}_error`];
        return {
            value: props.store ? store[props.store][props.name] : undefined,
            defaultValue: props.value,
            error,
            optsData: store.userOpts
        };
    },
    (dispatch, props) => ({
        update: data => dispatch(props.save(data))
    })
)(Select);
