import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoSharp from '@material-ui/icons/InfoSharp';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { saveFormulariosData } from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import { resource } from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ClientsServcices from "../../../services/Clients";
import ServiciosServcices from '../../../services/Servicios';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import Date from "../../../common/components/Fields/Date";

class Lista extends React.Component {

    Cities = resource('Cities');
    _mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0,
            type: props.type,
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
        console.log(this.props)
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll() {
        let [clients] = await Promise.all([
            ClientsServcices.get(-1)
        ]);
        this.setState(() => ({ clients: clients.data }));
        await this.getData();
    }

    async getData() {
        Helpers.loader();
        let form = Store.getState().formularios;
        console.log(this.props);
        let type = localStorage.getItem('type');
        let typeTable = (type) ? type : this.state.type;
        let cotizacion = await ServiciosServcices.getState(0, typeTable, form);
        this.setState(() => ({ data: cotizacion.data, total: cotizacion.total }));
        Helpers.closeLoader();
    }

    edit = id => () => this.props.history.push(`/servicio/edit/${id}`);
    view = id => () => this.props.history.push(`/servicio/view/${id}`);
    showTwnel = id => () => this.props.history.push(`/serviciotwnel/${id}`);

    changePage = async (event, page) => {
        Helpers.loader();
        let form = Store.getState().formularios;
        let cotizacion = await ServiciosServcices.getState(page, this.state.type, form);
        this.setState(() => ({ data: cotizacion.data, total: cotizacion.total, page }));
        Helpers.closeLoader();
    };

    search = () => {
        this.setState(() => ({ page: 0 }));
        this.getData();
    };

    render() {
        const { state, props: { campo } } = this;
        return (
            <div className="wrap table">
                <Row>
                    <Provider store={'formularios'} save={saveFormulariosData}>
                        <Col md={10}>

                            <Select name={'campo'} opts={[
                                {
                                    name: '# Servicio',
                                    code: 'consecutivo'
                                },
                                {
                                    name: 'Fecha ida',
                                    code: 'fecha_ida'
                                },
                                {
                                    name: 'Cliente',
                                    code: 'client'
                                },
                                {
                                    name: 'Origen',
                                    code: 'origen'
                                },
                                {
                                    name: 'Destino',
                                    code: 'destino'
                                }
                            ]} />
                            {campo === 'client' &&
                                <Select name={'search'} label={'Buscar...'} optionLabel={Helpers.clientsLabels} opts={state.clients} hash={'id'} />
                            }
                            {campo === 'fecha_ida' &&
                                <React.Fragment>
                                    <Date name="init_date" label="Fecha de" />
                                    <Date name="end_date" label="Fecha hasta" />
                                </React.Fragment>
                            }
                            {(campo === 'origen' || campo === 'destino') &&
                                <Select name={'search'} label={'Buscar...'} opts={this.Cities} />
                            }
                            {campo === 'consecutivo' &&
                                <Text name={'search'} label={'Buscar...'} />
                            }
                        </Col>
                        <Col md={2}>
                            <Btn text={"Buscar"} type={'primary'} class={'btn-block'} click={this.search} />
                        </Col>
                    </Provider>
                </Row>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell># Servicio</TableCell>
                                <TableCell>Fecha ida</TableCell>
                                <TableCell>Cliente</TableCell>
                                <TableCell>Origen</TableCell>
                                <TableCell>Destino</TableCell>
                                <TableCell>Valor</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Array.isArray(state.data) && state.data.map((item, key) =>
                                <React.Fragment key={key}>
                                    <TableRow>
                                        <TableCell>{item.consecutivo}</TableCell>
                                        <TableCell>{item.fecha_ida}</TableCell>
                                        <TableCell width='320'>{item.cliente.names}</TableCell>
                                        <TableCell>{Helpers.getStatic(item.origen, this.Cities).name}</TableCell>
                                        <TableCell>{Helpers.getStatic(item.destino, this.Cities).name}</TableCell>
                                        <TableCell>{item.total}</TableCell>
                                        <TableCell className="last-td">
                                            {item.state !== 5 && <IconButton size="small" onClick={this.edit(item.id)}><EditIcon /></IconButton>}
                                            {item.state === 5 && <IconButton size="small" onClick={this.view(item.id)}><VisibilityIcon /></IconButton>}
                                            {item.state >= 2 && <IconButton size="small" onClick={this.showTwnel(item.id)}><VisibilityIcon /></IconButton>}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )}
                        </TableBody>


                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={state.total}
                        rowsPerPage={10}
                        page={state.page}
                        onChangePage={this.changePage}
                    />
                </TableContainer>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        campo: store.formularios.campo
    }),
    (dispatch) => ({}),
)(Lista);
