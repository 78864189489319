import React from 'react';
import {Col, Row} from 'react-grid-system';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import UsuariosService from '../../../services/Usuarios';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';

class Lista extends React.Component {

    Roles = resource('Roles');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.getData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getData(){
        Helpers.loader();
        let clients = await UsuariosService.get();
        this.setState(()=>({data: clients.data, total: clients.total}));
        Helpers.closeLoader();
    }

    edit = id => () => this.props.history.push(`/usuario/edit/${id}`);

    changePage = async (event, page) => {
        Helpers.loader();
        let clients = await UsuariosService.get(page);
        this.setState(()=>({data: clients.data, total: clients.total, page}));
        Helpers.closeLoader();
    };

    render() {
        const {state} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Usuarios</h2>
                    </Col>
                </Row>
                <div className="wrap table">
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Correo</TableCell>
                                    <TableCell>Teléfono</TableCell>
                                    <TableCell>Rol</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(state.data) && state.data.map((item,key)=>
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            <TableCell>{item.names}</TableCell>
                                            <TableCell>{item.email}</TableCell>
                                            <TableCell>{item.cell}</TableCell>
                                            <TableCell>{Helpers.getStatic(item.role, this.Roles).name}</TableCell>
                                            <TableCell className="last-td">
                                                <IconButton size="small" onClick={this.edit(item.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={state.total}
                            rowsPerPage={10}
                            page={state.page}
                            onChangePage={this.changePage}
                        />
                    </TableContainer>
                </div>
            </div>
        );
    }
}

export default Lista;
