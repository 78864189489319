import TextField from '@material-ui/core/TextField';
import PropTypes from "prop-types";
import React from 'react';
import {connect} from 'react-redux';
import Helpers from "../../Helpers";
import HelperText from "./HelperText";

const Text = ({value, update, name, label, type, multi, error, disabled, change, uppercase, lowercase, capitalizecase, max, helpe, counter, money, username, className, number}) => {
    value = value ? value : '';
    const handler = e => {
        let {value} = e.target;
        // if (max) if (value.length > max) return;
        if (uppercase) value = Helpers.ucText(value);
        if (capitalizecase) value = Helpers.ucfirst(value);
        if (lowercase) value = value.toLowerCase();
        if (username) value = Helpers.formatUserName(value);
        if (money) value = Helpers.formatToMask(value);
        if (number) value = Helpers.formatOnlyNumbers(value);
        update({[name]: value});
        if( change ) change({[name]: value});
    };
    return (
        <TextField disabled={disabled} error={error} multiline={multi} onChange={handler} type={type} name={name}
                   inputProps={{maxLength: max}}
                   value={value}
                   autoComplete="off"
                   className={`${className} ${multi?'milti-field':''}`}
                   label={<React.Fragment>{label}{`${counter ? '(' + value.length + ')' : ''}`}<HelperText value={helpe}/></React.Fragment>}
                   variant="outlined"/>
    );
};

Text.propTypes = {
    store: PropTypes.string,
    save: PropTypes.func,
    name: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.any,
    type: PropTypes.string,
    helpe: PropTypes.string,
    multi: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    change: PropTypes.func,
    value: PropTypes.any,
    uppercase: PropTypes.bool,
    lowercase: PropTypes.bool,
    username: PropTypes.bool,
    money: PropTypes.bool,
    capitalizecase: PropTypes.bool,
    max: PropTypes.number,
    counter: PropTypes.bool,
    number: PropTypes.bool,
};

export default connect(
    (store, props) => {
        props.name = props.name ? props.name : '';
        props.label = props.label ? props.label : '';
        props.type = props.type ? props.type : 'text';
        props.multi = props.multi !== undefined ? props.multi : false;
        props.disabled = props.disabled !== undefined ? props.disabled : false;
        let value = props.value;
        if (props.name !== '') value = props.store ? store[props.store][props.name] : undefined;

        let error = props.error;
        error = error ? error : store.errors[`${props.name}_error`];
        return {
            value,
            error
        };
    },
    (dispatch, props) => ({update: data => dispatch(props.save(data))})
)(Text);
