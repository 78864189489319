import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { saveData, saveFormulariosData } from '../../../common/Actions';
import Btn from '../../../common/components/Btn/Btn';
import Date from '../../../common/components/Fields/Date';
import Select from '../../../common/components/Fields/Select';
import Text from '../../../common/components/Fields/Text';
import Provider from '../../../common/components/Provider/Provider';
import Helpers from '../../../common/Helpers';
import { resource } from '../../../common/lang/lang';
import Store from '../../../common/Store';
import Clients from '../../../services/Clients';
import Contratos from '../../../services/Contratos';
import Solicitudes from '../../../services/Solicitudes';
import Model from '../Solicitudes.json';

class Formulario extends React.Component {

    Cities = resource('Cities');
    Trayecto = [
        {
            code: "IDA",
            name: "IDA"
        },
        {
            code: "IDA Y VUELTA",
            name: "IDA Y VUELTA"
        }
    ];
    VehicleType = resource('VehicleType');


    constructor(props) {
        super(props)
        this.state = {
            contacts: [],
            existeTarifa: false,
            disabledAsignado: false,
            id: this.props.match.params.id
        }

    }

    componentDidMount() {
        let params = this.props.match.params;
        this.getData(params.id);
    }


    async getData(id) {

        if (id) {
            let soli = await Solicitudes.getById(id);
            if (soli) {
                soli.data.tarifa = Helpers.formatToMask(soli.data.tarifa);
                console.log(soli.data)
                this.props.save({
                    formularios: soli.data
                })
            }
        };
        let user = Store.getState().user;
        let clientes = await Clients.get(-1);
        console.log(clientes, user);
        let contactos = clientes.data.filter(element => element.id == user.id);
        if (contactos.length) {
            this.setState({ contacts: contactos[0].contactos });
        }
    }

    verTarifa = async () => {
        let { origen, destino, tipo_trayecto, via, tipo_veh, num_dias } = Store.getState().formularios

        //console.log(Store.getState().formularios);
        let user = Store.getState().user;

        let response = await Solicitudes.costeoRutas({
            origen,
            destino,
            tipo_trayecto,
            via,
            tipo_veh,
            num_dias,
            client: user.id
        })

        if (!response.data) {
            Helpers.alert({
                title: "Solicitud",
                text: response.message
            })
        } else {
            let data = Store.getState().formularios;

            data.tarifa = Helpers.formatToMask(response.total);

            this.props.save({ formularios: data });
            this.setState({ existeTarifa: true, disabledAsignado: true })
        }

    }

    guardarTarifa = async () => {
        let data = Store.getState().formularios;

        let valid = Helpers.validForm(data, Model)

        if (valid.keys) {
            this.editarParametros();
            this.props.save({ errors: valid.keys });
            Helpers.alert({ title: 'Error!', text: valid.errors.join('\n') });
        } else {
            let user = Store.getState().user;
            data.cliente = user.id
            data.tarifa = Helpers.formatOnlyNumbers(data.tarifa);

            let response;
            console.log(this.state);
            if (this.state.id) {
                delete data.created_at;
                delete data.updated_at;
                delete data.cliente;
                response = await Solicitudes.update(data)
            } else {
                response = await Solicitudes.save(data)
            }

            if (response.status) {
                Helpers.alert({
                    title: "Solicitudes",
                    text: "Solicitud guardada exitosamente"
                });
                this.props.history.push(`/solicitud/list`)
            } else {
                Helpers.alert({
                    title: "Solicitudes",
                    icon: "error",
                    text: "Ah ocurrido un error intente mas tarde"
                });
            }
        }

    }

    editarParametros = () => {
        this.setState({ disabledAsignado: false, existeTarifa: false });
        let data = Store.getState().formularios;
        data.tarifa = null;
        this.props.save({ formularios: data });
    }

    cancelar = () => {
        this.props.history.push(`/solicitud/list`)
    }

    render() {
        const { state } = this;
        const disabledAsignado = state.disabledAsignado;
        return (
            <div className='formdata'>
                <Row>
                    <Col>
                        <h2>{(state.id) ? "Editar" : "Nueva"} solicitud</h2>
                    </Col>
                </Row>
                <div className="wrap">
                    <Provider save={saveFormulariosData} store="formularios">
                        <div>
                            <Select disabled={disabledAsignado} label="Seleccione el contacto" name='contact' opts={state.contacts} optionLabel={(opt) => { return opt.names }} hash="id" />
                            <div className="w-49 d-i">
                                <Date today disabled={disabledAsignado} label="Fecha de ida" name="fecha_ida" />
                                <Date today disabled={disabledAsignado} label="Fecha de regreso" name="fecha_regreso" />
                            </div>
                        </div>
                        <div>
                            <Text disabled={disabledAsignado} label="Cantidad de pasajeros" number name={`cantidad_pasajeros`} />
                            <div className="w-49 d-i">
                                <Select disabled={disabledAsignado} label="Origen" opts={this.Cities} name='origen' />
                                <Select disabled={disabledAsignado} label="Destino" opts={this.Cities} name='destino' />
                            </div>
                        </div>
                        <div>
                            <Select disabled={disabledAsignado} label="Tipo de trayecto" name='tipo_trayecto' opts={this.Trayecto} />
                            <div className="w-49 d-i">
                                <Text disabled={disabledAsignado} label="Vía" name={`via`} />
                                <Select disabled={disabledAsignado} label="Tipo de vehiculo" name='tipo_veh' opts={this.VehicleType} />
                            </div>
                        </div>
                        <div>
                            <Text disabled={disabledAsignado} label="# de días" number name={`num_dias`} />
                            <div className='w-49 d-i'>
                                <Text disabled={true} label="Tarifa $" name={`tarifa`} money />
                                <Text disabled={true} label="Servicio" number name={`servicio`} />
                            </div>
                        </div>
                        <div>
                            <Text label="Observación" multi className="w-100 field-observa" name={`observa`} />
                        </div>
                        <div className='btns'>
                            <Btn text={`Ver tarifa`} type="primary" click={this.verTarifa} />
                            <Btn text={`Editar Parametros`} type="secondary" click={this.editarParametros} />
                            <Btn text={`Enviar`} disabled={!state.existeTarifa} type="primary" click={this.guardarTarifa} />
                            <Btn text={`Cancelar`} type="secondary" click={this.cancelar} />
                        </div>
                    </Provider>
                </div>
            </div>
        )
    }
}


export default connect(
    (store) => ({
        name: store.formularios.name,
    }),
    (dispatch) => ({
        save: data => dispatch(saveData(data)),
        saveForm: data => dispatch(saveFormulariosData(data))
    })
)(Formulario)