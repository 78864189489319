import PropTypes from "prop-types";
import React from 'react';
import {connect} from 'react-redux';
import Helpers from "../../Helpers";
import Btn from "../Btn/Btn";
import HelperText from "./HelperText";
import PublishIcon from '@material-ui/icons/Publish';
import FilesService from "../../../services/Files";

const Files = ({values, save, name, label, multi, helpe, disabled}) => {
    values = values ? values : [];
    const formats = ".png,.jpg,.jpeg,.pdf";

    const change = e => {
        let {name, files} = e.target;
        files = new Array(files.length).fill(0).map(item => files[item]);
        save({[name]: files});
    };

    const download = url => async e =>{
        e.preventDefault();
        Helpers.loader();
        let doc = await FilesService.download(url);
        let a = document.createElement('a');
        a.download = doc.name;
        a.href = doc.data;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        Helpers.closeLoader();
    };

    return (
        <div className="files-component">
            <label>{label}</label>
            <ul>
                {Array.isArray(values) && values.map((item, key) =>
                    <li key={key}>
                        <div className="box">
                            <h5>{item.name} - </h5>
                            <h6>{item.type}</h6>
                        </div>
                    </li>
                )}
                {!Array.isArray(values) &&
                <li>
                    <div className="box">
                        <h5><a onClick={download(values)} href={'/#'}>{values}</a></h5>
                    </div>
                </li>
                }
            </ul>
            <Btn class="input" click={() => ({})} type='primary' disabled={disabled}>
                <input multiple={multi} accept={formats} type='file' name={name} disabled={disabled} onChange={change}/>
                Adjuntar documento <PublishIcon />
            </Btn>
            <HelperText value={helpe}/>
        </div>
    );
};

Files.propTypes = {
    store: PropTypes.string,
    helpe: PropTypes.string,
    save: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    multi: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default connect(
    (store, props) => {
        props.name = props.name ? props.name : '';
        props.label = props.label ? props.label : '';
        props.multi = props.multi !== undefined ? props.multi : false;
        return {
            values: props.store ? store[props.store][props.name] : undefined,
            error: store.errors[`${props.name}_error`]
        };
    },
    (dispatch, props) => ({save: data => dispatch(props.save(data))}),
)(Files);
