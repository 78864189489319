import React from 'react';
import Formulario from "./components/Formulario";
import Lista from "./components/Lista";

const Conductores = props => {
    const path = props.match.path;
    return (
        <React.Fragment>
            {(path === '/condutcor/new' || path.indexOf('/condutcor/edit') === 0) && <Formulario {...props}/>}
            {path === '/condutcor/list' && <Lista {...props}/>}
        </React.Fragment>
    );
};

export default Conductores;
