import Backdrop from '@material-ui/core/Backdrop';

import {default as ModalMaterial} from '@material-ui/core/Modal';
import moment from "moment";
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-grid-system';
import {connect} from "react-redux";
import {saveData, saveFormulariosData, saveRepetidosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Date from "../../../common/components/Fields/Date";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ConsecutivoService from "../../../services/Consecutivo";
import ContratosService from "../../../services/Contratos";
import CotizacionService from "../../../services/Cotizacion";
import Model from "../Contrato";

class Modal extends React.Component {

    ContractType = resource('ContractType');
    ContractClass = resource('ContractClass');

    constructor(props){
        super(props);
        this.state = {
            consecutivo: '----'
        };
    }

    componentDidMount() {
        this.getData();
    }

    async getData(){
        let [contratos] = await Promise.all([
            ContratosService.get(-1),
        ]);
        let newActual = [];
        for (let i = 0; i < contratos.data.length; i++) {
            let end = moment(contratos.data[i].end_date);
            if( moment().isBefore(end) ) newActual.push(contratos.data[i]);
        }
        this.setState(()=>({
            contratos: newActual,
        }));
    }

    contract = e => {
        this.setState(()=>({ consecutivo: e.v.consecutivo }));
        this.props.saveForm({
            init_date: e.v.init_date,
            end_date: e.v.end_date,
            objecto: e.v.objecto,
        });
    };

    contractType = () => {
        this.setState(()=>({ consecutivo: '----' }));
        this.props.saveForm({
            init_date: '',
            end_date: '',
            objecto: '',
            contract_id: '',
        });
    };
    
    save = async () => {
        let store = Store.getState();

        let form = {
            ...store.formularios,
            data: this.props.data,
        };

        let valid = Helpers.validForm(form, Model);
        if( valid.keys ){
            this.props.save({errors: valid.keys});
            Helpers.alert({title: 'Error!', text:valid.errors.join('\n')});
        }else{
            this.props.save({errors: {}});
            Helpers.loader();

            let consecutivo;
            if(form.tipo_contract === 2){
                consecutivo = this.state.consecutivo;
                form['consecutivo'] = consecutivo;
            }else{
                consecutivo = await Promise.resolve(ConsecutivoService.get(4));
                form['consecutivo'] = consecutivo.number.toString().padStart(4, '0');
            }

            let response = await CotizacionService.saveService(form);
            Helpers.closeLoader();
            if( response.message ){
                Helpers.alert({title: 'Error!', text: response.message});
            }else if(response.status){
                this.contractType();
                Helpers.alert({title: '', icon:'success', text: 'Se ha confirmado correctamente!'});
                this.props.close(true);
            }else{
                Helpers.alert({title: 'Error!', text: 'Error intente más tarde'});
            }
        }
    };

    filterContracts = () => {
        if(!this.props.data) return;
        let contracts = this.state.contratos;
        let newContracts = [];
        for (let i = 0; i < contracts.length; i++) {
            if( this.props.data.client.id === contracts[i].cliente.id ){
                newContracts.push(contracts[i]);
            }
        }
       return newContracts;
    };

    render() {
        const {props: {close, open,
            tipo_contract,
            data = {
                disponibilidad: {},
                contacto: {},
                client: {},
            }
        }, state} = this;
        let disabled = tipo_contract === 2;
        return (
            <ModalMaterial
                open={open}
                onClose={close}
                closeAfterTransition
                BackdropComponent={Backdrop}
                style={{overflow: 'auto'}}
                BackdropProps={{
                    timeout: 500
                }}>
                <Row nogutter={true} className={'popup-component'}>
                    <div className="close" onClick={close}>&nbsp;</div>
                    <Col className="formdata">
                        <div className="wrap">
                            <Provider save={saveFormulariosData} store="formularios">
                                <Row className="row">
                                    <Col>
                                        <h3>Confirmar la cotización</h3>
                                        <div>
                                            <Select className="w-100" multi label="Seleccione el servicio" opts={data.disponibilidad} optionLabel={Helpers.citieLabels} hash="id" name="service" />
                                        </div>
                                        <div>
                                            <Select label="Tipo de contrato" opts={this.ContractClass} name="tipo_contract" change={this.contractType} />
                                            {tipo_contract === 2 && <Select label="Selecione el contrato" opts={this.filterContracts()} change={this.contract} optionLabel={Helpers.contratosLabels} name="contract_id" hash="id" />}
                                        </div>
                                        <div>
                                            <Text label="Número de contrato" disabled value={state.consecutivo}/>
                                        </div>
                                        <div>
                                            <Text disabled label="Cliente" value={data.client?.names} />
                                            <Text disabled label="Contacto" value={data.contacto?.names} />
                                        </div>
                                        <div>
                                            <Date disabled={disabled} today label="Fecha de inicio" name="init_date" />
                                            <Date disabled={disabled} today label="Fecha de vencimiento" name="end_date" />
                                        </div>
                                        <div>
                                            <Select disabled={disabled} className="w-100" label="Objeto del contrato" opts={this.ContractType} name="objecto" />
                                        </div>
                                        <div className="text-center">
                                            <Btn click={this.save} text="Guardar" type="primary" />
                                        </div>
                                    </Col>
                                </Row>
                            </Provider>
                        </div>
                    </Col>
                </Row>
            </ModalMaterial>
        );
    }
}

Modal.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    data: PropTypes.any,
};

export default connect(
    (store) => ({
        tipo_contract: store.formularios.tipo_contract,
    }),
    (dispatch) => ({
        save: data => dispatch(saveData(data)),
        saveForm: data => dispatch(saveFormulariosData(data)),
        saveRepe: data => dispatch(saveRepetidosData(data)),
    }),
)(Modal);
