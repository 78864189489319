export const Backend = 'https://translaval.co/BackEnd/api/v1';
//export const Backend = 'http://translavalback.local.co/api/v1';
// export const Backend = 'http://127.0.0.1:8000/api/v1';
// export const Backend = 'https://emperadorsoluciones.co/translaval/backend/public/api/v1';
// export const Backend = 'http://testidealatente.com/translaval-back/public/api/v1'
export const Files = `${Backend}/static/files/`;
export const GOOGLE_API = `AIzaSyD2sY5tG5o8WMZnZKS0b3L6fcuh3TDKgoo`;

export const headers = {
    "Content-type": "application/json"
};

