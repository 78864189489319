import React from 'react';
import {Col, Row} from 'react-grid-system';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {connect} from "react-redux";
import {saveFormulariosData} from "../../../common/Actions";
import Btn from "../../../common/components/Btn/Btn";
import Select from "../../../common/components/Fields/Select";
import Text from "../../../common/components/Fields/Text";
import Provider from "../../../common/components/Provider/Provider";
import Helpers from "../../../common/Helpers";
import {resource} from "../../../common/lang/lang";
import Store from "../../../common/Store";
import ClientsServcices from "../../../services/Conductores";
import ViaticosService from "../../../services/Viaticos";
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import Date from "../../../common/components/Fields/Date";
import { Visibility } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";

class Lista extends React.Component {

    Type = resource('Type');
    _mounted = false;

    constructor(props){
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0,
            clients: []
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.getAll();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async getAll(){
        let [clients] = await Promise.all([
            ClientsServcices.get()
        ]);
        this.setState(()=>({ clients: clients.data }));
        await this.getData();
    }

    async getData(){
        Helpers.loader();
        let form = Store.getState().formularios;
        let clients = await ViaticosService.get(this.state.page,form);
        this.setState(()=>({data: clients.data, total: clients.total}));
        Helpers.closeLoader();
    }

    edit = id => () => this.props.history.push(`/viatico/edit/${id}`);

    changePage = async (event, page) => {
        Helpers.loader();
        let clients = await ViaticosService.get(page);
        this.setState(()=>({data: clients.data, total: clients.total, page}));
        Helpers.closeLoader();
    };

    search = () => {
        this.setState(()=>({ page: 0 }));
        this.getData();
    };

    render() {
        const {state, props:{campo}} = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Viáticos</h2>
                    </Col>
                </Row>
                <div className="wrap table">
                    <Row>
                        <Provider store={'formularios'} save={saveFormulariosData}>
                            <Col md={10}>
 
                                <Select name={'campo'} opts={[
                                    {
                                        name: 'Consecutivo',
                                        code: 'consecutivo'
                                    },
                                    {
                                        name: 'Conductor',
                                        code: 'conductor'
                                    },
                                    {
                                        name: 'Fecha',
                                        code: 'fecha'
                                    },
                                ]} />
                               {campo === 'conductor' &&
                                <Select name={'search'} label={'Buscar...'} optionLabel={Helpers.driveLabels} opts={state.clients} hash={'id'} />
                                }
                                {(campo === 'consecutivo') &&
                                <Text name={'search'} label={'Buscar...'}/>
                                } 
                                {campo === 'fecha' &&
                            <React.Fragment>
                              <Date   name="init_date" label="Fecha de" /> 
                              <Date   name="end_date"  label="Fecha hasta" /> 
                            </React.Fragment>
                            }                               
                            </Col>
                            <Col md={2}>
                                <Btn text={"Buscar"} type={'primary'} class={'btn-block'} click={this.search}/>
                            </Col>
                        </Provider>
                    </Row>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Consecutivo</TableCell>
                                    <TableCell>Conductor</TableCell>
                                    <TableCell>Valor</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(state.data) && state.data.map((item,key)=>
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            <TableCell>{item.consecutivo}</TableCell>
                                            <TableCell>{item.conductor?.name} {item.conductor?.surname}</TableCell>
                                            <TableCell>{Helpers.formatToMask(item.valor)}</TableCell>
                                            <TableCell>{moment(item.created_at).format('YYYY-MM-DD')}</TableCell>
                                            <TableCell>{item.estado}</TableCell>
                                            <TableCell className="last-td">

                                                <IconButton size="small" onClick={this.edit(item.id)}>
                                                {item.estado !== "CERRADO" &&  <EditIcon /> }
                                                {item.estado == "CERRADO" &&  <VisibilityIcon /> }
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={state.total}
                            rowsPerPage={10}
                            page={state.page}
                            onChangePage={this.changePage}
                        />
                    </TableContainer>
                </div>
            </div>
        );
    }
}

export default connect(
    (store) => ({
        campo: store.formularios.campo
    }),
    (dispatch) => ({}),
)(Lista);
