import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import Provider from '../../../common/components/Provider/Provider';
import Helpers from '../../../common/Helpers';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TablePagination from '@material-ui/core/TablePagination';
import Ubicaciones from '../../../services/Ubicaciones';
import { resource } from '../../../common/lang/lang';

class Lista extends React.Component {

    Cities = resource('Cities');

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 0,
            total: 0,
            type: props.type,
        };
    }

    componentDidMount() {
        this.listUbications();
    }


    listUbications = async () => {
        let list = await Ubicaciones.list(-1);

        if (list.code == 200) {
            list = list.data;
            console.log(list, this.Cities);
            list.forEach(element => {
                let city = this.Cities.filter(element2 => element2.code == element.code_city);
                console.log(city.length);
                element.cityName = (city.length) ? city[0].name : "";
            });
            this.setState({ data: list });
        }else{

        }
    }

    edit = id => () => this.props.history.push(`/ubicaciones/edit/${id}`);


    render() {
        const { state } = this;
        return (
            <div className="formdata">
                <Row>
                    <Col>
                        <h2>Ubicaciones</h2>
                    </Col>
                </Row>
                <div className="wrap table">
                    <Row>
                        {/* <Provider store={'formularios'} save={saveFormulariosData}>
                        <Col md={10}>

                            <Select name={'campo'} opts={[
                                {
                                    name: '# Servicio',
                                    code: 'consecutivo'
                                },
                                {
                                    name: 'Fecha ida',
                                    code: 'fecha_ida'
                                },
                                {
                                    name: 'Cliente',
                                    code: 'client'
                                },
                                {
                                    name: 'Origen',
                                    code: 'origen'
                                },
                                {
                                    name: 'Destino',
                                    code: 'destino'
                                }
                            ]} />
                            {campo === 'client' &&
                                <Select name={'search'} label={'Buscar...'} optionLabel={Helpers.clientsLabels} opts={state.clients} hash={'id'} />
                            }
                            {campo === 'fecha_ida' &&
                                <React.Fragment>
                                    <Date name="init_date" label="Fecha de" />
                                    <Date name="end_date" label="Fecha hasta" />
                                </React.Fragment>
                            }
                            {(campo === 'origen' || campo === 'destino') &&
                                <Select name={'search'} label={'Buscar...'} opts={this.Cities} />
                            }
                            {campo === 'consecutivo' &&
                                <Text name={'search'} label={'Buscar...'} />
                            }
                        </Col>
                        <Col md={2}>
                            <Btn text={"Buscar"} type={'primary'} class={'btn-block'} click={this.search} />
                        </Col>
                    </Provider> */}
                    </Row>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Ciudad</TableCell>
                                    <TableCell>Latitud</TableCell>
                                    <TableCell>Longitud</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(state.data) && state.data.map((item, key) =>
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            <TableCell>{item.code}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.cityName}</TableCell>
                                            <TableCell>{item.lat}</TableCell>
                                            <TableCell>{item.lng}</TableCell>
                                            <TableCell className="last-td">
                                                <IconButton size="small" onClick={this.edit(item.code)}><EditIcon /></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={state.total}
                            rowsPerPage={10}
                            page={state.page}
                            onChangePage={this.changePage}
                        />
                    </TableContainer>
                </div>
            </div>

        )
    }
}

export default connect()(Lista);
